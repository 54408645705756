<template>
  <f7-page  no-swipeback name="vip" class="page-messgae-center" @page:init="onPageInit" style="padding-bottom: var(--f7-safe-area-bottom);"
    @page:beforein="onPageBeforeIn">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-title><div style="width: 100%;"><img src="../../../static/images/yt999/merchant/default/logo/dlogVip.png" style="width: 180px;margin: auto;" srcset=""></div></f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="head" style="position: relative;">
      <div class="jie" >
        <div class="xian"></div>
        <van-tabs v-model="vandian" @click="retur()" duration="0.3">
          <van-tab v-for="(item, index) in simpleVipList" :key="index" :title="item.Name" ></van-tab>
        </van-tabs>
        
        <!-- <div class="gai"></div> -->
      </div>
      <f7-block class="block-banner" style="margin: 5px 0 0 0;padding: 0;">
        <f7-swiper next-button prev-button  data-lazy="{'enabled': true}" :params="swiperParams"
          class="swiper-banner" ref="mySwiper">
          <f7-swiper-slide v-for="(item, index) in simpleVipList" :key="index">
            <div @click="checkIndexto(index)" style="width: 100%;height: -webkit-fill-available;">
              <img :src="`../../../static/images/yt999/vip/v${index+1}.png`" class="swiper-lazy"  width="100%" height="122px"/>
              <span class="vipname">{{ item.Name }}</span>
              <span class="amount" :id="'am'+index" v-if="index==checkIndex">{{ item.DepositTotalAmount | formatCurrency2 }}</span>
              <span class="amount" :id="'am'+index" v-else>{{ item.Name }}</span>
            </div>
          </f7-swiper-slide>
        </f7-swiper>
      </f7-block>
    </div>
    
    <div class="vipcontent">
      <div class="triangle-up"></div>
      <div class="title">
        <h3 id="tip">{{ vipInfo.Name }} {{ $t("vip_detail_0001") }}</h3>
      </div>
      <!-- <div class="vipInfo">
        <div>{{ $t("vip_detail_0002") }}</div>
        <table>
          <tr>
            <td>{{ $t("vip_detail_0003") }}</td>
            <td>{{ vipInfo.OrdersRebatesRateL1 }}%</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0004") }}</td>
            <td>{{ vipInfo.FinancialProductRebatesRateL1 }}%</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0005") }}</td>
            <td>{{ vipInfo.VIPLevelUpRebatesRateL1 }}%</td>
          </tr>
        </table>
        <div>{{ $t("vip_detail_0006") }}</div>
        <table>
          <tr>
            <td>{{ $t("vip_detail_0003") }}</td>
            <td>{{ vipInfo.OrdersRebatesRateL2 }}%</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0004") }}</td>
            <td>{{ vipInfo.FinancialProductRebatesRateL2 }}%</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0005") }}</td>
            <td>{{ vipInfo.VIPLevelUpRebatesRateL2 }}%</td>
          </tr>
        </table>
        <div>{{ $t("vip_detail_0007") }}</div>
        <table>
          <tr>
            <td>{{ $t("vip_detail_0003") }}</td>
            <td>{{ vipInfo.OrdersRebatesRateL3 }}%</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0004") }}</td>
            <td>{{ vipInfo.FinancialProductRebatesRateL3 }}%</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0005") }}</td>
            <td>{{ vipInfo.VIPLevelUpRebatesRateL3 }}%</td>
          </tr>
        </table>
      </div> -->
      <h4 style="margin: 0 0 4px 0;">{{ $t("vip_detail_0019") }}</h4>
      <table class="custom-table">
        <thead>
          <tr>
            <th>{{ $t("vip_detail_0020") }}</th>
            <th>{{ $t("vip_detail_0041") }}</th>
            <th>{{ $t("vip_detail_0021") }}</th>
            <th>{{ $t("vip_detail_0022") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("vip_detail_0023") }}</td>
            <td>{{ (vipInfo.FixedProfitAmount * 1 ) | formatCurrency }}</td>
            <td>{{ vipInfo.DailyOrderCountLimit }}</td>  
            <td>{{ (vipInfo.DailyOrderCountLimit*vipInfo.FixedProfitAmount) | formatCurrency }}</td>
            <!-- DailyOrderCountLimit * 天 * FixedProfitAmount  = 总佣金 -->
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0024") }}</td>
            <td>{{ (vipInfo.FixedProfitAmount  ) | formatCurrency }}</td>
            <td>{{ vipInfo.DailyOrderCountLimit*30 }}</td>
            <td>{{ (vipInfo.DailyOrderCountLimit*30*vipInfo.FixedProfitAmount) | formatCurrency }}</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0025") }}</td>
            <td>{{ (vipInfo.FixedProfitAmount  ) | formatCurrency }}</td>
            <td>{{ vipInfo.DailyOrderCountLimit*365 }}</td>
            <td>{{ (vipInfo.DailyOrderCountLimit*365*vipInfo.FixedProfitAmount) | formatCurrency }}</td>
          </tr>
        </tbody>
      </table>

      <h4 style="margin: 15px 0 4px 0;">{{ $t("vip_detail_0026") }}</h4>
      <table class="custom-table">
        <thead>
          <tr>
            <th>{{ $t("vip_detail_0027") }}</th>
            <th>{{ $t("vip_detail_0028") }}</th>
            <th>{{ $t("vip_detail_0029") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("vip_detail_0030") }}</td>
            <td>{{ vipInfo.VIPLevelUpRebatesRateL1 }}%</td>
            <td>{{ (vipInfo.VIPLevelUpRebatesRateL1*vipInfo.DepositTotalAmount/100) | formatCurrency }}</td>
            <!-- DepositTotalAmount * (VIPLevelUpRebatesRateL1 / 100) = 收益金额 -->
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0031") }}</td>
            <td>{{ vipInfo.VIPLevelUpRebatesRateL2 }}%</td>
            <td>{{ (vipInfo.VIPLevelUpRebatesRateL2*vipInfo.DepositTotalAmount/100) | formatCurrency }}</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0032") }}</td>
            <td>{{ vipInfo.VIPLevelUpRebatesRateL3 }}%</td>
            <td>{{ (vipInfo.VIPLevelUpRebatesRateL3*vipInfo.DepositTotalAmount/100) | formatCurrency }}</td>
          </tr>
        </tbody>
      </table>

      <h4 style="margin: 15px 0 4px 0;">{{ $t("vip_detail_0033") }}</h4>
      <table class="custom-table">
        <thead>
          <tr>
            <th>{{ $t("vip_detail_0034") }}</th>
            <th>{{ $t("vip_detail_0035") }}</th>
            <th>{{ $t("vip_detail_0036") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t("vip_detail_0037") }}</td>
            <td>{{ vipInfo.OrdersRebatesRateL1 }}%</td>
            <td>{{ (vipInfo.OrdersRebatesRateL1*vipInfo.FixedProfitAmount*vipInfo.DailyOrderCountLimit/100) | formatCurrency }}</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0038") }}</td>
            <td>{{ vipInfo.OrdersRebatesRateL2 }}%</td>
            <td>{{ (vipInfo.OrdersRebatesRateL2*vipInfo.FixedProfitAmount*vipInfo.DailyOrderCountLimit/100) | formatCurrency }}</td>
          </tr>
          <tr>
            <td>{{ $t("vip_detail_0039") }}</td>
            <td>{{ vipInfo.OrdersRebatesRateL3 }}%</td>
            <td>{{ (vipInfo.OrdersRebatesRateL3*vipInfo.FixedProfitAmount*vipInfo.DailyOrderCountLimit/100) | formatCurrency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <f7-button round outline class="buyVip" v-if="!vipInfo.EnableVIPLevelUpdate" style="background: #ccc;">{{ $t("vip_detail_0040") }}</f7-button>
      <f7-button round outline class="buyVip" @click="showPopup()" v-else-if="userInfo.VipLevel < vipInfo.Id">{{ $t("vip_detail_0008") }}</f7-button>
    </div>
    <div style="height: 55px;"></div>

    <van-popup v-model="vantshow" position="bottom"   class="vanbuy">
      <div class="sheet-modal-swipe-step">
        <div class="display-flex justify-content-space-between align-items-center buypopup">
          <div class="yiban one">
            <div style="font-size: 18px;line-height: 37px;">{{ $t("vip_detail_0010") }}</div>
            <div style="position: relative;text-align: right;">
              <i class="f7-icons"  style="color:yellow" id="add" @click="topUp()" >plus_circle</i>
              <div class="yuer">
                <span class="">{{ $t("vip_detail_0011") }}</span><br/>
                <span><span class="symbol">{{ currencySymbol }}</span>{{ memberBalance | formatCurrency}}</span>
              </div>
            </div>
          </div>
          <div class="paddding" style="font-size: 18px;">{{ $t("vip_detail_0012") }}{{ vipInfo.Name }}</div>
          <div class="yiban two paddding">
            <div style="color:#707070 ;">{{ $t("vip_detail_0013") }} {{ vipInfo.VIPAmountLockDays }}</div>
          </div>
          <div class="yiban three paddding">
            <div style="color:#707070 ;">{{ $t("vip_detail_0014") }}</div>
            <div style="color:#2366cc ;"><span class="symbol">{{ currencySymbol }}</span>{{ vipInfo.DepositTotalAmount | formatCurrency2 }}</div>
          </div>
          <div class="yiban four paddding price" >
            <div><span>{{ $t("vip_detail_0016") }}</span>
            <span style="color: #2366cc;font-size: 18px;">{{ currencySymbol }}&nbsp;{{vipInfo.DepositTotalAmount | formatCurrency2}}</span></div>
            <div class="paybtn" @click="vipLevelUpdateMethod(vipInfo.Id)">{{ $t("vip_detail_0017") }}</div>
          </div>
          <div style=" height:calc(var(--f7-safe-area-bottom) + 55px);"></div>
        </div>
      </div>
    </van-popup>

  </f7-page>
</template>

<script>
import { getCookie,getStore } from "@/config/utils";
import { getUserInfo, getBalancesDetail, logout, checkBonusPointWallet, userNameSignindetailDay, getsimpleviplist, vipLevelUpdate } from "../../../axios/api";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    const self = this;
    return {
      currencySymbol: '',
      simpleVipList: [],
      vipInfo: {
        Name: ''
      },
      myVIPLevelUpRebatesRateL: [],
      memberBalance: 0,
      checkIndex: -1,
      swiperParams: {
        speed: 700,
        initialSlide: 0,  // 默认显示第几块
        lazy: {
          loadPrevNext: true,
        },
        loop: false,
        spaceBetween: 10,
        slidesPerView: 'auto',
        centeredSlides: true,
        // allowTouchMove: false,  // 禁用滑动
        pagination: {
          clickable: true,  // 启用分页点击
        },
        on: {
          slideChangeTransitionEnd: function () {
            self.activeSimpleVip(this.activeIndex);
            self.updateAm(this.activeIndex);
            
          },
        },
      },
      mySheetOpened: true,
      vantshow: null,
      vandian: 0,
      num : 0
    };
  },
  watch: {
    // 滑动
    checkIndex(newData, oldData) {
      this.updateAm(this.checkIndex);
      this.$refs.mySwiper.swiper.slideTo(this.checkIndex, 1000);   // mySwiper对象名是通过 ref定义的
    }

  },
  computed: {
    ...mapGetters(["userInfo", "getLoginInfo", "getLoginState"]),

  },
  methods: {
    ...mapActions(["saveLoginState", "getUserInfo"]),

    retur(){
      this.activeSimpleVip(this.vandian);
      this.updateAm(this.vandian);
      this.$refs.mySwiper.swiper.slideTo(this.vandian, 1000);
    },
    onPageInit() {
      this.$f7.emit("myCustomEvent", "vip");
      this.getsBalancesDetail();
    },
    showPopup() {
      this.vantshow = true;
    },
    //点击
    checkIndexto(id) {
      this.checkIndex = id;
      this.updateAm(id);
      // this.$refs.mySwiper.swiper.slideTo(id, 1000);   // mySwiper对象名是通过 ref定义的
    },
    updateAm(id){
      this.vandian = id;
      this.simpleVipList.forEach((item, index) => {
        const dom = document.getElementById('am'+index);
        if(dom==null) return;
        if(id==index){
          // dom.innerHTML = this.currencySymbol+'<br/>'+this.$options.filters.formatCurrency2( this.simpleVipList[index].DepositTotalAmount) ;
          dom.innerHTML = this.$options.filters.formatCurrency2( this.simpleVipList[index].DepositTotalAmount) ;
          // dom.style.fontSize = '14px';
          dom.style.right = '10px';
        }else{
          dom.innerHTML = this.simpleVipList[index].Name;
          // dom.style.fontSize = '16px';
          dom.style.right = '20px';
        }
      });
    },

    vipLevelUpdateMethod(id) {
      const self = this;
      self.vantshow = false;
      const params = {
        TargetVIPLevelID: id,
      };
      let d = { data: JSON.stringify(params) };
      vipLevelUpdate(d).then((data) => {
        if (data.Code === "NoError") {
          self.vantshow = false;
          self.logout(1, '');
        }else if(data.Code == 'BalanceNotEnough'){
          self.$f7.dialog.create({
          title: this.$t(""),
          text:  data.Data,
          cssClass: "dialog-logout",
          buttons: [
            {
              text: this.$t("common_0001"),
              cssClass: "dialog-ok",
              onClick: () => {
                self.$f7.sheet.close();
                self.$f7.$(".dialog-backdrop").css("background", "transparent");
              },
            },
            {
              text: this.$t("vip_detail_0042"),
              cssClass: "dialog-ok",
              onClick: () => {
                self.$f7.sheet.close();
                self.$f7.$(".dialog-backdrop").css("background", "transparent");
                self.$f7router.navigate("/member-center/deposit/");
              },
            }
          ],
        }).open();
        } else {
          self.logout(2, data.Data);
        }

      });
    },

    getSimpleVipList() {
      const self = this;
      const vipLevel = this.$f7route.params.vipLevel;
      const params = {"ShowDefault":false};
      let d = { data: JSON.stringify(params) };
      getsimpleviplist(d).then((data) => {
        if (data.Code === "NoError") {
          self.simpleVipList = data.Data;
          data.Data.forEach((item, index) => {
            item.Name = item.Name.toUpperCase();
            if (item.Id == vipLevel) {
              self.checkIndex = index;
              self.vipInfo = item;
            }
          });
          if (self.checkIndex == -1) {
            self.vipInfo = self.simpleVipList[0];
            
            setTimeout(() => {
              self.checkIndex = 0;
            }, 500);
          }

        }
      });
    },

    getsBalancesDetail() {
      getBalancesDetail().then((data) => {
        if (data.Code === "NoError") {
          const res = data.Data;
          this.memberBalance = data.Data.Balance;;
        }
      });
    },

    activeSimpleVip(index) {
      this.vipInfo = this.simpleVipList[index];
    },

    topUp() {
      this.$f7.sheet.close();
      this.$f7router.navigate("/member-center/deposit/");
    },

    onPageBeforeIn() {
      this.$f7.tab.show("#tab-user-message");
      //}
    },
    back() {
      this.$f7router.back();
    },

    // 弹窗
    logout(paramInt, paramstring) {
      const self = this;
      const showtext = '';
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      if (paramInt == 1) {
        self.showtext = `<div><img src="../../../static/images/yt999/home/ico/ZZicon/${self.vipInfo.Id}.png" style="width: 100%;"><br/><span style="font-weight: bold;">` + this.$t("vip_detail_0018") + `&nbsp;` + self.vipInfo.Name + `</span></div>`;
      } else {
        self.showtext = paramstring;
      }
      self.$f7.dialog.create({
        title: this.$t(""),
        text: this.showtext,
        cssClass: "dialog-logout",
        buttons: [
          {
            text: this.$t("common_0001"),
            cssClass: "dialog-ok",
            onClick: () => {
              self.$f7.sheet.close();
              self.$f7.$(".dialog-backdrop").css("background", "transparent");
              if (paramInt == 1) {     //升级成功更新下界面
                this.getUserInfo();   //更新顶部会员信息
                this.getsBalancesDetail();   //更新钱包余额
              }
            },
          },
        ],
      }).open();
    },
  },
  created() {
    this.currencySymbol = getStore('currencySymbol');
    this.getUserInfo();

  },
  mounted() {
    this.getSimpleVipList();
  },
};
</script>
<style lang="less" scoped>
::v-deep .navbar-inner{    //头部颜色更换
  color: white;
  background: linear-gradient(to right, #2366cc, #2366cc);
}
.head {
  background: var(--f7-THEME-color);
  padding-top: 10px;
  .jie{
    // height: 80px;
    position: relative;
    margin-bottom: 20px;
  }
  .xian{
    background: linear-gradient(to right, rgba(24, 69, 204, 0.5), rgba(24, 69, 204, 1));
    // background-color: rgba(0, 0, 0, 0.2);;
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 2px;
    z-index: 0;
  }
  .gai{
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0px;
    z-index: 11;
  }
  .swiper-container{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    // padding-bottom: 30px;
    ::v-deep .swiper-pagination-bullet-active {
      opacity: 1;
      background: var(--f7-THEME-color);
    }
    
  }
  ::v-deep .swiper-pagination-bullet{
      width: 10px;
      height: 4px;
      display: inline-block;
      border-radius: 40%;
      background: #000;
      opacity: 0.2;
    }
  .swiper-slide {
    width: 67%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    height: 122px;
    img {
      width: 100%;
      
    }
  }

  .amount {
    position: absolute;
    right: 10px;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
  }

  .vipname {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 12px;

  }

}


.vipcontent {
  width: 90%;
  margin: 0 auto;
  position: relative;
  .triangle-up {
    position: absolute;
    left: 45%;
    top: -27px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    z-index: 10;
  }
  .title {
    position: relative;
    padding-left: 7px;

    #tip {
      padding-left: 6px;
    }

    #tip:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0px;
      transform: translateX(-50%);
      width: 3px;
      height: 16px;
      background-color: var(--f7-THEME-color);
    }
  }

  .vipInfo {
    div {
      margin-bottom: 5px;
      padding-bottom: 5px;
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #e3e3e3;
      margin-top: 10px;
    }

    table {
      display: block;
      color: #696969;

      tr {
        display: flex;

        td {
          width: 50%;
          margin-bottom: 5px;
        }

        td:nth-child(2) {
          text-align: right;
        }
      }
      
    }
  }

.custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    padding: 5px;

    head{
      background-color: #ddd;
     
    }
    th, td {
      text-align: center;
      border: 1px solid #d6d6d6; /* 设置边框颜色和样式 */
      padding: 0 2px;
    }
    tr{
      // border-bottom: 1px solid #ddd;
      height: 30px;
      line-height: 30px;
      th {
        background-color: #f2f2f2;
        font-weight: 400;
        line-height: normal;
      }
      th:nth-child(3) {
      }
      td:nth-child(1) {
        width: 42%;
        line-height: 16px;
      }
      td:nth-child(2) {
      }
      td:nth-child(3) {
      }
    } 
  }
}

.buyVip {
  background: var(--f7-THEME-color);
  width: 80%;
  height: 40px;
  line-height: 40px;
  color: white;
  text-align: center;
  border-radius: 7px;
  margin: 0 auto;
  margin-top: 30px;
  border: 0px;
}

.user-level {
  position: absolute;
  top: 0px;
  left: 0px;

  img {
    width: 66px;
    height: 18px;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  span {
    color: #fff;
    position: absolute;
    top: 0px;
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    left: 20px;
  }
}

.buypopup {
  display: block !important;
  padding: 0px !important;

  #add {
    position: absolute;
    right: 25px;
    top: 5px;
    font-size: 23px;
  }
  .yuer{
    width: 100%;
    position: absolute;
    right: 60px;
  }
  .yiban {
    display: flex;
 
    div {
      width: 50%;
    }
    div:nth-child(1) {
      width: 40%;
    }

    div:nth-child(2) {
      width: 60%;
    }
  }

  .paddding {
    padding: 10px;
    margin: 10px;
    padding: 5px;
    border-bottom: 1px solid #e1e0e0;

    div:nth-child(2) {
      text-align: right;
    }
  }
  .price{
    span {
      width: 50px;
      height: 20px;
      display: block;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
      border: 1px solid var(--f7-THEME-color);
      margin: 0px 3px;
    }

  }
  .one {
    background: var(--f7-THEME-color);
    color: white;
    padding: 11px 0 7px 28px;
  }

  .four {
    border: none;
    padding: 10px 0 13px 20px;

    span {
      border: none;
    }
  }

  .paybtn {
    text-align: center !important;
    line-height: 36px;
    border-radius: 20px;
    background: var(--f7-THEME-color);
    height: 36px;
    color: #ffffff;
    font-size: 16px;
    width: 113px !important;
    margin-left: 25%;
    margin-top: 3px;
  }
}
.vanbuy{
  border-radius: 20px 20px 0 0;
  // padding-bottom: 55px;
}
::v-deep .van-tabs__nav{
  background: none;
}
::v-deep .van-tabs__wrap--scrollable .van-tab{
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    padding: 0px 33px;
    color: #fff;
    position: relative;
    font-size: 12px;

    ::after{
      content: '';
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      bottom: 2px;
      left: 35px;
      z-index: 10;
    }
    // span{
    //   width: 10px;
    //   height: 10px;
    //   background-color: #fff;
    //   border-radius: 50%;
    // }
}
::v-deep .van-tabs__line{
  position:absolute;
  background: #ffe600;
  border: 3px solid #efb64f;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  z-index: 11;
}
::v-deep .van-tabs--line .van-tabs__wrap{
  // height: 10px;
  // background: #e03c21;
}
</style>

<template>
  <f7-page
    no-toolbar
    no-swipeback
    name="direct-records"
    class="page-direct-records"
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar :title="$t('referrer_receive_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-records" v-if="recordsList.length>0">
      <div class="card card-outline" v-for="(item,index) in recordsList" :key="index">
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-40 text-right">{{ $t('referrer_receive_records_0002') }}</div>
            <div class="col-60">
              <template>{{item.CreateTS|time_local}}</template>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-40 text-right">{{ $t('referrer_receive_records_0003') }}</div>
            <div class="col-60 co-blue">{{item.Amount}}</div>
          </div>
        </div>
      </div>
    </f7-block>
    
    <f7-card v-else-if="recordsList.length<=0 && !showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" >
    </f7-card>


  </f7-page>
</template>

<script>
import { getCookie } from '@/config/utils';
import { referrertransferhistorylist } from "../../../axios/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      recordsList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    toMyDate(date){
        let myYear = date.getFullYear().toString();
        let myMonth = (date.getMonth()+1)<10 ? '0'+(date.getMonth()+1).toString() : (date.getMonth()+1).toString();
        let myDate = date.getDate()<10 ? '0'+date.getDate().toString() : date.getDate().toString();
        let mydate = myYear + myMonth + myDate;
        mydate = parseInt(mydate);
        return mydate;
    },
    recordsListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = self.toMyDate(new Date(queryParam.BeginDate));
      queryParam.EndDate = self.toMyDate(new Date(queryParam.EndDate));
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 7;

      const params = { data: JSON.stringify(queryParam) };
      referrertransferhistorylist(params).then(data => {
        if (data.Code === "NoError") {
          self.recordsList = self.recordsList.concat(
            data.Data[0]
          );
          self.showData = self.recordsList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.recordsList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.recordsList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.recordsListEvent();
        self.allowInfinite = true;
      }, 1000);
    }
  },
  created() {
    this.recordsListEvent();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-direct-records {
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #bfb6b6;
      background-color: #eee;
      margin: 8px;
      .co-white {
        color: #fff;
      }
      .co-gray {
        color: #b8b8b9;
      }
      .co-green {
        color: #33cd5f;
      }
      .co-blue {
        color: #11c1f3;
      }
      .co-yellow {
        color: #f7da00;
      }
      .co-red {
        color: red;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #1c3b93;
      }
      .card-header {
        display: block;
        font-size: 12px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -3px;
          color: #b19785;
        }
      }
      .card-content {
        font-size: 12px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }
  
  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
<template>
  <f7-page no-toolbar no-swipeback name="deposit-details" class="deposit-details-info">
    <f7-popup v-show="showOnlineCustomPopup" class="onlineCustom-popup" :opened="onlinePopupOpened" @popup:open="openOnlineCustomPopup" @popup:close="closeOnlineCustomPopup">
      <f7-page>
        <f7-navbar :title="$t('app_0016')">
          <f7-nav-right>
            <f7-link popup-close><i class="f7-icons">multiply_circle</i></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <iframe :src="onlineCustomUrl" class="iframe-onlineCustom" frameborder="0"></iframe>
      </f7-page>
    </f7-popup>
    <f7-navbar :no-hariline="true" innerClass="inner-bankInfo">
      <f7-nav-left>
        <f7-link @click="back">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("deposit_qa_0001") }}</f7-nav-title>
    </f7-navbar>
    <template>
      <div class="deposit-head-bgimg">
        <ul>
          <li>
            <span>{{ $t("deposit_details_0001") }}</span>
          </li>
          <li>
            <span v-if="depositList.PayType === 1">{{ $t("deposit_details_0002") }}</span>
            <span v-else>{{ $t("deposit_details_0010") }}</span>
          </li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 48%">{{ $t("deposit_details_0003") }}</li>
          <li class="li-right" style="width: 45%">{{ depositList.TicketNo }}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 33%">{{ $t("deposit_details_0004") }}</li>
          <li class="li-right" style="width: 60%">{{ depositList.CreateTs | time_local }}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 43%">{{ $t("deposit_details_0005") }}</li>
          <li class="li-right" style="width: 50%">{{ depositList.BankCode == "ATM" ? $t("deposit_qa_0003") : $t("deposit_qa_0002") }}</li>
        </ul>
      </div>
      <div class="statistics" v-show="depositList.PayType != 1">
        <ul>
          <li style="width: 43%">{{ $t("deposit_details_0011") }}</li>
          <li class="li-right" style="width: 50%">{{ offlineEnum[depositList.PayType] }}</li>
        </ul>
      </div>
      <div class="statistics" v-show="depositList.PayType != 1">
        <ul>
          <li style="width: 93%">{{ $t("deposit_details_0012") }}</li>
        </ul>
      </div>
      <div class="statistics" style="color: #005fe3" v-show="depositList.PayType != 1">
        <ul>
          <li style="width: 5%">#</li>
          <li class="li-right" style="width: 88%">{{ depositList.IncomeAccountNo }}</li>
        </ul>
      </div>
      <div class="statistics" v-show="depositList.PayType != 1">
        <ul>
          <li style="width: 93%">{{ $t("deposit_details_0013") }}</li>
        </ul>
      </div>
      <div class="statistics" style="color: #e30000" v-show="depositList.PayType != 1">
        <ul>
          <li style="width: 5%">#</li>
          <li class="li-right" style="width: 88%">{{ depositList.SureName }}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 43%">{{ $t("deposit_details_0006") }}</li>
          <li class="li-right" style="width: 50%"><span class="symbol">{{ currencySymbol }}</span>{{ depositList.Amount }}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 43%">{{ $t("deposit_details_0007") }}</li>
          <li v-if="depositList.PayType === 1" class="li-right" style="width: 50%">{{ $t("betting_records_0003") }}</li>
          <li v-else class="li-right" style="width: 50%">{{ $t("daily_task_0006") }}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 93%">{{ $t("deposit_details_0008") }}</li>
        </ul>
      </div>
      <div class="statistics stat-remark">
        <ul>
          <li class="li-remark">{{ $t("deposit_details_0009") }}</li>
        </ul>
      </div>

      <div class="deposit-tail-bgimg" @click="openServiceAction()">
        <ul>
          <li>
            <a href="#" class="list-button button-single">
              <span style="display: inline-block">{{ $t("deposit_qa_0005") }}</span>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </f7-page>
</template>
<script>
import { memberwaitofflinepaymentget } from "../../../axios/api";
import { mapGetters } from "vuex";
import { getCookie,getStore } from "../../../config/utils";
let self;
export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: '',
      bindBankInfoStatus: false,
      depositList: [],
      onlinePopupOpened: false,
      onlineCustomUrl: "",
      SureNameFlag: false,
      showOnlineCustomPopup: true,
      offlineEnum: {
        1: "--",
        2: this.$t("deposit_0050"),
        3: this.$t("deposit_0051"),
        4: this.$t("deposit_0052"),
        5: this.$t("deposit_0053"),
        101: "UPI",
        102: "Paytm",
        90: "USDT(Erc20)",
        91: "USDT(Trc20)",
        92: "USDT(Omni)",
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["getMerchantInfo", "onlineCustomLoaded"]),
  },
  methods: {
    getDepositListEvent() {
      const self = this;
      memberwaitofflinepaymentget().then((data) => {
        if (data.Code === "NoError") {
          self.depositList = data.Data;
        }
      });
    },
    onCopy(e) {
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_0003"),
            cssClass: "dialog-preloadgame",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    openServiceAction() {
      const self = this;
      const baseInfo = self.getMerchantInfo.BaseInfo;
      // self.customerService = {
      //   WeChat: {
      //     'QR': (baseInfo && baseInfo.WeChatCS_QR) ? baseInfo.WeChatCS_QR.split('|') : [],
      //     'NickName': (baseInfo && baseInfo.WeChatCS_NickName) ? baseInfo.WeChatCS_NickName.split('|') : [],
      //     'Account': (baseInfo && baseInfo.WeChatCS) ? baseInfo.WeChatCS.split('|') : [],
      //   },
      //   QQ: {
      //     'QR': (baseInfo && baseInfo.QQ_QR) ? baseInfo.QQ_QR.split('|') : [],
      //     'NickName': (baseInfo && baseInfo.QQ_NickName) ? baseInfo.QQ_NickName.split('|') : [],
      //     'Account': (baseInfo && baseInfo.QQ) ? baseInfo.QQ.split('|') : [],
      //   }
      // }
      // self.actionServiceOpened = true;
      // self.onlineCustomUrl = baseInfo.MobileService;
      if (baseInfo.CSType == 2) {
        if (!this.onlineCustomLoaded) {
          let loading = self.$f7.dialog
            .create({
              title: "",
              text: "Loading...",
              cssClass: "dialog-preloadgame",
            })
            .open();
          setTimeout(() => {
            loading.close();
            this.openServiceAction();
          }, 1500);
        } else {
          window.Tawk_API.toggle();
        }
      } else {
        self.onlineCustomUrl = baseInfo.MobileService;
        //self.onlinePopupOpened = true;

        var loginName = ""; //getCookie("LoginName");
        //console.log(loginName);
        if (loginName == null || loginName == "" || loginName == undefined) {
          window.open(self.onlineCustomUrl, "_blank");
        } else {
          var userInfos = { uid: loginName };
          var params = JSON.stringify(userInfos);
          self.onlineCustomUrl = self.onlineCustomUrl + "&userInfo=" + params;
          window.open(self.onlineCustomUrl, "_blank");
        }
      }
    },
    onlineCustomService() {
      const self = this;
      self.$f7.actions.close(".actions-qq");
      self.$f7.actions.close(".actions-wechat");
      self.onlinePopupOpened = true;
    },
    back() {
      //this.$f7router.back();
      //this.$f7router.navigate('/member-center/deposit/');
      this.$f7router.navigate("/member-center/");
    },
    openOnlineCustomPopup() {
      this.showOnlineCustomPopup = true;
    },
    closeOnlineCustomPopup() {
      this.onlinePopupOpened = false;
      this.showOnlineCustomPopup = false;
    },
  },
  created() {
    self = this;
    sessionStorage.setItem("deposit-flag", "1");
    this.getDepositListEvent();
    this.currencySymbol = getStore('currencySymbol');
  },
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.deposit-details-info {
  --f7-page-toolbar-bottom-offset: 0;

  .deposit-head-bgimg {
    width: 95%;
    margin: auto;
    text-align: center;
    margin-top: 10px;
    background: #e5e4dc;
    border-radius: 5px;
    ul {
      span {
        font-family: "Cairo";

        color: #000;
      }
      li:nth-child(1) {
        padding: 20px 20px 0px 20px;
        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 21px;
        }
      }
      li:nth-child(2) {
        padding: 5px 20px 10px 20px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }

  .statistics {
    position: relative;
    width: 95%;
    margin: auto;
    background: #fafafa;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    border: 1px solid #1b2a3b10;
    li {
      width: 100%;
      display: inline-block;
      margin-top: 10px;
      text-align: left;
      //margin-left: 10px;
      margin-bottom: 10px;
    }
    .li-right {
      text-align: right;
      margin-left: unset;
      //margin-right: 10px;
    }
  }
  .stat-remark {
    padding: 10px 0px 10px 0px;
    .li-remark {
      width: 90%;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
    }
  }

  .deposit-tail-bgimg {
    width: 95%;
    margin: auto;
    text-align: center;
    background: var(--f7-button-bg-color);
    border-radius: 6px;
    margin-top: 10px;
    ul {
      span {
        font-family: "Cairo";

        color: var(--f7-button-text-color);
      }
      li:nth-child(1) {
        padding: 3px 10px 3px 10px;
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
        }
      }
      li:nth-child(2) {
        padding: 5px 20px 10px 20px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
  .button-save {
    margin: 20px 0px;

    ul {
      background: var(--f7-button-bg-color);
      li {
        a {
          color: var(--f7-button-text-color);
        }
      }
    }
  }
}
</style>

<template>
  <!-- Views/Tabs container -->

  <f7-page no-toolbar no-swipeback name="deposit-qa" class="deposit-qa-info">
    <f7-navbar :no-hariline="true" innerClass="inner-bankInfo">
      <f7-nav-left>
        <f7-link @click="back">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("deposit_qa_0001") }}</f7-nav-title>
    </f7-navbar>
    <div v-show="showUI == true" class="deposit-balance-bgimg">
      <ul>
        <li>
          <span v-if="totalBalance == -99999">--</span>
          <span v-else><span class="symbol">{{ currencySymbol }}</span>{{ totalBalance }}</span>
        </li>
        <li>
          <span>{{ $t("member_center_0046") }}</span>
        </li>
      </ul>
    </div>
    <div class="buttons-tab buttons-deposit">
      <f7-link tab-link="#tab-bank-transfer" tab-link-active :text="$t('deposit_qa_0003')"></f7-link>
      <f7-link tab-link="#tab-offline-recharge" :text="$t('deposit_qa_0002')"></f7-link>
    </div>
    <f7-tabs class="tabs-deposit">
      <f7-tab @tab:show="tabInit(3)" id="tab-bank-transfer" class="page-content tab-bank-transfer" style="padding-top: 5px" tab-active>
        <f7-block v-show="showUI == true" class="deposit-info">
          <div class="form">
            <div class="form-item pay-notice">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("deposit_qa_0004") }}</div>
              </div>
            </div>
            <div class="form-item">
              <div class="pay-method">
                <span class="checkbox-item" key="1">
                  <label class="checkbox">
                    <input type="checkbox" :checked="checkBankCodeATM" name="name1" @change="changeBank('ATM')" />
                    <i class="icon-checkbox"></i>
                  </label>
                </span>
                <div class="pay-title" style="">{{ $t("deposit_qa_0003") }}</div>
                <div class="pay-info" style="">
                  <ul>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_1.png" style="" />
                    </li>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_2.png" style="" />
                    </li>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_3.png" style="" />
                    </li>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_4.png" style="" />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="pay-method" v-show="1 === 0">
                <span class="checkbox-item" key="2">
                  <label class="checkbox">
                    <input type="checkbox" :checked="checkBankCodeBCTFS" name="name2" @change="changeBank('BCTFS')" />
                    <i class="icon-checkbox"></i>
                  </label>
                </span>
                <div class="pay-title" style="">{{ $t("deposit_qa_0002") }}</div>
                <div class="pay-info" style="">
                  <ul>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_5.png" style="" />
                      <!-- <i style="background: url(../../../static/images/yt999/pay/pay_1.png); background-size: cover;"></i> -->
                    </li>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_6.png" style="" />
                    </li>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_7.png" style="" />
                    </li>
                    <li style="">
                      <img src="../../../static/images/yt999/pay/pay_8.png" style="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="form-item input-amt">
              <div class="item-inner inner-amt">
                <div class="item-title item-label item-amt">$</div>
                <div class="item-input-wrap">
                  <input class="input-amt" type="number" :placeholder="$t('deposit_0060')" v-model="bankTransferForm.Amount" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div block class="item-amount-quick">
                <ul>
                  <li>
                    <div :class="{ active: 1000 === bankTransferForm.Amount }" @click="toggleAmount(1000)"><span style="">$1000</span></div>
                  </li>
                  <li>
                    <div :class="{ active: 3000 === bankTransferForm.Amount }" @click="toggleAmount(3000)"><span>$3000</span></div>
                  </li>
                  <li>
                    <div :class="{ active: 5000 === bankTransferForm.Amount }" @click="toggleAmount(5000)"><span>$5000</span></div>
                  </li>
                  <li>
                    <div :class="{ active: 10000 === bankTransferForm.Amount }" @click="toggleAmount(10000)"><span>$10000</span></div>
                  </li>
                  <li>
                    <div :class="{ active: 30000 === bankTransferForm.Amount }" @click="toggleAmount(30000)"><span>$30000</span></div>
                  </li>
                  <li>
                    <div :class="{ active: 50000 === bankTransferForm.Amount }" @click="toggleAmount(50000)"><span>$50000</span></div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="form-item confirm-notice">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("deposit_qa_0006") }}</div>
                <div class="item-title item-label">{{ $t("deposit_qa_0007") }}</div>
              </div>
            </div>
            <div class="list inset button-save">
              <ul>
                <li>
                  <a href="#" class="list-button button-single" @click="bankTransferSubmit()" click="bankInfoSubmit">
                    <span style="display: inline-block">{{ $t("apply_activity_0005") }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="tabInit(1)" id="tab-offline-recharge" class="page-content tab-offline" style="padding-top: 5px">
        <template v-if="qrCodeData.length > 0 && qrCodeDataInit">
          <!-- <img class="panel-img" :src="selectedPay.qrCodeImg" @click="clickRedirectTo(selectedPay.ClickRedirect)"> -->
          <f7-block class="panel-qrcode">
            <div class="qrcode-info text-center" v-if="selectedIndex > 0">
              <img :src="selectedPay.qrCodeImg" @click="clickRedirectTo(selectedPay.ClickRedirect)" />
              <p class="qr-selectCode">{{ selectedPay.account }} ({{ selectedPay.name }})</p>
              <p class="qr-remark">{{ $t("deposit_0021") }}</p>
              <!-- <div class="qr-name">{{offlinePayList[selectedIndex].item[0].Name}}</div> -->
              <f7-segmented round tag="p" class="qrcode-list-btn qrcode-detail-list" v-if="selectedQRCode.length > 0">
                <f7-button round outline v-for="(item, key) in selectedQRCode" :key="key" :active="item.Id === selectedPay.offlineId" @click="selectQrCodeDetail(item)">{{ item.Name }}</f7-button>
              </f7-segmented>
              <f7-button class="click-copy" v-clipboard:copy="selectedPay.account" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t("deposit_0022") }}</f7-button>
            </div>
            <f7-segmented round tag="p" class="qrcode-list-type-btn" v-if="selectedIndex > 0">
              <f7-button round outline v-for="(value, key) in offlinePayList" :key="key" :active="value.type == selectedQRCode[0].Type" @click="toggleOfflinePay(value)">{{ value.name }}</f7-button>
            </f7-segmented>
          </f7-block>
          <div class="block-title block-title-payinfo">{{ $t("deposit_0023") }}</div>
          <div class="list list-pay inline-labels">
            <ul>
              <li>
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">
                      <i class="iconfont icon-jinbi"></i>
                    </div>
                    <div class="item-input-wrap">
                      <input type="number" :placeholder="$t('common_deposit_onlineminmaxamount_format', [selectedPay.offlineMinAmt, selectedPay.offlineMaxAmt])" v-model="offlineForm.Amount" />
                    </div>
                  </div>
                </div>
              </li>
              <li v-show="selectedPay.EnableRealDepositRate">
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label"><i class="iconfont icon-jinbi"></i></div>
                    <div class="item-input-wrap">
                      <label>{{ $t("deposit_0025") }} {{ getQRRealAmount }}</label>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">
                      <i class="iconfont icon-mingxiqingdanlei"></i>
                    </div>
                    <div class="item-input-wrap">
                      <input type="text" :placeholder="$t('deposit_0026') + ' ' + offlineConfirmNoDesc" v-model="offlineForm.AccountName" />
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="validData.length > 0">
                <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                  <select name="select-valid" v-model="offlineForm.PromotionId">
                    <option v-for="item in validData" :value="item[0]" :key="item[0]">{{ item[1] }}</option>
                  </select>
                  <div class="item-content">
                    <div class="item-inner">
                      <div class="item-title"><i class="iconfont icon-Giftliwu1"></i></div>
                      <div class="item-after">{{ validData[0][1] }}</div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div v-show="selectedPay.EnableRealDepositRate"></div>
          <f7-list inset class="list-btn">
            <f7-list-button class="button-single" :title="$t('deposit_0007')" color="white" style="background-color: red; border-radius: 8px" @click="offlineSubmit"></f7-list-button>
          </f7-list>
          <f7-block-title class="block-title-tip">{{ $t("deposit_0027") }}:</f7-block-title>
          <f7-block class="block-tip">
            <p>1.&nbsp; {{ $t("deposit_0028") }}</p>
            <!-- <p>2.&nbsp; {{ $t("deposit_0029") }}</p> -->
            <p>2.&nbsp; {{ $t("deposit_0030") }}</p>
          </f7-block>
        </template>
        <template v-if="qrCodeData.length == 0 && qrCodeDataInit">
          <f7-card class="no-data">
            <img :src="`../../../static/images/yt999/hd_nodata_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" />
          </f7-card>
        </template>
      </f7-tab>
    </f7-tabs>

    <!-- 在线客服popup -->
    <f7-popup v-show="showOnlineCustomPopup" class="onlineCustom-popup" :opened="onlinePopupOpened" @popup:open="openOnlineCustomPopup" @popup:close="closeOnlineCustomPopup">
      <f7-page>
        <f7-navbar :title="$t('app_0016')">
          <f7-nav-right>
            <f7-link popup-close><i class="f7-icons">multiply_circle</i></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <iframe :src="onlineCustomUrl" class="iframe-onlineCustom" frameborder="0"></iframe>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import { getCookie, setStore, getStore } from "../../../config/utils";
import { getValidList, getQrList, payQR, getmemberwinloserp, depositqalist, payBankTransfer, memberwaitofflinepaymentget } from "../../../axios/api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
let self;
export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: '',
      showUI: false,
      validData: [],
      qrCodeData: [],
      qrCodeDataInit: false,
      checkBankCodeATM: true,
      checkBankCodeBCTFS: false,
      bindBankInfoStatus: false,
      selectedPay: {
        qrCodeImg: "",
        offlineId: 0,
        offlineMinAmt: 0,
        offlineMaxAmt: 0,
        name: "",
        account: "",
        EnableRealDepositRate: false,
        RealDepositRate: 1,
        ClickRedirect: "",
      },
      selectedQRCode: [],
      offlineEnum: {
        1: this.$t("deposit_0049"),
        2: this.$t("deposit_0050"),
        3: this.$t("deposit_0051"),
        4: this.$t("deposit_0052"),
        5: this.$t("deposit_0053"),
        101: "UPI",
        102: "Paytm",
        90: "USDT(Erc20)",
        91: "USDT(Trc20)",
        92: "USDT(Omni)",
      },
      offlinePayList: {},
      depositqaList: [],
      bankInfoForm: {
        BankAccountNo: "",
        SureName: "",
        BankAddress: "",
        BankName: "",
      },
      winloseParams: {
        DateRangeType: 1,
      },
      winloseInfo: {},
      totalBalance: -99999,
      bankTransferForm: {
        BankId: 0,
        BankCode: "ATM",
        TransferType: 6,
        PayType: 1,
        Amount: "",
        BankAccountNo: "--",
        AccountName: "--",
        PromotionId: 0,
        RealDepositRate: 1,
        DepositTs: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      },
      onlinePayForm: {
        PayAccountId: 0,
        PromotionId: "0",
        PayPlatformType: "",
        Amount: "",
        SupportPlatform: 2,
      },
      offlineForm: {
        BankId: null,
        PayType: null,
        Amount: "",
        AccountName: "",
        BankAccountNo: "",
        PromotionId: "0",
        RealDepositRate: 0,
      },
      eBankForm: {
        minAmount: 0,
        maxAmount: 0,
        Amount: "",
        BankCode: "",
        PromotionId: "0",
        OnlineBlankList: [],
      },
      memberBankCardList: [],
      onlinePopupOpened: false,
      onlineCustomUrl: "",
      SureNameFlag: false,
      showOnlineCustomPopup: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["getMerchantInfo", "onlineCustomLoaded"]),
    getQRRealAmount: function () {
      if (this.selectedPay.EnableRealDepositRate == false) {
        return 0;
      } else {
        if (this.offlineForm.Amount <= 0) {
          return 0;
        }
        if (this.selectedPay.RealDepositRate <= 0) {
          return 0;
        }
        return Math.round((this.offlineForm.Amount / this.selectedPay.RealDepositRate) * 1000) / 1000;
      }
    },
    offlineConfirmNoDesc: function () {
      switch (this.selectedIndex) {
        case 90:
        case 91:
        case 92:
          return this.$t("deposit_0054");
        default:
          return this.$t("deposit_0055");
      }
    },
  },
  methods: {
    onCopy(e) {
      if (e.text) {
        let copyLoading = self.$f7.dialog
          .create({
            title: "",
            text: this.$t("common_0003"),
            cssClass: "dialog-preloadgame",
          })
          .open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    changeBank(item) {
      if (item == "ATM") {
        this.checkBankCodeATM = true;
        this.checkBankCodeBCTFS = false;
        this.bankTransferForm.BankCode = "ATM";
      } else {
        this.bankTransferForm.BankCode = "BCTFS";
        this.checkBankCodeATM = false;
        this.checkBankCodeBCTFS = true;
      }
    },
    toggleAmount(value) {
      if (value == "") {
        return;
      }
      this.bankTransferForm.Amount = value;
    },
    getDepositQAListEvent() {
      const self = this;
      depositqalist().then((data) => {
        if (data.Code === "NoError") {
          self.depositqaList = data.Data;
        }
      });
    },
    openServiceAction() {
      const self = this;
      const baseInfo = self.getMerchantInfo.BaseInfo;
      // self.customerService = {
      //   WeChat: {
      //     'QR': (baseInfo && baseInfo.WeChatCS_QR) ? baseInfo.WeChatCS_QR.split('|') : [],
      //     'NickName': (baseInfo && baseInfo.WeChatCS_NickName) ? baseInfo.WeChatCS_NickName.split('|') : [],
      //     'Account': (baseInfo && baseInfo.WeChatCS) ? baseInfo.WeChatCS.split('|') : [],
      //   },
      //   QQ: {
      //     'QR': (baseInfo && baseInfo.QQ_QR) ? baseInfo.QQ_QR.split('|') : [],
      //     'NickName': (baseInfo && baseInfo.QQ_NickName) ? baseInfo.QQ_NickName.split('|') : [],
      //     'Account': (baseInfo && baseInfo.QQ) ? baseInfo.QQ.split('|') : [],
      //   }
      // }
      // self.actionServiceOpened = true;
      // self.onlineCustomUrl = baseInfo.MobileService;
      if (baseInfo.CSType == 2) {
        if (!this.onlineCustomLoaded) {
          let loading = self.$f7.dialog
            .create({
              title: "",
              text: "Loading...",
              cssClass: "dialog-preloadgame",
            })
            .open();
          setTimeout(() => {
            loading.close();
            this.openServiceAction();
          }, 1500);
        } else {
          window.Tawk_API.toggle();
        }
      } else {
        self.onlineCustomUrl = baseInfo.MobileService;
        self.onlinePopupOpened = true;
      }
    },
    getMemberWinloseRP() {
      const self = this;
      self.totalBalance = -99999;
      self.showWinlose = false;
      const param = { data: JSON.stringify(self.winloseParams) };
      getmemberwinloserp(param).then((data) => {
        if (data.Code === "NoError") {
          self.winloseInfo = data.Data;
          self.totalBalance = self.winloseInfo[0].TotalBalance;
        }
      });
    },
    bankTransferSubmit() {
      const self = this;
      if (!self.bankTransferForm.Amount) {
        self.$f7.dialog.alert(this.$t("deposit_0060"), this.$t("common_0004"));
        return false;
      }
      const param = { data: JSON.stringify(this.bankTransferForm) };
      let promtLoadingGame = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("Order_0017"),
          cssClass: "dialog-preloadorder",
        })
        .open();
      self.$f7.params.dialog.closeByBackdropClick = false;
      //self.$f7.$('.dialog-backdrop').css('background-color', 'transparent');

      payBankTransfer(param).then((data) => {
        self.$f7.params.dialog.closeByBackdropClick = true;
        //self.$f7.$('.dialog-backdrop').css('background-color', 'rgba(0, 0, 0, 0.74)');
        promtLoadingGame.close();

        if (data.Code === "NoError") {
          // self.$f7.dialog.alert(this.$t('deposit_0056'), this.$t('common_0004'));
          self.$f7router.navigate("/member-center/deposit-details/");
          self.bankTransferForm.AccountName = "";
          self.bankTransferForm.Amount = "";
          self.bankTransferForm.BankAccountNo = "";
        } else if (data.Code === "Exist" || data.Code === "DepositSubmitLimit") {
          self.$f7.dialog.confirm(
            this.$t("deposit_qa_0008"),
            this.$t("common_0004"),
            () => {
              self.$f7router.navigate("/member-center/deposit-details/");
            },
            () => {
              // self.$f7router.back();
            }
          );
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
    onlineCustomService() {
      const self = this;
      self.$f7.actions.close(".actions-qq");
      self.$f7.actions.close(".actions-wechat");
      self.onlinePopupOpened = true;
    },
    tabInit(d) {
      const self = this;
      if (d === 1) {
        self.onlinePayShowCode = false;
        clearInterval(self.intervalTime);
        self.validList("QrCodeOfflinePay");
        self.qrList();
        clearInterval(self.intervalTime);
      } else if (d === 2) {
        self.onlinePayShowCode = false;
        self.validList("QrCodeOnlinePay");
        self.onlinePayTypeList();
      } else if (d === 3) {
        self.onlinePayShowCode = false;
        clearInterval(self.intervalTime);
        self.validList("OfflineBankPay");
      } else if (d === 4) {
        self.onlinePayShowCode = false;
        self.validList("BankOnlinePay");
        self.getOnlineBlankList();
      }
    },
    back() {
      if (this.$f7route.params.Id == 0) {
        this.$f7router.back();
      } else {
        this.$f7router.navigate("/member-center/");
      }
    },
    openOnlineCustomPopup() {
      this.showOnlineCustomPopup = true;
    },
    closeOnlineCustomPopup() {
      this.onlinePopupOpened = false;
      this.showOnlineCustomPopup = false;
    },
    validList(type) {
      const self = this;
      const param = { data: type };
      getValidList(param).then((data) => {
        if (data.Code === "NoError") {
          self.validData = data.Data;
          if (data.Data.length > 0) {
            self.offlineForm.PromotionId = data.Data[0][0];
            self.onlinePayForm.PromotionId = data.Data[0][0];
            self.bankTransferForm.PromotionId = data.Data[0][0];
            self.eBankForm.PromotionId = data.Data[0][0];
          } else {
            self.offlineForm.PromotionId = 0;
            self.onlinePayForm.PromotionId = 0;
            self.bankTransferForm.PromotionId = 0;
            self.eBankForm.PromotionId = 0;
          }
        }
      });
    },
    qrList() {
      const self = this;
      getQrList().then((data) => {
        if (data.Code === "NoError") {
          self.qrCodeData = data.Data;
          self.qrCodeDataInit = true;
          if (data.Data.length > 0) {
            self.selectedIndex = data.Data[0].Type;
            data.Data.forEach((item) => {
              if (!self.offlinePayList[item.Type]) {
                self.offlinePayList[item.Type] = {
                  name: "",
                  item: [],
                  type: "",
                };
              }
              self.offlinePayList[item.Type]["type"] = item.Type;
              self.offlinePayList[item.Type]["name"] = self.offlineEnum[item.Type];
              self.offlinePayList[item.Type]["item"].push(item);
            });
            self.selectedPay.qrCodeImg = getStore("ImagesURL") + window.configs.qrcodeImg + self.qrCodeData[0].QRList[0].Url;
            self.selectedQRCode = self.qrCodeData[0].QRList;
            self.selectedPay.offlineId = self.offlineForm.BankId = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].Id;
            self.selectedPay.offlineMinAmt = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].MinAmount;
            self.selectedPay.offlineMaxAmt = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].MaxAmount;
            self.selectedPay.name = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].Name;
            self.selectedPay.account = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].CardNumber;
            self.selectedPay.EnableRealDepositRate = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].EnableRealDepositRate;
            self.selectedPay.RealDepositRate = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].RealDepositRate;
            self.selectedPay.ClickRedirect = self.offlinePayList[self.selectedIndex] && self.offlinePayList[self.selectedIndex].item[0].QRList[0].ClickRedirect;
          }
        }
      });
    },
    toggleOfflinePay(d) {
      //线下充值选择支付方式
      this.selectedIndex = d.type;
      this.selectedQRCode = d.item[0].QRList;
      this.selectedPay.qrCodeImg = getStore("ImagesURL") + window.configs.qrcodeImg + d["item"][0].QRList[0].Url;
      this.selectedPay.offlineId = this.offlineForm.BankId = d["item"][0].QRList[0].Id;
      this.selectedPay.offlineMinAmt = d["item"][0].QRList[0].MinAmount;
      this.selectedPay.offlineMaxAmt = d["item"][0].QRList[0].MaxAmount;
      this.selectedPay.name = d["item"][0].QRList[0].Name;
      this.selectedPay.account = d["item"][0].QRList[0].CardNumber;
      this.selectedPay.EnableRealDepositRate = d["item"][0].QRList[0].EnableRealDepositRate;
      this.selectedPay.RealDepositRate = d["item"][0].QRList[0].RealDepositRate;
      this.selectedPay.ClickRedirect = d["item"][0].QRList[0].ClickRedirect;
    },
    offlineSubmit() {
      //线下充值
      const self = this;
      if (self.offlineForm.Amount == "" || self.offlineForm.Amount < self.selectedPay.offlineMinAmt || self.offlineForm.Amount > self.selectedPay.offlineMaxAmt) {
        self.$f7.dialog.alert(this.$t("common_deposit_minmaxamount_format", [self.selectedPay.offlineMinAmt, self.selectedPay.offlineMaxAmt]), this.$t("common_0004"));
        return false;
      }
      // if (!self.offlineForm.BankAccountNo) {
      //   self.$f7.dialog.alert(`请输入支付账户`, "温馨提示");
      //   return false;
      // }
      if (!self.offlineForm.AccountName) {
        self.$f7.dialog.alert(this.$t("deposit_0026") + self.offlineConfirmNoDesc, this.$t("common_0004"));
        return false;
      }
      if (self.selectedPay.EnableRealDepositRate) {
        self.offlineForm.RealDepositRate = self.selectedPay.RealDepositRate;
      } else {
        self.offlineForm.RealDepositRate = 0;
      }
      self.offlineForm.PayType = self.selectedIndex * 1 + 1;
      self.offlineForm.BankAccountNo = "--";
      const param = { data: JSON.stringify(self.offlineForm) };
      let promtLoadingGame = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("Order_0017"),
          cssClass: "dialog-preloadorder",
        })
        .open();
      self.$f7.params.dialog.closeByBackdropClick = false;
      payQR(param).then((data) => {
        self.$f7.params.dialog.closeByBackdropClick = true;

        promtLoadingGame.close();
        if (data.Code === "NoError") {
          self.offlineForm = {
            PayType: null,
            Amount: "",
            BankAccountNo: "",
            AccountName: "",
            RealDepositRate: 0,
          };
          //self.$f7.dialog.alert(this.$t("deposit_0056"), this.$t("common_0004"));
          self.$f7router.navigate("/member-center/deposit-details/");
        } else if (data.Code === "Exist" || data.Code === "DepositSubmitLimit") {
          self.$f7.dialog.confirm(
            this.$t("deposit_qa_0008"),
            this.$t("common_0004"),
            () => {
              self.$f7router.navigate("/member-center/deposit-details/");
            },
            () => {
              // self.$f7router.back();
            }
          );
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
  },
  created() {
    self = this;
    this.currencySymbol = getStore('currencySymbol');
    this.totalBalance = -99999;
    var depositFlag = sessionStorage.getItem("deposit-flag") ?? "";
    if (depositFlag === "") {
      sessionStorage.removeItem("deposit-flag");
      memberwaitofflinepaymentget().then((data) => {
        if (data.Code === "NoError") {
          if (data.Data.TicketNo) {
            self.$f7router.navigate("/member-center/deposit-details/");
          } else {
            this.showUI = true;
          }
        }
      });
    } else {
      sessionStorage.removeItem("deposit-flag");
      this.showUI = true;
    }
    this.getDepositQAListEvent();
    this.getMemberWinloseRP();

  },
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.deposit-qa-info {
  --f7-page-toolbar-bottom-offset: 0;
  .buttons-deposit {
    height: var(--f7-px-50);
    width: var(--f7-width-size-pt100);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    box-sizing: var(--f7-border-box);
    align-items: var(--f7-text-center);
    align-content: var(--f7-text-center);
    overflow: var(--f7-hidden);
    //background-color: #0C1939;

    a.tab-link {
      width: var(--f7-width-size-pt100);
      height: var(--f7-height-size-pt100);
      box-sizing: var(--f7-border-box);
      display: var(--f7-display-flex);
      justify-content: var(--f7-text-center);
      align-items: var(--f7-text-center);
      flex-direction: var(--f7-flex-direction);
      text-transform: var(--f7-tabbar-link-text-transform);
      font-weight: var(--f7-tabbar-link-font-weight);
      letter-spacing: var(--f7-tabbar-link-letter-spacing);
      overflow: var(--f7-hidden);
      color: #808080;

      i {
        margin-top: var(--f7-padding-size-2);
        font-size: var(--f7-px-32);
      }

      span {
        font-size: var(--f7-px-14);
        margin-left: var(--f7-margin-size-0);
      }
    }

    a.tab-link:after {
      content: "";
      //background: url(../static/images/yt999/navafter.png) no-repeat;
      width: var(--f7-width-size-2);
      height: var(--f7-height-size-pt100);
      position: absolute;
      right: var(--f7-size-ps-0);
      top: var(--f7-size-ps-0);
      background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
    }

    a.tab-link:last-child:after {
      height: var(--f7-height-size-0);
    }

    a.tab-link.tab-link-active {
      color: var(--f7-THEME-color);
      border-bottom: 1px solid var(--f7-THEME-color);

      i.iconfont {
        color: var(--f7-color-blue-active);
      }
    }
  }
  .tab-offline {
    .panel-img {
      width: 100%;
    }

    .panel-qrcode {
      margin: var(--f7-margin-size-0);
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-bottom: 1px solid #D0D0D0;
      padding-bottom: var(--f7-px-15);

      .qrcode-info {
        width: 75%;
        margin: 0 auto;
        padding: var(--f7-padding-size-5) 0;
        background: #e9e9e9;
        margin-top: var(--f7-margin-size-10);
        padding-top: var(--f7-padding-size-8);

        img {
          width: 60%;
        }

        p {
          margin: var(--f7-px-5) 12px;
        }

        .qrcode-detail-list {
          a.button {
            height: 32px;
            line-height: 32px;
          }
        }

        .click-copy {
          border-radius: unset;
          background-color: #e9e9e9;
          color: #0070e4;
        }

        p.qr-selectCode {
          font-size: var(--f7-font-size-14);
          color: #464646;
          word-break: break-word;
        }

        p.qr-remark {
          font-size: var(--f7-font-size-14);
          color: #d41e1e;
        }

        .qr-name {
          background: #0c214e;
          color: #fff;
          padding: var(--f7-padding-size-6) 0;
          width: 50%;
          margin: 0 auto;
          text-align: center;
          border: 1px solid #21438a;
          border-radius: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .qrcode-list-type-btn {
        width: 260px;
        a.button {
          background-color: #FF0001;
          border: 1px solid #C50001;
        }

        a.button-active {
          color: #fff;
          background-color: #C50001;
        }
      }

      .qrcode-list-btn {
        width: var(--f7-size-percent-90);

        a.button {
          height: var(--f7-px-40);
          line-height: var(--f7-px-40);
          background-color: #ffffff;
          color: #000;
          border-color: #D0D0D0;
          padding: var(--f7-padding-size-0);          
        }

        a.button-active {
          color: #000;
          background: #ffffff;
        }
      }
    }

    .block-title-payinfo {
      margin-top: var(--f7-margin-size-15);
      margin-bottom: var(--f7-margin-size-15);
      font-size: var(--f7-px-16);
      color: #464646;
    }

    .list-pay {
      ul {
        background-color: var(--f7-color-bg-transparent);

        .item-input {
          .item-inner {
            .item-title {
              padding-top: 0;
            }
          }
        }

        .item-content {
          .item-inner {
            .item-title {
              color: #464646;
              width: auto;
              text-align: left;
              font-size: var(--f7-px-16);
            }

            .item-input-wrap {
              label {
                color: #d43030;
                font-size: 14px;
              }
            }

            input {
              font-size: var(--f7-font-size-14);
            }

            .item-after {
              font-size: var(--f7-font-size-14);
              color: #464646;
              width: auto;
              max-width: unset;
              flex: 1;
              padding-left: 8px;
            }
          }
        }
      }

      ul:before,
      ul:after {
        height: 0;
      }
    }

    .list-btn {
      margin-bottom: var(--f7-margin-size-15);

      ul {
        background: var(--f7-button-bg-color);
      }
    }

    .block-title-tip {
      margin-top: var(--f7-margin-size-10);
      font-size: var(--f7-px-16);
      color: #464646;
    }

    .block-tip {
      margin-bottom: var(--f7-px-160);

      p {
        color: #464646;
      }
    }

    .list .item-inner:after {
      right: 10px;
      left: 0px;
    }

    .list li:last-child > .item-link.smart-select > .item-content > .item-inner:after {
      display: var(--f7-display-block) !important;
    }
  }
  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
  .deposit-balance-bgimg {
    width: 95%;
    margin: auto;
    text-align: center;
    margin-top: 10px;
    background-color: #e5e4dc;
    background-size: cover;
    border-radius: 10px;
    ul {
      span {
        font-family: "Cairo";

        color: #000;
      }
      li:nth-child(1) {
        padding: 20px 20px 0px 20px;
        span {
          font-weight: 500;
          font-size: 24px;
          line-height: 21px;
        }
      }
      li:nth-child(2) {
        padding: 5px 20px 10px 20px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
        }
      }
    }
  }
  .deposit-info {
    margin: 10px 12px 12px;
    padding: 0;
    .form {
      .input-amt {
        margin-top: 30px;
      }
      .pay-notice {
        margin-top: 15px;
        margin-bottom: 15px;
        .item-label {
          font-size: 16px;
          font-weight: 550;
          margin-left: 3px;
        }
      }
      .confirm-notice {
        margin-top: 15px;
        margin-bottom: 15px;
        .item-label {
          font-size: 15px;
          font-weight: 550;
          text-align: center;
          color: #5f5f5f;
        }
      }
      .item-inner {
        .input-amt {
          width: 95%;
          line-height: 24px;
          margin: auto;
          border-bottom: 1px solid #cecece;
        }
        hr {
          border: 1px solid rgb(206, 206, 206);
          position: absolute;
          width: 96%;
          top: 30px;
          border-top: 1px;
          text-align: center;
        }

        ul {
          li:nth-child(1) {
            float: left;
            margin-left: 10px;
            margin-right: 10px;
            span {
              font-weight: 700;
              font-size: 20px;
              line-height: 21px;
              text-align: center;
              color: #000000;
            }
          }
          li:nth-child(2) {
            float: left;
            margin-right: 30px;
            width: 80%;
            margin-bottom: 20px;
          }
        }
      }
      .inner-amt {
        position: relative;
        .item-amt {
          position: absolute;
          left: 2%;
          top: -6px;
          font-size: 24px;
          font-weight: 500;
        }
      }

      .item-amount-quick {
        width: 100%;
        height: 90px;
        margin: auto;
        ul {
          li {
            float: left;
            width: 31%;
            padding: 1%;
            text-align: center;
            div {
              border: 1px solid rgb(206, 206, 206);
              border-radius: 5px;
              padding: 6px 12px 6px 12px;
            }
            span {
              font-weight: 600;
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .active {
          background-color: #e5e4dc;
          // background: url(../../../static/images/yt999/active_bg.png) no-repeat;
          // background-size: 100% 100%;
          // border: 1px solid #ffc107;
          span {
            color: #000;
          }
        }
      }

      .pay-method {
        background: #eeeeee;
        border-radius: 5px;
        height: 90px;
        position: relative;
        margin-bottom: 6px;
        .checkbox-item {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100px;
          margin-right: 3%;
        }
        .pay-title {
          padding: 10px 0px 0px 20px;
          font-family: "Poppins";
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          position: absolute;
          left: 0;
          top: 0;
        }

        .pay-info {
          padding: 10px 0px 0px 10px;
          position: absolute;
          left: 0px;
          top: 35px;
          ul {
            li {
              float: left;
              margin-left: 10px;
              margin-right: 10px;
              width: 28px;
              img {
                width: 28px;
                height: 28px;
              }
            }
          }
          .item-input-wrap {
            .checkbox-item {
              .checkbox {
                position: absolute;
                // width: 16px;
                // height: 16px;
                left: 350px;
                top: 240px;
              }
              .checkbox i {
                border: 1px solid #3265ce;
              }
            }
          }
        }
      }

      .item-title {
        color: #464646;
      }
      .item-input-wrap {
        // width: 100%;
        // background: #fafafa;
        // border: 1px solid #1b2a3b10;
        // padding: 10px;
        // border-radius: 6px;
        margin: 2px 0 10px;
        width: 100%;
        input {
          width: 100%;
          text-align: center;

          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #464646;
        }
        input[type="number"]::placeholder {
          text-align: center;

          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #c0c0c0;
        }
      }
      .smart-select .item-after {
        max-width: unset;
      }
    }
  }
  .qaimg {
    width: 95%;
    margin: auto;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    img {
      border-radius: 5px;
      max-width: 100%;
      max-height: 100%;
      width: 100px;
      height: auto;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .qalist {
    background: #f1f1f1;
    ul {
      background: #f1f1f1;
    }
  }
  .statistics {
    width: 95%;
    margin: auto;
    background: #f1f1f1;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;

    .title {
      text-align: center;
      font-size: 18px;
      padding-top: 6px;
      font-weight: bold;
      color: #ad5c09;
    }
    .amount {
      text-align: center;
      font-size: 18px;
      padding-top: 6px;
      font-weight: bold;
      color: #ad5c09;
    }
    .desc {
      border-top: 1px solid #e0dbdb;
      margin-top: 10px;
      .p1 {
        margin: 10px;
        font-weight: bold;
      }
      .p2 {
        margin-left: 10px;
        margin-right: 10px;
        padding-bottom: 10px;
        word-wrap: break-word;
      }
    }
  }
  .accordion-list {
    //background-color: #EEEEEE;
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    margin: auto;
    .accordion-item {
      border-bottom: 1px solid #f0f0f0;
      .col-title {
        position: relative;
        .help {
          position: absolute;
          left: 0px;
          border-radius: 5px;
          width: 19px;
          height: auto;
          vertical-align: middle;
        }
        .s-title {
          width: 94%;
          margin-left: 15px;
          margin-top: -1px;
          font-size: 15px;
          color: #464646;
          text-decoration: underline;
          word-break: break-all;
          word-wrap: break-word;
        }
        .accordion-item-content {
          .ans {
            width: 100%;
            margin-left: 10px 10px 10px 10px;
            // word-break: break-all;
            // word-wrap: break-word;
          }
        }
      }
    }
  }
  .bank-card {
    margin: 0px;
    background: -webkit-linear-gradient(#6d9af6, #6d9af6);
    background: -o-linear-gradient(#6d9af6, #6d9af6);
    background: -moz-linear-gradient(#6d9af6, #6d9af6);
    background: -mos-linear-gradient(#6d9af6, #6d9af6);
    background: linear-gradient(#6d9af6, #6d9af6);
    border-radius: 8px;
    color: #fff;

    .card-header {
      justify-content: flex-start;
      margin: 0 12px;
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid #5e89e1;

      .card-avatar {
        img {
          vertical-align: middle;
        }
      }

      .card-name,
      .card-accountname {
        margin: 0 10px;
        font-size: 16px;
        position: relative;
      }

      .card-name:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 15px;
        background-color: #fff;
        right: -10px;
        top: 4px;
        display: block;
      }
    }

    .card-header:after {
      height: 0;
    }

    .card-content {
      .card-info {
        margin: 6px 10px;
        padding: 5px 0;
      }

      .card-address {
        margin: 6px 14px;
      }
    }

    .card-footer {
      padding: 15px 8px;

      .block {
        width: 100%;

        .button {
          background-color: #3569cf;
          color: #ffffff;
        }
      }
    }

    .card-footer:before {
      height: 0;
    }
  }
  .block-bank-title {
    color: var(--f7-THEME-color);
    margin: 20px 0 10px;
  }
  .block-bank-info {
    margin: 5px 0 10px;
    padding: 0 10px;
    .list-bank-info {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 8px;
              color: #464646;
              width: 25%;
              padding-right: 5px;
            }
            input {
              color: #464646;
              font-size: 14px;
            }
          }
          .item-inner:after {
            height: 0;
          }
        }
        .smart-select {
          .item-content {
            .item-inner {
              .item-after {
                width: 69%;
                color: #464646;
                font-size: 14px;
              }
            }
            .item-inner:after {
              height: 0;
            }
          }
        }
        .smart-select.active-state {
          background-color: transparent;
        }
        .item-title {
          color: #464646;
          font-size: 15px;
          text-align: right;
          width: 27%;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-save {
    margin: 20px 0px;

    ul {
      background: var(--f7-button-bg-color);
      li {
        a {
          color: var(--f7-button-text-color);
        }
      }
    }
  }
}
</style>

<template>
  <f7-page no-toolbar no-swipeback name="vip-birthday-gift" class="page-vip-birthday-gift">
    <f7-navbar :title="$t('v1_vbg_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-birthday">
      <f7-row>
        <f7-col width="25" class="text-left">{{ $t("v1_vbg_0002") }}:</f7-col>
        <f7-col width="75">{{ birthdayInfo.UserBirthday }}</f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="25" class="text-left">{{ $t("v1_vbg_0003") }}:</f7-col>
        <f7-col width="75">{{ birthdayInfo.EnableBonusButton ? birthdayStatus.BonusRemark : "--" }}</f7-col>
      </f7-row>
    </f7-block>

    <div class="list inset button-birthday" :class="{ 'disabled-status': !birthdayInfo.EnableBonusButton }">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="birthdaySubmit">{{ $t("v1_vbg_0004") }}</a>
        </li>
      </ul>
    </div>
    <f7-block class="block-remark" v-if="!birthdayInfo.EnableBonusButton">
      <p>{{ birthdayInfo.BonusRemark }}</p>
    </f7-block>
    <f7-block class="block-tip">
      <p class="tip-title">{{ $t("common_0004") }}：</p>
      <p>1.&nbsp;{{ $t("v1_vbg_0005") }}</p>
      <p>2.&nbsp;{{ $t("v1_vbg_0006") }}</p>
      <p>3.&nbsp;{{ $t("v1_vbg_0007") }}</p>
      <p>4.&nbsp;{{ $t("v1_vbg_0008", [$f7.params.name]) }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { getBirthdayStatus, getBirthdayBonus } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      birthdayInfo: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    birthdayStatusEvent() {
      const self = this;
      getBirthdayStatus().then((data) => {
        if (data.Code === "NoError") {
          self.birthdayInfo = data.Data;
        }
      });
    },
    birthdaySubmit() {
      const self = this;
      if (!self.birthdayInfo.EnableBonusButton) return;
      getBirthdayBonus().then((data) => {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"), () => {
          self.birthdayStatusEvent();
        });
      });
    },
  },
  created() {
    this.birthdayStatusEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-vip-birthday-gift {
  .block-birthday {
    margin: 10px 10px;
    .row {
      padding: 12px 0;
      font-size: 15px;
      .col-25 {
        color: #464646;
      }
      .col-75 {
        color: #464646;
      }
    }
  }
  .button-birthday {
    margin: 10px 8px;

    ul {
      background: var(--f7-button-bg-color);
    }
  }
  .button-birthday.disabled-status {
    ul {
      background: #ccc;
    }
  }
  .block-remark {
    margin: 10px 10px;
    padding: 0;
    p {
      color: #fd6666;
      font-size: 16px;
    }
  }
  .block-tip {
    margin: 20px 8px;
    padding: 0;
    p {
      color: #464646;
    }
    .tip-title {
      color: #464646;
    }
  }
}
</style>

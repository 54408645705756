<template>
  <f7-page no-toolbar no-swipeback name="vip-holiday-gift" class="page-vip-holiday-gift">
    <f7-navbar :title="$t('v1_vhg_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-holiday">
      <f7-row>
        <f7-col width="35" class="text-left">{{ $t("v1_vhg_0002") }}:</f7-col>
        <f7-col width="65">{{ festivalInfo.FestivalName }}</f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="35" class="text-left">{{ $t("v1_vhg_0003") }}:</f7-col>
        <f7-col width="65">{{ festivalInfo.NextFestivalName }}</f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="35" class="text-left">{{ $t("v1_vhg_0004") }}:</f7-col>
        <f7-col width="65">{{ festivalInfo.EnableBonusButton ? festivalInfo.BonusRemark : "--" }}</f7-col>
      </f7-row>
    </f7-block>

    <div class="list inset button-holiday" :class="{ 'disabled-status': !festivalInfo.EnableBonusButton }">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="festivalSubmit">{{ $t("v1_vhg_0005") }}</a>
        </li>
      </ul>
    </div>
    <f7-block class="block-remark" v-if="!festivalInfo.EnableBonusButton">
      <p>{{ festivalInfo.BonusRemark }}</p>
    </f7-block>
    <f7-block class="block-tip">
      <p class="tip-title">{{ $t("common_0004") }}：</p>
      <p>1.&nbsp;{{ $t("v1_vhg_0006", [$f7.params.name]) }}</p>
      <p>2.&nbsp;{{ $t("v1_vhg_0007") }}</p>
      <p>3.&nbsp;{{ $t("v1_vhg_0008") }}</p>
      <p>4.&nbsp;{{ $t("v1_vhg_0009", [$f7.params.name]) }}</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { checkFestival, getFestivalBonus } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      festivalInfo: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    checkFestivalEvent() {
      const self = this;
      checkFestival().then((data) => {
        if (data.Code === "NoError") {
          self.festivalInfo = data.Data;
        }
      });
    },
    festivalSubmit() {
      const self = this;
      if (!self.festivalInfo.EnableBonusButton) return;
      const param = {
        data: JSON.stringify({ FestivalID: self.festivalInfo.FestivalID }),
      };
      getFestivalBonus(param).then((data) => {
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"), () => {
          self.checkFestivalEvent();
        });
      });
    },
  },
  created() {
    this.checkFestivalEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-vip-holiday-gift {
  .block-holiday {
    margin: 10px 10px;
    .row {
      padding: 12px 0;
      font-size: 15px;
      .col-35 {
        color: #464646;
      }
      .col-65 {
        color: #464646;
      }
    }
  }
  .button-holiday {
    margin: 10px 8px;

    ul {
      background: var(--f7-button-bg-color);
    }
  }
  .button-holiday.disabled-status {
    ul {
      background: #ccc;
    }
  }
  .block-remark {
    margin: 10px 10px;
    padding: 0;
    p {
      color: #fd6666;
      font-size: 16px;
    }
  }
  .block-tip {
    margin: 20px 8px;
    padding: 0;
    p {
      color: #464646;
    }
    .tip-title {
      color: #464646;
    }
  }
}
</style>

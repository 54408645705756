<template>
  <f7-page no-toolbar class="page-register-agent" name="register">
    <f7-navbar :title="$t('register_agent_0001')" back-link></f7-navbar>
    <form class="list no-hairlines-md" id="form-register">
      <div class="block-title">{{ $t('register_agent_0002') }}</div>
      <ul>
        <li class="item-content item-input" v-show="baseInfo.ShowPromotionCodeOnAgentRegister">
          <div class="item-media">
            <i class="iconfont icon-yaoqingma"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input
                type="text"
                name="promotion-code"
                :placeholder="$t('register_agent_0003')"
                v-model="promotionCode"
              >
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-media">
            <i class="iconfont icon-zhanghu"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <!-- <input type="text" name="user-name" placeholder="账号由字母开头4-15数字或字母组成" @blur="checkUserExist" required> -->
              <input type="text" name="user-name" :placeholder="$t('register_agent_0004')" required>
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-media">
            <i class="iconfont icon-mima"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-pwd" :placeholder="$t('register_agent_0005')" required>
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input">
          <div class="item-media">
            <i class="iconfont icon-mima"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-repwd" :placeholder="$t('register_agent_0006')" required>
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
      </ul>
      <div class="block-title">{{ $t('register_agent_0007') }}</div>
      <ul>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedAgentSureName">
          <div class="item-media">
            <i class="iconfont icon-yonghu"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-surename" :placeholder="$t('register_agent_0008')">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedAgentPhone">
          <div class="item-media">
            <i class="iconfont icon-shouji"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" name="user-phone" :placeholder="$t('register_agent_0009')">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedAgentEmail">
          <div class="item-media">
            <i class="iconfont icon-youxiang"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="email" name="user-email" :placeholder="$t('register_agent_0010')">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedAgentWechat">
          <div class="item-media">
            <i class="iconfont icon-weixin"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-wechat" :placeholder="$t('register_agent_0011')">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedAgentQQ">
          <div class="item-media">
            <i class="iconfont icon-qq"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" name="user-qq" :placeholder="$t('register_agent_0012')">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info item-input-bank" v-if="baseInfo.NeedAgentBankNo">
          <div class="item-media">
            <i class="iconfont icon-yinhangqia"></i>
          </div>
          <div class="item-inner">
            <a class="item-link smart-select smart-select-init bank-select" data-open-in="sheet">
              <select name="user-bankName">
                <option v-for="item in bankList" :key="item[0]" :value="item[0]">{{item[1]}}</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                    <div class="item-after">{{$t('register_agent_0013')}}</div>
                </div>
              </div>
            </a>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedAgentBankNo">
          <div class="item-media">
            <i class="iconfont icon-yinhangqia"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" name="user-bankNum" :placeholder="$t('register_agent_0014')">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.NeedAgentBankNo">
          <div class="item-media">
            <i class="iconfont icon-yinhangqia"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-bankAddres" :placeholder="$t('register_agent_0015')">
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media">
            <i class="iconfont icon-mima"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-securitypwd" :placeholder="$t('register_agent_0016')" required>
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media">
            <i class="iconfont icon-mima"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="password" name="user-reSecuritypwd" :placeholder="$t('register_agent_0017')" required>
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media">
            <i class="iconfont icon-yanzhengma"></i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-verify" :placeholder="$t('register_agent_0018')" required>
              <span class="span-verify">
                <img @click.self="updateVerify" :src="`${verifyUrl}`" alt>
              </span>
            </div>
          </div>
        </li>
      </ul>
      <f7-block strong>
        <f7-block-header class="text-center" v-show="1==0">
          <p>
            <f7-checkbox name="readRule" :checked="read" @change="checkRead"></f7-checkbox> {{ $t('register_agent_0019') }}
            <f7-link popup-open=".popup-general" class="color-blue2">&lt;{{ $t('register_agent_0020') }}&gt;</f7-link>
          </p>
        </f7-block-header>
        <f7-button fill raised large @click="registerNow">{{ $t('register_agent_0021') }}</f7-button>
      </f7-block>
    </form>
    <f7-popup v-show="showRegisterAgentPopup" class="popup-general" :opened="popupOpened" @popup:open="openRegisterAgentPopup" @popup:close="closeRegisterAgentPopup">
      <f7-page>
        <f7-navbar :title="$t('register_agent_0020')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <ul>
            <li>
              {{ $t('register_agent_0022', [$f7.params.name]) }}
            </li>
            <li>{{ $t('register_agent_0023') }}</li>
            <li>
              【
              <span>{{$f7.params.name}}</span>】{{ $t('register_agent_0024') }}
            </li>
            <li>
              {{ $t('register_agent_0025', [$f7.params.name]) }}
            </li>
            <li>
              {{ $t('register_agent_0026', [$f7.params.name]) }}
            </li>
            <li>
              {{ $t('register_agent_0027', [$f7.params.name]) }}
            </li>
          </ul>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import api from "../../config/api";
import { getCookie, setCookie, getStore, setStore } from "../../config/utils";
import { mapGetters, mapMutations } from "vuex";
import { getBlankList,agentRegister } from "../../axios/api";
import common from "../../service/common";
import http from "../../axios/http";

export default {
  components: {},
  props: {},
  data() {
    return {
      read: true,
      uuid: getCookie("u"),
      verifyUrl: ``,
      baseInfo: {},
      userExist: false,
      checkRegisterInterval: null,
      popupOpened: false,
      bankList: [],
      promotionCode: '',
      showRegisterAgentPopup: true,
    };
  },
  watch: {
    getMerchantInfo: function(val) {
      this.baseInfo = val.BaseInfo;
    }
  },
  computed: {
    ...mapGetters(["getMerchantInfo", "getLoginState"])
  },
  methods: {
    ...mapMutations(["SETLOGINSTATE", "SETLOGININFO"]),
    checkRead() {
      this.read = this.read ? false : true;
    },
    updateVerify() {
      const uid = common.uuid();
      setCookie("u", uid, 7);
      this.verifyUrl = `${api.verifyimage}?u=${uid}`;
    },
    focusVerify() {
      const uid = common.uuid();
      setCookie("u", uid, 7);
      this.verifyUrl = `${api.verifyimage}?u=${uid}`;
    },
    async checkUserExist(event) {
      let res = await http.post(api.memberExist, { data: event.target.value });
      this.userExist = res.Data;
      if (res.Code === "NoError" && res.Data) {
        this.$f7.dialog.alert(this.$t('register_agent_0028'), this.$t('common_0004'));
      }
      return res.Data;
    },
    checkInput() {
      const self = this;
      const formData = self.$f7.form.convertToData(`#form-register`);
      const userReg = /^[a-zA-Z][0-9a-zA-Z]{3,14}$/,
        passReg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/,
        nameReg = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/;
        phoneReg = /^\+?[0-9]{1,4}(?:\s?[0-9]{1,4})*$/;
        aqPassReg = /^\d{6}$/,
        wechatReg = /^[a-zA-Z0-9]{1}[-_a-zA-Z0-9]{5,19}$/,
        qqReg = /^[1-9][0-9]{4,10}$/,
        emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (formData[`user-name`]) {
        if (!userReg.test(formData[`user-name`])) {
          self.$f7.dialog.alert(this.$t('register_agent_0029'), this.$t('common_0004'));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t('register_agent_0030'), this.$t('common_0004'));
        return false;
      }

      if (formData[`user-pwd`]) {
        if (!passReg.test(formData[`user-pwd`])) {
          self.$f7.dialog.alert(this.$t('register_agent_0031'), this.$t('common_0004'));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t('register_agent_0032'), this.$t('common_0004'));
        return false;
      }
      if (formData[`user-repwd`]) {
        if (formData[`user-repwd`] !== formData[`user-pwd`]) {
          self.$f7.dialog.alert(this.$t('register_agent_0033'), this.$t('common_0004'));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t('register_agent_0034'), this.$t('common_0004'));
        return false;
      }

      if (self.baseInfo.NeedAgentSureName) {
        if (formData[`user-surename`]) {
          // if (!nameReg.test(formData[`user-surename`])) {
          //   self.$f7.dialog.alert(this.$t('register_agent_0035'), this.$t('common_0004'));
          //   return false;
          // }
        } else {
          self.$f7.dialog.alert(this.$t('register_agent_0036'), this.$t('common_0004'));
          return false;
        }
      }

      if (self.baseInfo.NeedAgentPhone) {
        if (formData[`user-phone`]) {
          if (!phoneReg.test(formData[`user-phone`])) {
            self.$f7.dialog.alert(this.$t('register_agent_0037'), this.$t('common_0004'));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t('register_agent_0009'), this.$t('common_0004'));
          return false;
        }
      }

      if (self.baseInfo.NeedAgentEmail) {
        if (formData[`user-email`]) {
          if (!emailReg.test(formData[`user-email`])) {
            self.$f7.dialog.alert(this.$t('register_agent_0038'), this.$t('common_0004'));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t('register_agent_0039'), this.$t('common_0004'));
          return false;
        }
      }

      if (self.baseInfo.NeedAgentWechat) {
        if (formData[`user-wechat`]) {
          if (!wechatReg.test(formData[`user-wechat`])) {
            self.$f7.dialog.alert(this.$t('register_agent_0040'), this.$t('common_0004'));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t('register_agent_0011'), this.$t('common_0004'));
          return false;
        }
      }

      if (self.baseInfo.NeedAgentQQ) {
        if (formData[`user-qq`]) {
          if (!wechatReg.test(formData[`user-qq`])) {
            self.$f7.dialog.alert(this.$t('register_agent_0041'), this.$t('common_0004'));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t('register_agent_0012'), this.$t('common_0004'));
          return false;
        }
      }
      if (self.baseInfo.NeedAgentBankNo) {
        if (!formData[`user-bankName`]) {
          self.$f7.dialog.alert(this.$t('register_agent_0013'), this.$t('common_0004'));
          return false;
        }
        if (!formData[`user-bankNum`]) {
          self.$f7.dialog.alert(this.$t('register_agent_0014'), this.$t('common_0004'));
          return false;
        }
        if (formData[`user-bankAddres`]) {
          if (!nameReg.test(formData[`user-bankAddres`])) {
            self.$f7.dialog.alert(this.$t('register_agent_0042'), this.$t('common_0004'));
            return false;
          }
        } else {
          self.$f7.dialog.alert(this.$t('register_agent_0015'), this.$t('common_0004'));
          return false;
        }
      }

      if (formData[`user-securitypwd`]) {
        if (!aqPassReg.test(formData[`user-securitypwd`])) {
          self.$f7.dialog.alert(this.$t('register_agent_0043'), this.$t('common_0004'));
          return false;
        }
      } else {
        self.$f7.dialog.alert(this.$t('register_agent_0044'), this.$t('common_0004'));
        return false;
      }
      if (
        !formData[`user-reSecuritypwd`] ||
        formData[`user-reSecuritypwd`] !== formData[`user-securitypwd`]
      ) {
        self.$f7.dialog.alert(this.$t('register_agent_0045'), this.$t('common_0004'));
        return false;
      }

      if (!formData[`user-verify`]) {
        self.$f7.dialog.alert(this.$t('login_0006'), this.$t('common_0004'));
        return false;
      }
      if (!self.read) {
        self.$f7.dialog.alert(this.$t('register_agent_0046'), this.$t('common_0004'));
        return false;
      }

      return true;
    },
    registerNow() {
      const self = this;
      const formData = self.$f7.form.convertToData("#form-register");
      let registerRequest = false;
      if (self.checkInput() && !registerRequest) {
        registerRequest = true;
        const param = {
          UserName: formData[`user-name`],
          Password: formData[`user-pwd`],
          SecurePassword: formData[`user-securitypwd`],
          SureName: formData[`user-surename`],
          BankAccountNo: formData[`user-bankNum`],
          BankAddress: formData[`user-bankAddres`],
          BankName: formData[`user-bankName`],
          Email: formData[`user-email`],
          QQ: formData[`user-qq`],
          Wechat: formData[`user-wechat`],
          VerifyCode: formData[`user-verify`],
          Mobile: formData[`user-phone`],
          PromoCode: formData[`promotion-code`],
        };
        const d = { data: JSON.stringify(param) };
        agentRegister(d).then(data => {
          registerRequest = false;
          if (data.Code === `NoError`) {
            self.$f7.dialog.alert(this.$t('register_agent_0047'), this.$t('common_0004'), () => {
              self.$f7.form.removeFormData('#form-register');
            });
          } else {
            self.$f7.dialog.alert(data.Data, this.$t('common_0004'), () => {
              self.updateVerify();
            });
          }
        });
      }
    },
    getBankList(){
      const self = this;
      getBlankList().then(data => {
        if(data.Code === 'NoError') {
          self.bankList = data.Data;
        }
      });
    },
    openRegisterAgentPopup() {
      this.showRegisterAgentPopup = true;
    },
    closeRegisterAgentPopup() {
      this.popupOpened = false;
      this.showRegisterAgentPopup = false;
    },
  },
  created() {
    const self = this;
    const d = self.$f7route.query.d || getStore('d');
    if(d) setStore('d', d);
    self.promotionCode = getStore('d');
    self.verifyUrl = `${api.verifyimage}?u=${getCookie(`u`)}`;
    self.baseInfo = self.getMerchantInfo.BaseInfo
      ? self.getMerchantInfo.BaseInfo
      : ``;
  },
  mounted() {
    this.getBankList();
  }
};
</script>

<style lang="less" scoped>
.page-register-agent {
  .list {
      margin: var(--f7-margin-size-10) 0;

      .block-title {
          background-image: linear-gradient(to right, #e0dddd, #fff);
          width: 91%;
          height: var(--f7-height-size-30);
          line-height: var(--f7-height-size-30);
          background-size: cover;
          color: #3793d4;
          margin-left: var(--f7-size-ps-12);
          margin-bottom: var(--f7-margin-size-0);
          margin-top: var(--f7-margin-size-15);
          padding-left: var(--f7-px-5);
          font-size: var(--f7-font-size-14);
          border-radius: 5px;
      }

      ul {
          background-color: var(--f7-color-bg-transparent);

          .item-content {
              margin-bottom: var(--f7-margin-size-10);
              padding-left: var(--f7-px-20);

              input {
                  font-size: var(--f7-font-size-14);
                  color: #464646;
              }

              .input-clear-button {
                  right: var(--f7-size-ps-12);
              }

              .iconfont {
                  color: #464646;
              }

              .span-verify {
                  position: var(--f7-postion-absolute);
                  right: var(--f7-px-10);
                  top: var(--f7-px-4);
                  z-index: 2;
              }

              .smart-select.bank-select {
                  width: 100%;

                  .item-content {
                      margin-bottom: 0;
                      padding-left: 0;

                      .item-inner {
                          .item-after {
                              line-height: 40px;
                              margin-left: 0;
                              font-size: 14px;
                              padding-left: 0;
                          }
                      }

                      .item-inner:after {
                          height: 0;
                      }
                  }
              }
              
              .smart-select.active-state {
                background-color: transparent;
              }
          }

          .item-input-bank {
              .item-inner {
                  padding: 0 15px 0 2px;
              }
          }
      }

      ul:before,
      ul:after {
          height: 0;
      }
  }

  .list .item-inner:after {
      width: var(--f7-width-size-pt92);
  }

  .list li:last-child>.item-inner:after {
      display: var(--f7-display-block) !important;
  }

  .block-strong {
      padding: var(--f7-padding-size-10) var(--f7-padding-size-20, --f7-block-padding-horizontal);
      margin: var(--f7-margin-size-0) auto;

      .block-header {
          margin-bottom: var(--f7-margin-size-15);
          color: #fff;
      }

      .block-footer {
          margin-top: var(--f7-margin-size-15);
          color: #fff;

          a.link {
              border-bottom: 1px solid #0e8ad0;
          }
      }
  }

  .block-strong:before,
  .block-strong:after {
      height: var(--f7-height-size-0);
  }
}
.popup-general {
  ul {
    padding: var(--f7-padding-size-0);
    li {
      list-style: var(--f7-border-none);
      padding-bottom: var(--f7-padding-size-10);
      margin-bottom: var(--f7-margin-size-10);
      border-bottom: 1px dashed #666;
      font-size: var(--f7-px-16);
      color: var(--f7-color-gray-1);
    }
    li:last-child {
      padding-bottom: 0px;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}
</style>
<template>
  <f7-page no-toolbar no-swipeback name="financial" class="page-center" >
    <f7-navbar innerClass="navbar-inner">
      <f7-nav-left>
        <f7-link @click="$f7router.back()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("myPosition_0002") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div ><img :src="`../../../static/images/yt999/job_details/${lang}.png`" alt="" srcset="" width="100%" style="display: block;"></div>
  </f7-page>
</template>

<script>
import { getCookie } from "@/config/utils";
import { getMsgSystemList } from "../../../axios/api";
import { get } from "http";

export default {
  components: {},
  props: {},
  data() {
    return {
      lang:''
    };
  },
  watch: {},
  computed: {},
  methods: {
    
  },
  created() { 
    this.lang = getCookie('lang');
    if(this.lang=='' || this.lang==null  || this.lang==undefined){
      this.lang = 'enUS';
    }
  },
  mounted() { 
    
  },
};
</script>
<style lang="less" scoped>
::v-deep .navbar-inner{    //头部颜色更换
  color: white;
  background: linear-gradient(to right, #357ae2, #1f64cd);
  a{
    color: white;
  }
}
::v-deep .page-content::after{
  height: 0px;
}
</style>
<template>
  <f7-page no-toolbar class="page-apply-activity" name="apply-activity">
    <f7-navbar :title="$t('apply_activity_0001')" back-link></f7-navbar>
    <form class="list inline-labels no-hairlines-md" id="form-apply">
      <ul>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label text-right">{{ $t('apply_activity_0002') }}：</div>
            <div class="item-input-wrap item-input-name">
              <input type="text" disabled readonly v-model="applyDetals.Name">
            </div>
          </div>
        </li>
        <li v-if="hasData && applyDetals.BonusSetting.length > 0">
          <a class="item-link smart-select" data-open-in="sheet">
            <select name="pormotion-name" v-model="formParam.promotionName.key">
              <option
                v-for="item in applyDetals.BonusSetting"
                :key="item.BonusSettingID"
                :value="item.BonusSettingID"
              >{{item.BonusTitle}}{{item.BonusDescription ? '('+item.BonusDescription+')' : ''}}</option>
            </select>
            <div class="item-content">
              <div class="item-inner">
                <div class="item-title text-right">{{ $t('apply_activity_0003') }}：</div>
                <div class="item-after">{{formParam.promotionName.value}}</div>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <ul>
        <div v-for="item in applyDetals.InputSetting" :key="item.InputSettingID">
          <li class="item-content item-input" v-if="item.InputType==='TextBox'">
            <div class="item-inner">
              <div class="item-title item-label text-right">{{item.InputTitle}}：</div>
              <div class="item-input-wrap">
                <input
                  type="text"
                  :placeholder="`$t('apply_activity_0004')${item.InputTitle}`"
                  v-model="formParam[item.InputSettingID].value"
                >
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
          <li v-if="item.InputType==='RadioButton' && item.OptionValues">
            <a class="item-link smart-select" data-open-in="sheet">
              <select name="pormotion-name" v-model="formParam[item.InputSettingID].value">
                <option
                  v-for="options in item.OptionValues.split('|')"
                  :key="options"
                  :value="options"
                >{{options}}</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title text-right">{{item.InputTitle}}：</div>
                  <div class="item-after">{{formParam[item.InputSettingID].value}}</div>
                </div>
              </div>
            </a>
          </li>
          <li></li>
          <li class="item-content item-input" v-if="item.InputType==='CheckBox'">
            <div class="item-inner">
              <div class="item-title item-label text-right">{{item.InputTitle}}：</div>
              <div class="item-input-wrap">
                <label class="toggle toggle-init color-blue">
                  <input type="checkbox" v-model="formParam[item.InputSettingID].value">
                  <span class="toggle-icon"></span>
                </label>
              </div>
            </div>
          </li>
          <li v-if="item.InputType==='DropDownList' && item.OptionValues">
            <a class="item-link smart-select" data-open-in="sheet">
              <select name="pormotion-name" v-model="formParam[item.InputSettingID].value">
                <option
                  v-for="options in item.OptionValues.split('|')"
                  :key="options"
                  :value="options"
                >{{options}}</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title text-right">{{item.InputTitle}}：</div>
                  <div class="item-after">{{formParam[item.InputSettingID].value}}</div>
                </div>
              </div>
            </a>
          </li>
          <li
            class="item-content item-input"
            v-if="item.InputType==='SelectedButton' && item.OptionValues"
          >
            <div class="item-inner">
              <div class="item-title item-label text-right">{{item.InputTitle}}：</div>
              <div class="item-input-wrap">
                <span
                  class="checkbox-item"
                  v-for="(options, index) in item.OptionValues.split('|')"
                  :key="options"
                >
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      :checked="formParam[item.InputSettingID].checked[index].c"
                      @change="checkRead(index,formParam[item.InputSettingID], item.InputSettingID)"
                    >
                    <i class="icon-checkbox"></i>
                  </label>
                  {{options}}
                </span>
              </div>
            </div>
          </li>
          <li class="item-content item-input" v-if="item.InputType === 'BigTextBox'">
            <div class="item-inner">
              <div class="item-title item-label text-right">{{item.InputTitle}}：</div>
              <div class="item-input-wrap">
                <textarea name="bio" class="resizable" :placeholder="$t('apply_activity_0004')+item.InputTitle" v-model="formParam[item.InputSettingID].value"></textarea>
              </div>
            </div>
          </li>
        </div>
      </ul>
      <f7-block strong>
        <f7-button fill raised large @click="applyNow">{{ $t('apply_activity_0005') }}</f7-button>
      </f7-block>
    </form>
    <div class="activity-detail" v-show="applyDetals.ContentMobile">
      <f7-block v-html="applyDetals.ContentMobile"></f7-block>
    </div>
  </f7-page>
</template>

<script>
import { getPromotionDetail, postPromotionDetail } from "../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      hasData: false,
      applyDetals: {},
      formParam: {
        promotionName: {
          key: "",
          value: ""
        }
      },
      contentMobile:""
    };
  },
  watch: {},
  computed: {},
  methods: {
    applyDetails() {
      const self = this;
      const id = self.$f7route.params.Id;
      const params = { data: id };
      getPromotionDetail(params).then(data => {
        if (data.Code === "NoError") {
          self.hasData = true;
          self.initParams(data.Data);
          self.applyDetals = data.Data;
        }
      });
    },
    initParams(data) {
      const self = this;
      if (data.AutoSendBonusWhenConfirm) {
        const defaultD = data.BonusSetting[0];
        self.formParam.promotionName.key = defaultD.BonusSettingID.toString();
        self.formParam.promotionName.value =
          defaultD.BonusTitle +
          (defaultD.BonusDescription ? `(${defaultD.BonusDescription})` : ``);
      }
      if (data.InputSetting.length) {
        data.InputSetting.forEach(function(item, index) {
          let val =
            item.InputType === "CheckBox"
              ? false
              : item.InputType === "DropDownList"
              ? item.OptionValues.split("|")[0]
              : item.InputType === "RadioButton"
              ? item.OptionValues.split("|")[0]
              : item.DefaultValue;
          self.formParam[item.InputSettingID] = {
            key: item.InputTitle,
            value: val,
            inputCheck: item.InputCheck
          };
          // 多选 checkbox 的情况特殊处理
          if (item.InputType === "SelectedButton") {
            let servieArr = [];
            item.OptionValues.split("|").forEach(function(d, i) {
              servieArr.push({ id: d, c: false });
            });
            self.formParam[item.InputSettingID]["checked"] = servieArr;
          }
        });
      }
    },
    joinService(data) {
      let joinArr = [];
      data.forEach(function(item, index) {
        if (item.c) {
          joinArr.push(item.id);
        }
      });
      return joinArr.join("|");
    },
    applyNow() {
      const self = this;
      var param = {
        PromotionID: self.applyDetals.Id,
        BonusSettingID: self.formParam.promotionName.key
          ? self.formParam.promotionName.key
          : "",
        InputList: {}
      };
      let checkInput = true;
      self.applyDetals.InputSetting.forEach(function(item, index) {
        if (checkInput && item.InputCheck) {
          if ( ((item.InputType === "TextBox" || item.InputType === "BigTextBox") && 
          !self.formParam[item.InputSettingID].value) || (item.InputType === "SelectedButton" && 
          !self.joinService(self.formParam[item.InputSettingID].checked))) {
            self.$f7.dialog.alert( (item.InputType === "TextBox" ? this.$t('apply_activity_0004') : this.$t('common_0013')) + item.InputTitle, this.$t('common_0004'));
            checkInput = false;
          }
        }
        param.InputList[item.InputTitle] =
          item.InputType === "SelectedButton"
            ? self.joinService(self.formParam[item.InputSettingID].checked)
            : item.InputType === "CheckBox"
            ? self.formParam[item.InputSettingID].value
              ? this.$t('common_0014')
              : this.$t('common_0015')
            : self.formParam[item.InputSettingID].value;
      });
      if (!checkInput) return false;
      const d = {data: JSON.stringify(param) };
      postPromotionDetail(d).then(data => {
        if(data.Code === 'NoError') {
          self.$f7.dialog.alert(this.$t('apply_activity_0006'),this.$t('common_0004'), () => {
            self.$f7router.back();
          });
        } else {
          self.$f7.dialog.alert(data.Data, this.$t('common_0004'));
        }
      });

    },
    checkRead(index, data, key) {
      if (data.checked[index].c) {
        this.formParam[key].checked[index].c = false;
      } else {
        this.formParam[key].checked[index].c = true;
      }
    }
  },
  created() {
    this.applyDetails();
  },
  mounted() {},
  updated() {}
};
</script>
<style lang="less" scoped>
.page-apply-activity {
  .activity-detail{
    width:100%;
    .show-detail{
      img{
        width:100%;
      }
    }
    .block{
      width: 100%;
      margin:0;
      padding:0;
      margin-top:10px;
      img{
        width:100%;
      }
    }
  }
  .list {
    margin: var(--f7-margin-size-0);
    ul {
      margin: var(--f7-margin-size-10) var(--f7-px-5);
      border: 1px solid #c0c2c5;
      border-radius: 3px;
      padding: 6px 0;
      background-color: #fff;
      a.item-link.active-state {
        background-color: var(--f7-color-bg-transparent);
      }
      .smart-select {
        .item-inner {
          color: #464646;
          .item-title {
            width: 37%;
          }
          .item-after {
            font-size: 14px;
            margin-left: 0;
            width: 60%;
          }
        }

        .item-inner:after {
          height: 0;
        }
      }
      li.item-content {
        min-height: var(--f7-px-49);
        .item-inner {
          color: var(--f7-color-white);
          .item-label {
            padding-top: var(--f7-padding-size-10);
            color: #464646;
          }
          .item-input-wrap {
            input,
            select {
              font-size: 14px;
            }
            .checkbox-item {
              font-size: 14px;
              margin-right: 2px;
              i.icon-checkbox {
                width: 18px;
                height: 18px;
                top: -2px;
              }
              i.icon-checkbox:after {
                width: 18px;
                height: 18px;
                top: -2px;
              }
            }
          }
          .item-input-name {
            input {
              font-size: 16px;
              color: #0b54c1;
            }
          }
        }
        .item-inner:after {
          height: 0;
        }
      }
    }
    ul:before,
    ul:after {
      height: 0;
    }
    .block-strong {
      padding: var(--f7-padding-size-10)
        var(--f7-padding-size-10, --f7-block-padding-horizontal);
      margin: var(--f7-margin-size-0) auto;
    }
    .block-strong:before,
    .block-strong:after {
      height: var(--f7-height-size-0);
    }
  }
}
</style>
<template>
  <f7-page
    no-toolbar
    no-swipeback
    name="activity-records"
    class="page-activity-records"
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar :title="$t('activity_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-records" v-if="fundsRecordsList.length>0">
      <div class="card card-outline" v-for="item in fundsRecordsList" :key="item.Id">
        <div class="card-header">
          <div class="row no-gap">
            <div class="col-25 text-right">
              <i class="f7-icons">equal_square</i>
              <span class="co-gray">{{ $t('activity_records_0002') }}:</span>
            </div>
            <div class="col-75 co-white text-right">{{item.TicketNo}}</div>
          </div>
        </div>
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('activity_records_0003') }}:</div>
            <div
              class="col-75"
              :class="item.Amount<0?'co-yellow':'co-blue'"
            >{{item.Amount| formatCurrency}}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('activity_records_0004') }}:</div>
            <div class="col-75 co-green">{{item.Balance| formatCurrency}}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('activity_records_0005') }}:</div>
            <div class="col-75 co-gray">{{item.Remark?item.Remark:'--'}}</div>
          </div>
        </div>
        <div class="card-footer co-white">{{item.UpdateTs.replace('T',' ')}}</div>
      </div>
    </f7-block>
    <f7-card v-else-if="fundsRecordsList.length<=0&&!showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" >
    </f7-card>
  </f7-page>
</template>

<script>
import { getFundsRecordsList } from "../../../axios/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      fundsRecordsList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    fundsRecordsListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = Math.round(
        new Date(queryParam.BeginDate.replace(/-/g,'/')).getTime() / 1000
      );
      queryParam.EndDate = Math.round(
        new Date(queryParam.EndDate.replace(/-/g,'/')).getTime() / 1000
      );
      queryParam.TicketNo = "";
      queryParam.Category = 0;
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 20;

      const params = { data: JSON.stringify(queryParam) };
      getFundsRecordsList(params).then(data => {
        if (data.Code === "NoError") {
          self.fundsRecordsList = self.fundsRecordsList.concat(data.Data[0]);
          self.showData = self.fundsRecordsList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.fundsRecordsList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.fundsRecordsList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.fundsRecordsListEvent();
        self.allowInfinite = true;
      }, 1000);
    }
  },
  created() {
    this.fundsRecordsListEvent();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-activity-records {
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #d2d2d2;
      background-color: #f9f9f9;
      margin: 8px;
      .co-white {
        color: #464646;
      }
      .co-gray {
        color: #8e8e91;
      }
      .co-green {
        color: #17903a;
      }
      .co-blue {
        color: #028cb3;
      }
      .co-yellow {
        color: #706404;
      }
      .co-red {
        color: #c50d0d;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #1c3b93;
      }
      .card-header {
        display: block;
        font-size: 15px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -3px;
          color: #1e56bb;
        }
      }
      .card-content {
        font-size: 15px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }
  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
<template>
  <div class="loader">
    <div class="loader-inner line-scale">
      <div v-for="item in list" :key="item"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["list"],
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>
<style lang="less" scoped>
@-webkit-keyframes line-scale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
@keyframes line-scale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
.loader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  position: relative;
}
.line-scale > div:nth-child(1) {
  -webkit-animation: line-scale 0.6s 0.1s infinite cubic-bezier(0, 0, 0.25, 1);
  animation: line-scale 0.6s 0.1s infinite cubic-bezier(0, 0, 0.25, 1);
}
.line-scale > div:nth-child(2) {
  -webkit-animation: line-scale 0.8s 0.2s infinite cubic-bezier(0, 0, 0.25, 1);
  animation: line-scale 0.8s 0.2s infinite cubic-bezier(0, 0, 0.25, 1);
}
.line-scale > div:nth-child(3) {
  -webkit-animation: line-scale 0.8s 0.3s infinite cubic-bezier(0, 0, 0.25, 1);
  animation: line-scale 0.8s 0.3s infinite cubic-bezier(0, 0, 0.25, 1);
}
.line-scale > div:nth-child(4) {
  -webkit-animation: line-scale 0.8s 0.4s infinite cubic-bezier(0, 0, 0.25, 1);
  animation: line-scale 0.8s 0.4s infinite cubic-bezier(0, 0, 0.25, 1);
}
.line-scale > div:nth-child(5) {
  -webkit-animation: line-scale 0.8s 0.8s infinite cubic-bezier(0, 0, 0.25, 1);
  animation: line-scale 0.8s 0.8s infinite cubic-bezier(0, 0, 0.25, 1);
}
.line-scale > div {
  background-color: #dcca2d;
  width: 2px;
  height: 14px;
  border-radius: 2px;
  margin: -2px 1px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}
</style>
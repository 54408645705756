import Vue from "vue";
import VueI18n from "vue-i18n";
import { getCookie, setCookie, setStore, getStore } from "../config/utils";

Vue.use(VueI18n);

export const LANGLIST = {
  enUS: { key: "en", code: "enUS", name: "English", currency: "USD", local: require("./enUS.json") },
  thTH: { key: "th", code: "thTH", name: "ไทย", currency: "USD", local: require("./thTH.json") },
  viVN: { key: "vi", code: "viVN", name: "Tiếng Việt", currency: "USD", local: require("./viVN.json") },
  idID: { key: "id", code: "idID", name: "Indonesia", currency: "USD", hide: true, local: require("./idID.json") },
  msMY: { key: "ms", code: "msMY", name: "B.Melayu", currency: "USD", hide: true, local: require("./msMY.json") },
  myMY: { key: "my", code: "myMY", name: "မြန်မာ", currency: "USD", hide: true, local: require("./myMY.json") },
  ptPT: { key: "pt", code: "ptPT", name: "Português", currency: "USD", hide: false, local: require("./ptPT.json") },
  zhTW: { key: "zh", code: "zhTW", name: "繁體中文", currency: "USD", hide: true, local: require("./zhTW.json") },
  zhCN: { key: "zh", code: "zhCN", name: "简体中文", currency: "USD", local: require("./zhCN.json") },
  jaJP: { key: "ja", code: "jaJP", name: "日本語.", currency: "USD", local: require("./jaJP.json") },
  koKR: { key: "ko", code: "koKR", name: "한국어", currency: "USD", local: require("./koKR.json") },
};

const DEFALUT_ITEM = null; // Object.values(LANGLIST).find((item) => !item.hide && navigator.languages.includes(item.key));
//const DEFAULT_LANG = DEFALUT_ITEM ? DEFALUT_ITEM.code : "enUS";
const DEFAULT_LANG = "idID";
const LOCALE_KEY = "lang";

const locales = Object.values(LANGLIST).reduce((curr, prev) => {
  curr[prev.code] = prev.local;
  return curr;
}, {});

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
});

export const setup = (lang) => {
  if (lang === undefined) {
    lang = getCookie(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  setCookie(LOCALE_KEY, lang, 7);

  Vue.config.lang = lang;
  i18n.locale = lang;
};
setup();
export default i18n;

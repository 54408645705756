<template>
  <f7-page no-toolbar no-swipeback class="page-access" name="access">
    <f7-block class="block-access">
      <f7-card>
        <f7-card-header class="text-center">{{ $t('limited_access_0001') }}</f7-card-header>
        <f7-card-content>
          <f7-block>
            <p>
              <strong>{{ $t('limited_access_0002') }}:</strong>
            </p>
            <p>{{ $t('limited_access_0003') }}</p>
            <p>{{ $t('limited_access_0004') }}</p>
            <p>
              <span class="color-blue ng-binding">{{domainLimit}}</span>
            </p>
          </f7-block>
          <f7-block>
            <p>
              <strong>Dear Valued Customers:</strong>
            </p>
            <p>
              Viewing and using this website at your current location is prohibited due to its regulatory
              rules.
            </p>
            <p>We regret any inconvenience.</p>

            <p>
              From
              <span class="color-blue ng-binding">{{domainLimit}}</span>
            </p>
          </f7-block>
        </f7-card-content>
      </f7-card>
    </f7-block>
  </f7-page>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      domainLimit: window.location.host,
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-access {
  .block-access {
    margin: 20% 15px 0;
    border: solid 3px #eee;
    border-radius: 8px;
    padding: 3px;
    .card {
      margin: 0;
      background-color: #f5f5ff;
      border-radius: 6px;
      padding: 10px 5px;
      overflow: hidden;
      .card-header {
        font-size: 30px;
        color: red;
        font-weight: bold;
        border-bottom: 2px solid #000;
        padding: 0px 0 10px;
        justify-content: center;
      }
      .card-content {
        padding: 0;
        .block {
          padding: 10px;
          background-color: #fff;
          border-radius: 5px;
          border: solid 1px #cde;
          margin: 5px 0;
        }
        p {
          margin: 5px 0;
          color: #000;
        }
      }
    }
  }
}
</style>
<template>
  <f7-page no-toolbar no-swipeback hide-toolbar-on-scroll name="transfer" class="page-transfer">
    <f7-navbar :title="$t('transfer_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-wallet-list">
      <div class="card card-select-wallte card-bg-bright" @click="clickSelectWallte" v-if="selectWalletEntity.ProviderId==-1">
        <div class="card-content card-content-padding text-center">{{ $t('transfer_0002') }}</div>
      </div>
      <div class="card card-selected-wallet" :class="`card-bg-${selectWalletEntity.SupportGameType.length > 1 ? 'bright': 'dark'}`" v-else>
        <div class="card-header">
          <div class="wallet-name">&lt;{{selectWalletEntity.Name}}&gt;</div>
          <div class="wallet-select">
            <f7-button @click="popupOpened=true">{{ $t('transfer_0003') }}</f7-button>
          </div>
        </div>
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-50 col-balance">
              <span v-show="!selectedWalletLoading">{{ userInfo.currencySymbol }}{{selectWalletEntity.Balance| formatCurrency}}</span>
              <preloader v-show="selectedWalletLoading" :list="4"></preloader>
            </div>
            <div class="col-50 col-btns text-right">
              <!-- <f7-button raised fill class="btn-refresh" @click="refreshSelectedWallet()">刷新</f7-button> -->
              <f7-row class="btn-group">
                <f7-col>
                  <f7-button raised fill class="btn-transfer" @click="mainWalletToGameEvent(selectWalletEntity, true)">{{ $t('common_balance_transfer_in') }}</f7-button>
                </f7-col>
                <f7-col>
                  <f7-button raised fill class="btn-transferOut" @click="gameWalletToMainEvent(selectWalletEntity, true)">{{ $t('common_balance_transfer_out') }}</f7-button>
                </f7-col>
                <f7-col>
                  <f7-button raised fill class="btn-refresh" @click="refreshSelectedWallet">{{ $t('transfer_0004') }}</f7-button>
                </f7-col>
              </f7-row>
            </div>
          </div>
          <div class="row no-gap row-parent-names">
            <div class="col wallet-parent-names">{{selectWalletEntity.ParentNames}}</div>
          </div>
        </div>
      </div>
    </f7-block>
    <f7-block class="block-transfer-type">
      <f7-row no-gap class="trnasfer-type">
        <f7-col width="30" class="text-center text-16 color-black">{{ $t('transfer_0005') }}</f7-col>
        <f7-col width="70" class="text-16 color-transferType">
          <span v-if="transferFrom === 0">{{ $t('common_transfer_from_format', [selectWalletEntity.Name || '--']) }}</span>
          <span v-if="transferFrom === 1">{{ $t('common_transfer_to_format', [selectWalletEntity.Name || '--']) }}</span>
        </f7-col>
      </f7-row>
    </f7-block>
    <div class="block-transfer-from block">
      <div class="trnasfer-from row no-gap">
        <div
          class="text-center text-16 col"
          :class="{'active':transferFrom === 0}"
          @click="toggleTransferForm(0)"
        >{{ $t('transfer_0006') }}</div>
        <div
          class="text-center text-16 col"
          :class="{'active':transferFrom === 1}"
          @click="toggleTransferForm(1)"
        >{{ $t('transfer_0007') }}</div>
      </div>
    </div>
    <div class="list inline-labels list-transfer">
      <ul>
        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">
                 <i class="iconfont icon-jinbi"></i>
              </div>
              <div class="item-input-wrap">
                <input
                  type="text"
                  class="input-walletBlance"
                  readonly
                  disabled
                  :value="walletBalance| formatCurrency"
                >
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">
                <i class="iconfont icon-jinbi"></i>
              </div>
              <div class="item-input-wrap">
                <input type="number" :placeholder="$t('deposit_0026') + transferFrom ? $t('transfer_0010') : $t('transfer_0009')" v-model="quotaPrice">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <f7-block class="block-buttons">
      <f7-row tag="p">
        <f7-col tag="span">
          <f7-button large raised class="button-submit" @click="transferSubmit">{{ $t('transfer_0012') }}</f7-button>
        </f7-col>
        <f7-col tag="span">
          <f7-button large raised fill class="button-recycling" @click="oneClickRecycling">{{ $t('common_one_key_transfer_game_balance_back') }}</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-popup v-show="showTransferPopup" class="transfer-popup" :opened="popupOpened" @popup:open="openTransferPopup" @popup:close="closeTransferPopup">
      <f7-page hide-toolbar-on-scroll>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close><i class="f7-icons">chevron_left</i></f7-link>
          </f7-nav-left>
          <f7-nav-title>
            <f7-link>{{ $t('transfer_0013') }}</f7-link>
          </f7-nav-title>
          <f7-nav-right>
            <f7-button @click="refreshBalance">{{ $t('transfer_0004') }}</f7-button>
          </f7-nav-right>
          <f7-subnavbar>
            <f7-segmented raised>
              <f7-button tab-link="#tab-transfer-all" tab-link-active>{{ $t('common_0005') }}</f7-button>
              <f7-button tab-link="#tab-transfer-casino">{{ $t('common_0007') }}</f7-button>
              <f7-button tab-link="#tab-transfer-egame">{{ $t('common_0006') }}</f7-button>
              <f7-button tab-link="#tab-transfer-sports">{{ $t('common_0010') }}</f7-button>
              <f7-button tab-link="#tab-transfer-lottery">{{ $t('common_0011') }}</f7-button>
              <f7-button tab-link="#tab-transfer-card">{{ $t('common_0008') }}</f7-button>
              <f7-button tab-link="#tab-transfer-fish">{{ $t('common_0009') }}</f7-button>
            </f7-segmented>
          </f7-subnavbar>
        </f7-navbar>
        <f7-toolbar bottom>
          <span>{{ $t('transfer_0014') }}：<span class="mainwallet-balance">{{ userInfo.currencySymbol }}{{walletBalance| formatCurrency}}</span></span>
        </f7-toolbar>
        <f7-tabs>
          <f7-tab id="tab-transfer-all" tab-active class="page-content">
            <f7-block>
              <div class="card" v-for="item in gameWalletType.All" :key="`all-${item.ProviderId}`" :class="`card-${item.ProviderId} card-bg-${item.SupportGameType.length > 1 ? 'bright':'dark'}`">
                <div class="card-header">
                  <div class="wallet-name">&lt;{{item.Name}}&gt;</div>
                  <div class="wallet-select">
                    <f7-button :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="selectedWallet(item)">{{ $t('transfer_0015') }}</f7-button>
                  </div>
                </div>
                <div class="card-content card-content-padding">
                  <div class="row no-gap">
                    <div class="col-50 col-balance">
                      <div class="wallet-detail">
                        <span v-if="item.IsMaintain" class="is-maintain">{{ $t('common_under_maintenance') }}</span>
                        <span v-else-if="!item.QuerySuccess" class="is-na">N/A</span>
                        <span v-else class="query-success">{{ userInfo.currencySymbol }}{{item.Balance| formatCurrency}}</span>
                      </div>
                      <div class="wallet-preload">
                        <preloader :list="4"></preloader>
                      </div>
                    </div>
                    <div class="col-50 col-btns text-right">
                      <f7-row class="btn-group">
                        <f7-col>
                          <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGameEvent(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMainEvent(item)">{{ $t('common_balance_transfer_out') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-refresh" @click="refreshSingleWallet(item)">{{ $t('transfer_0004') }}</f7-button>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                  <div class="row no-gap row-parent-names">
                    <div class="col wallet-parent-names">{{item.ParentNames}}</div>
                  </div>
                </div>
              </div>
            </f7-block>
          </f7-tab>
          <f7-tab id="tab-transfer-casino" class="page-content">
            <f7-block>
              <div class="card" v-for="item in gameWalletType.Casino" :key="`all-${item.ProviderId}`" :class="`card-${item.ProviderId} card-bg-${item.SupportGameType.length > 1 ? 'bright':'dark'}`">
                <div class="card-header">
                  <div class="wallet-name">&lt;{{item.Name}}&gt;</div>
                  <div class="wallet-select">
                    <f7-button :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="selectedWallet(item)">{{ $t('transfer_0015') }}</f7-button>
                  </div>
                </div>
                <div class="card-content card-content-padding">
                  <div class="row no-gap">
                    <div class="col-50 col-balance">
                      <div class="wallet-detail">
                        <span v-if="item.IsMaintain" class="is-maintain">{{ $t('common_under_maintenance') }}</span>
                        <span v-else-if="!item.QuerySuccess" class="is-na">N/A</span>
                        <span v-else class="query-success">{{ userInfo.currencySymbol }}{{item.Balance| formatCurrency}}</span>
                      </div>
                      <div class="wallet-preload">
                        <preloader :list="4"></preloader>
                      </div>
                    </div>
                    <div class="col-50 col-btns text-right">
                      <f7-row class="btn-group">
                        <f7-col>
                          <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGameEvent(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMainEvent(item)">{{ $t('common_balance_transfer_out') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-refresh" @click="refreshSingleWallet(item)">{{ $t('transfer_0004') }}</f7-button>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                  <div class="row no-gap row-parent-names">
                    <div class="col wallet-parent-names">{{item.ParentNames}}</div>
                  </div>
                </div>
              </div>
            </f7-block>
          </f7-tab>
          <f7-tab id="tab-transfer-egame" class="page-content">
            <f7-block>
              <div class="card" v-for="item in gameWalletType.Egame" :key="`all-${item.ProviderId}`" :class="`card-${item.ProviderId} card-bg-${item.SupportGameType.length > 1 ? 'bright':'dark'}`">
                <div class="card-header">
                  <div class="wallet-name">&lt;{{item.Name}}&gt;</div>
                  <div class="wallet-select">
                    <f7-button :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="selectedWallet(item)">{{ $t('transfer_0015') }}</f7-button>
                  </div>
                </div>
                <div class="card-content card-content-padding">
                  <div class="row no-gap">
                    <div class="col-50 col-balance">
                      <div class="wallet-detail">
                        <span v-if="item.IsMaintain" class="is-maintain">{{ $t('common_under_maintenance') }}</span>
                        <span v-else-if="!item.QuerySuccess" class="is-na">N/A</span>
                        <span v-else class="query-success">{{ userInfo.currencySymbol }}{{item.Balance| formatCurrency}}</span>
                      </div>
                      <div class="wallet-preload">
                        <preloader :list="4"></preloader>
                      </div>
                    </div>
                    <div class="col-50 col-btns text-right">
                      <f7-row class="btn-group">
                        <f7-col>
                          <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGameEvent(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMainEvent(item)">{{ $t('common_balance_transfer_out') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-refresh" @click="refreshSingleWallet(item)">{{ $t('transfer_0004') }}</f7-button>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                  <div class="row no-gap row-parent-names">
                    <div class="col wallet-parent-names">{{item.ParentNames}}</div>
                  </div>
                </div>
              </div>
            </f7-block>
          </f7-tab>
          <f7-tab id="tab-transfer-sports" class="page-content">
            <f7-block>
              <div class="card" v-for="item in gameWalletType.Sports" :key="`all-${item.ProviderId}`" :class="`card-${item.ProviderId} card-bg-${item.SupportGameType.length > 1 ? 'bright':'dark'}`">
                <div class="card-header">
                  <div class="wallet-name">&lt;{{item.Name}}&gt;</div>
                  <div class="wallet-select">
                    <f7-button :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="selectedWallet(item)">{{ $t('transfer_0015') }}</f7-button>
                  </div>
                </div>
                <div class="card-content card-content-padding">
                  <div class="row no-gap">
                    <div class="col-50 col-balance">
                      <div class="wallet-detail">
                        <span v-if="item.IsMaintain" class="is-maintain">{{ $t('common_under_maintenance') }}</span>
                        <span v-else-if="!item.QuerySuccess" class="is-na">N/A</span>
                        <span v-else class="query-success">{{ userInfo.currencySymbol }}{{item.Balance| formatCurrency}}</span>
                      </div>
                      <div class="wallet-preload">
                        <preloader :list="4"></preloader>
                      </div>
                    </div>
                    <div class="col-50 col-btns text-right">
                      <f7-row class="btn-group">
                        <f7-col>
                          <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGameEvent(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMainEvent(item)">{{ $t('common_balance_transfer_out') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-refresh" @click="refreshSingleWallet(item)">{{ $t('transfer_0004') }}</f7-button>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                  <div class="row no-gap row-parent-names">
                    <div class="col wallet-parent-names">{{item.ParentNames}}</div>
                  </div>
                </div>
              </div>
            </f7-block>
          </f7-tab>
          <f7-tab id="tab-transfer-lottery" class="page-content">
            <f7-block>
              <div class="card" v-for="item in gameWalletType.Lottery" :key="`all-${item.ProviderId}`" :class="`card-${item.ProviderId} card-bg-${item.SupportGameType.length > 1 ? 'bright':'dark'}`">
                <div class="card-header">
                  <div class="wallet-name">&lt;{{item.Name}}&gt;</div>
                  <div class="wallet-select">
                    <f7-button :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="selectedWallet(item)">{{ $t('transfer_0015') }}</f7-button>
                  </div>
                </div>
                <div class="card-content card-content-padding">
                  <div class="row no-gap">
                    <div class="col-50 col-balance">
                      <div class="wallet-detail">
                        <span v-if="item.IsMaintain" class="is-maintain">{{ $t('common_under_maintenance') }}</span>
                        <span v-else-if="!item.QuerySuccess" class="is-na">N/A</span>
                        <span v-else class="query-success">{{ userInfo.currencySymbol }}{{item.Balance| formatCurrency}}</span>
                      </div>
                      <div class="wallet-preload">
                        <preloader :list="4"></preloader>
                      </div>
                    </div>
                    <div class="col-50 col-btns text-right">
                      <f7-row class="btn-group">
                        <f7-col>
                          <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGameEvent(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-transferOut" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMainEvent(item)">{{ $t('common_balance_transfer_out') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-refresh" @click="refreshSingleWallet(item)">{{ $t('transfer_0004') }}</f7-button>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                  <div class="row no-gap row-parent-names">
                    <div class="col wallet-parent-names">{{item.ParentNames}}</div>
                  </div>
                </div>
              </div>
            </f7-block>
          </f7-tab>
          <f7-tab id="tab-transfer-card" class="page-content">
            <f7-block>
              <div class="card" v-for="item in gameWalletType.Card" :key="`all-${item.ProviderId}`" :class="`card-${item.ProviderId} card-bg-${item.SupportGameType.length > 1 ? 'bright':'dark'}`">
                <div class="card-header">
                  <div class="wallet-name">&lt;{{item.Name}}&gt;</div>
                  <div class="wallet-select">
                    <f7-button :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="selectedWallet(item)">{{ $t('transfer_0015') }}</f7-button>
                  </div>
                </div>
                <div class="card-content card-content-padding">
                  <div class="row no-gap">
                    <div class="col-50 col-balance">
                      <div class="wallet-detail">
                        <span v-if="item.IsMaintain" class="is-maintain">{{ $t('common_under_maintenance') }}</span>
                        <span v-else-if="!item.QuerySuccess" class="is-na">N/A</span>
                        <span v-else class="query-success">{{ userInfo.currencySymbol }}{{item.Balance| formatCurrency}}</span>
                      </div>
                      <div class="wallet-preload">
                        <preloader :list="4"></preloader>
                      </div>
                    </div>
                    <div class="col-50 col-btns text-right">
                      <f7-row class="btn-group">
                        <f7-col>
                          <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGameEvent(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMainEvent(item)">{{ $t('common_balance_transfer_out') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-refresh" @click="refreshSingleWallet(item)">{{ $t('transfer_0004') }}</f7-button>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                  <div class="row no-gap row-parent-names">
                    <div class="col wallet-parent-names">{{item.ParentNames}}</div>
                  </div>
                </div>
              </div>
            </f7-block>
          </f7-tab>
          <f7-tab id="tab-transfer-fish" class="page-content">
            <f7-block>
              <div class="card" v-for="item in gameWalletType.Fish" :key="`all-${item.ProviderId}`" :class="`card-${item.ProviderId} card-bg-${item.SupportGameType.length > 1 ? 'bright':'dark'}`">
                <div class="card-header">
                  <div class="wallet-name">&lt;{{item.Name}}&gt;</div>
                  <div class="wallet-select">
                    <f7-button :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="selectedWallet(item)">{{ $t('transfer_0015') }}</f7-button>
                  </div>
                </div>
                <div class="card-content card-content-padding">
                  <div class="row no-gap">
                    <div class="col-50 col-balance">
                      <div class="wallet-detail">
                        <span v-if="item.IsMaintain" class="is-maintain">{{ $t('common_under_maintenance') }}</span>
                        <span v-else-if="!item.QuerySuccess" class="is-na">N/A</span>
                        <span v-else class="query-success">{{ userInfo.currencySymbol }}{{item.Balance| formatCurrency}}</span>
                      </div>
                      <div class="wallet-preload">
                        <preloader :list="4"></preloader>
                      </div>
                    </div>
                    <div class="col-50 col-btns text-right">
                      <f7-row class="btn-group">
                        <f7-col>
                          <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGameEvent(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMainEvent(item)">{{ $t('common_balance_transfer_out') }}</f7-button>
                        </f7-col>
                        <f7-col>
                          <f7-button raised fill class="btn-refresh" @click="refreshSingleWallet(item)">{{ $t('transfer_0004') }}</f7-button>
                        </f7-col>
                      </f7-row>
                    </div>
                  </div>
                  <div class="row no-gap row-parent-names">
                    <div class="col wallet-parent-names">{{item.ParentNames}}</div>
                  </div>
                </div>
              </div>
            </f7-block>
          </f7-tab>
        </f7-tabs>
        <!-- <div class="transfer-footer">
        </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  getMainWallet,
  getBalance,
  getValidProviders,
  recyclingFunds,
  mainWalletToGame,
  gameWalletToMain
} from "../../../axios/api";
import {getCookie} from '../../../config/utils';
import preloader from "../../template/preloader";

export default {
  components: {
    preloader
  },
  props: {},
  data() {
    return {
      walletDataList: [],
      walletBalance: 0,
      selectWalletEntity: {
        ProviderId: -1,
        Name: "",
        ParentNames: '',
        Balance: '',
        SupportGameType: '',
        IsMaintain:false,
        QuerySuccess: true,
      },
      transferFrom: 0,
      quotaPrice: "",
      popupOpened: false,
      gameWalletType: {
        All: {},
        Casino: {},
        Egame: {},
        Sports: {},
        Lottery: {},
        Card: {},
        Fish: {},
      },
      selectedWalletLoading: false,
      showTransferPopup: true,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapMutations(["SETALLBALANCES"]),
    ...mapActions(['getUserInfo']),
    getMainWalletEvent() {
      const self = this;
      getMainWallet().then(data => {
        if(data.Code === 'NoError') {
          self.walletBalance = data.Data.MainWalletBalance;
        }
      })
    },
    getValidProvidersList() {
      const self = this;
      const $$ = self.$f7.$;
      getValidProviders().then(data => {
        if (data.Code === "NoError") {
          self.walletDataList = data.Data;
          self.walletDataList.forEach((item) => {
            self.gameWalletType.All[item.Name] = item;

            if(item.SupportGameType.includes('Casino')) {
              self.gameWalletType.Casino[item.Name] = item;
            } 
            if(item.SupportGameType.includes('Egames')) {
              self.gameWalletType.Egame[item.Name] = item;
            }
            if(item.SupportGameType.includes('Fish')) {
              self.gameWalletType.Fish[item.Name] = item;
            }
            if(item.SupportGameType.includes('SportsBook')) {
              self.gameWalletType.Sports[item.Name] = item;
            }
            if(item.SupportGameType.includes('Lottery')) {
              self.gameWalletType.Lottery[item.Name] = item;
            }
            if(item.SupportGameType.includes('Card')) {
              self.gameWalletType.Card[item.Name] = item;
            }
            self.getRequestBalance(item);
          });
        }
      });
    },
    toggleTransferForm(type) {
      this.transferFrom = type;
    },
    transferEvent() {
      const self = this;
      if (isNaN(self.quotaPrice)) {
        self.$f7.dialog.alert(this.$t('transfer_0016'), this.$t('common_0004'));
        return false;
      }
      if (!self.quotaPrice) {
        self.$f7.dialog.alert(this.$t('transfer_0017'), this.$t('common_0004'));
        return false;
      }
      if (self.walletBlance < self.quotaPrice) {
        self.$f7.dialog.alert(this.$t('transfer_0018'), this.$t('common_0004'));
        return false;
      }
      const param = {
        data: JSON.stringify({
          ProviderId: self.selectWalletEntity.ProviderId,
          Amount: self.quotaPrice
        })
      };
      if (self.transferFrom === 0) {
        let transferLoading = self.$f7.dialog.create({
          title: '',
          text: this.$t('common_transfer_wallet_format', [self.selectWalletEntity.Name]),
          cssClass: "dialog-preloadgame",
        }).open();
        mainWalletToGame(param).then(data => {
          setTimeout(() => {
            transferLoading.close();
            if (data.Code === "NoError") {
              self.getMainWalletEvent();
              self.selectedWalletLoading = true;
              self.getRequestBalance(self.selectWalletEntity, true);
              self.quotaPrice = "";
            }
          }, 1000);
        });
      } else {
        let transferLoading = self.$f7.dialog.create({
          title: '',
          text: this.$t('common_transfer_gamewallet_format', [self.selectWalletEntity.Name]),
          cssClass: "dialog-preloadgame",
        }).open();
        gameWalletToMain(param).then(data => {
          setTimeout(() => {
            transferLoading.close();
            if (data.Code === "NoError") {
              self.getMainWalletEvent();
              self.selectedWalletLoading = true;
              self.getRequestBalance(self.selectWalletEntity, true);
              self.quotaPrice = "";
            }
          }, 1000)
        });
      }
    },
    transferSubmit() {
      const self = this;
      if (self.selectWalletEntity.ProviderId === -1 && !self.selectWalletEntity.Name) {
        self.$f7.dialog.alert(this.$t('transfer_0019'), this.$t('common_0004'));
        return false;
      }
      self.transferEvent();
    },
    oneClickRecycling() {
      const self = this;
      let recyclingLoading = self.$f7.dialog.create({
        title: '',
        text: this.$t('transfer_0020'),
        cssClass: "dialog-preloadgame",
      }).open();
      recyclingFunds().then(data => {
        recyclingLoading.close();
        if(data.Code === 'NoError') {
          self.refreshBalance(true);
        }
      });
    },
    clickSelectWallte(){
      this.popupOpened = true;
    },
    refreshBalance(isSelected){
      const self = this;
      const $$ = self.$f7.$;
      self.walletDataList.forEach((item) => {
        $$(`.card-${item.ProviderId} .wallet-detail`).hide();
        $$(`.card-${item.ProviderId} .wallet-preload`).show();
        if(isSelected){
          self.selectedWalletLoading = true;
        }
        self.getRequestBalance(item, isSelected);
      });
      self.getMainWalletEvent();
    },
    getRequestBalance(item, isSelected) {
      const self = this;
      const $$ = self.$f7.$;
      const loginName = getCookie('LoginName');
      let param = {data: JSON.stringify({ProviderId: item.ProviderId, UserName: loginName})}; 
      getBalance(param).then(data => {
        if(data.Code === 'NoError') {
          const result = data.Data;
          if(isSelected) {
            self.selectWalletEntity.Balance = result.Balance;
            self.selectedWalletLoading = false;
          }
          self.gameWalletType.All[result.Name].Balance = result.Balance;
          $$(`.card-${item.ProviderId} .wallet-detail`).show();
          $$(`.card-${item.ProviderId} .wallet-preload`).hide();
          if(item.SupportGameType.includes('Casino')) {
            self.gameWalletType.Casino[result.Name].Balance = result.Balance;
          } 
          if(item.SupportGameType.includes('Egames')) {
            self.gameWalletType.Egame[result.Name].Balance = result.Balance;
          }
          if(item.SupportGameType.includes('Fish')) {
            self.gameWalletType.Fish[result.Name].Balance = result.Balance;
          }
          if(item.SupportGameType.includes('SportsBook')) {
            self.gameWalletType.Sports[result.Name].Balance = result.Balance;
          }
          if(item.SupportGameType.includes('Lottery')) {
            self.gameWalletType.Lottery[result.Name].Balance = result.Balance;
          }
          if(item.SupportGameType.includes('Card')) {
            self.gameWalletType.Card[result.Name].Balance = result.Balance;
          }
        }
      }).catch((error) => {
        console.log(error)
      });
    },
    selectedWallet(item){
      if(item.IsMaintain || !item.QuerySuccess) return false;
      this.selectWalletEntity = {
        ProviderId: item.ProviderId,
        Name: item.Name,
        ParentNames: item.ParentNames,
        Balance: item.Balance,
        SupportGameType: item.SupportGameType,
        IsMaintain: item.IsMaintain,
        QuerySuccess: item.QuerySuccess,
      }
      this.popupOpened = false;
    },
    mainWalletToGameEvent(item, isSelected) {
      const self = this;
      if (item.IsMaintain || !item.QuerySuccess || self.walletBalance <= 0) return false;
      const params = {
        ProviderId: item.ProviderId,
        Amount: self.walletBalance
      };
      const d = { data: JSON.stringify(params) };
      let transferLoading = self.$f7.dialog.create({
        title: '',
        text: this.$t('common_transfer_sdwallet_format', [item.Name]),
        cssClass: "dialog-preloadgame",
      }).open();
      mainWalletToGame(d).then(data => {
        transferLoading.close();
        if (data.Code === "NoError") {
          const $$ = self.$f7.$;
          $$(`.card-${item.ProviderId} .wallet-detail`).hide();
          $$(`.card-${item.ProviderId} .wallet-preload`).show();
          if(isSelected) self.selectedWalletLoading = true;
          self.getRequestBalance(item, isSelected);
          self.getMainWalletEvent();
        }
      });
    },
    gameWalletToMainEvent(item, isSelected) {
      const self = this;
      if (item.IsMaintain || !item.QuerySuccess || !item.Balance) return false;
      const params = {
        ProviderId: item.ProviderId,
        Amount: item.Balance
      };
      const d = { data: JSON.stringify(params) };
      let transferLoading = self.$f7.dialog.create({
        title: '',
        text: this.$t('common_transfer_gamesdwallet_fromat', [item.Name]),
        cssClass: "dialog-preloadgame",
      }).open();
      gameWalletToMain(d).then(data => {
        transferLoading.close();
        if (data.Code === "NoError") {
          const $$ = self.$f7.$;
          $$(`.card-${item.ProviderId} .wallet-detail`).hide();
          $$(`.card-${item.ProviderId} .wallet-preload`).show();
          if(isSelected) self.selectedWalletLoading = true;
          self.getRequestBalance(item, isSelected);
          self.getMainWalletEvent();
        }
      });
    },
    refreshSingleWallet(item){
      const self = this;
      const $$ = self.$f7.$;
      $$(`.card-${item.ProviderId} .wallet-detail`).hide();
      $$(`.card-${item.ProviderId} .wallet-preload`).show();
      self.getRequestBalance(item);
    },
    refreshSelectedWallet() {
      const self = this;
      if (self.selectWalletEntity.ProviderId === -1) return false;
      self.selectedWalletLoading = true;
      self.getRequestBalance(self.selectWalletEntity, true);
    },
    openTransferPopup() {
      this.showTransferPopup = true;
    },
    closeTransferPopup() {
      this.popupOpened = false;
      this.showTransferPopup = false;
    },
  },
  created() {
    this.getUserInfo();
    this.getMainWalletEvent();
    this.getValidProvidersList();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-transfer {
  --f7-px-80:0px;
  --f7-page-toolbar-bottom-offset:0;
  .block-wallet-list {
    margin: 0px;
    padding: 0px;
    .card-select-wallte {
      background-color: transparent;
      border-radius: 10px;
      color: #000;
      font-size: 16px;
      .card-content-padding {
        padding: 40px 15px;
      }
    }
    .card-selected-wallet {
      background-color: transparent;
      border-radius: 10px;
      color: #fff;
      font-size: 16px;
      .card-header {
          .wallet-name {
              color: #000;
              font-weight: bold;
          }

          .wallet-select {
            .button {
              color: #fff;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background-color: #394158;
            }
          }
      }

      .card-header:after {
          width: 90%;
          left: 5%;
          background-color: #000;
      }

      .card-content-padding {
          padding: 10px 15px;

          >.row {
              padding: 8px 0;

              .col-balance {
                line-height: 24px;
                color: #d1701a;
              }

              .wallet-parent-names {
                  color: #1b1e23;
                  font-size: 14px;
              }
          }

          .col-btns {
              .button {
                height: 24px;
                line-height: 24px;
                min-width:30px;
                color: var(--f7-color-white);
                padding: var(--f7-button-padding-vertical, 0) var(--f7-padding-size-0);
                font-size: var(--f7-size-ps-12);
                border-radius: var(--f7-px-3);
                background-color: transparent;
              }

              
              .btn-refresh {
                background-color: #49743e;
              }
              .btn-transfer {
                background-color: #4d88e5;
              }

              .btn-transferOut {
                background-color: #3968b5;
              }
          }
      }
    }
    
    .card-bg-bright {
      // background: url(../../../static/images/partner/transfer-bg-bright.png) no-repeat center;
      // background-size: 100% 100%;
      background-color: #e0f3ff;
    }
    .card-bg-dark {
      // background: url(../../../static/images/partner/transfer-bg-dark.png) no-repeat center;
      // background-size: 100% 100%;
      background-color: #e2ecff;
    }
  }
  .block-transfer-type {
    margin: 0;
    padding: 12px 15px;
    border-top: 1px solid #25428b;
    border-bottom: 1px solid #25428b;
    .color-transferType {
      color: rgb(3, 169, 244);
    }
  }
  .block-transfer-from {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #25428b;
    .trnasfer-from {
      .col {
        height: 48px;
        line-height: 48px;
        color: #464646;
      }
      .col.active {
        // background: url(../../../static/images/yt999/signInNav.png) no-repeat;
        // background-size: 100% 100%;
        background-color: #d1ebf7;
        color: #000;
      }
    }
  }
  .list-transfer {
    margin: 0;
    ul {
      background-color: transparent;
      .item-input {
        .item-inner {
          .item-title {
            padding-top: 8px;
            color: #464646;
            width: 25%;
          }
          .input-walletBlance {
            color: #0b78ff;
          }
        }
        // .item-inner:after {
        //   height: 0;
        // }
      }
    }
    ul:before,
    ul:after {
      height: 0;
    }
  }
  .block-buttons {
    padding: 0 12px;
    margin: 10px 0 80px;
    .button-submit {
      background: #3875d9;
    }
    .button-recycling {
      border: 1px solid #d6d7d8;
    color: #5a5852;
    background: #d6d7d8;
    }
  }
}
</style>
<template>
  <f7-page
    no-toolbar
    no-swipeback
    name="direct-records"
    class="page-direct-records"
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar :title="$t('referrer_performance_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-records" v-if="recordsList.length>0">
      <div class="card card-outline" v-for="(item,index) in recordsList" :key="index">
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-40 text-right">{{ $t('referrer_performance_records_0002') }}:</div>
            <div class="col-60 co-gray">
              <template>{{item.UserName.split('.')[1]}}</template>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-40 text-right">{{ $t('referrer_performance_records_0003') }}:</div>
            <div class="col-60 co-gray">{{item.ReferrerDownlineCount}}</div>
          </div>
          <div class="row no-gap">
            <div class="col-40 text-right">{{ $t('referrer_performance_records_0004') }}:</div>
            <div
              class="col-60 co-green">{{item.TotalEffectiveBetAmount}}</div>
          </div>
          <div class="row no-gap">
            <div class="col-40 text-right">{{ $t('referrer_performance_records_0005') }}:</div>
            <div class="col-60 co-blue">{{item.TotalReferrerCommissionAmount}}</div>
          </div>
          <!-- <div class="row no-gap">
            <div class="col-40 text-right co-white">操作:</div>
            <div class="col-60 co-blue"><f7-link @click="openDialog(item)">查看详情</f7-link></div>
          </div> -->
        </div>
      </div>
    </f7-block>
    
    <f7-card v-else-if="recordsList.length<=0 && !showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" >
    </f7-card>

    <!-- 查看详情 -->
    <div class="dialog dialog-balance dialog-move" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t('referrer_performance_records_0006') }}
          <f7-link @click="closeDialogBalance">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <f7-block-header class="block-header-totalBalance">
              <f7-row>
                <f7-col width="25">{{ $t('referrer_performance_records_0007') }}</f7-col>
                <f7-col width="75">
                  <span >{{lookname}}</span>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="25">{{ $t('referrer_performance_records_0008') }}</f7-col>
                <f7-col width="75">
                  <span>{{lookfromDate}} - {{looktoDate}}</span>
                </f7-col>
              </f7-row>
            </f7-block-header>
            <div class="block-content">
              <f7-row class="item-balance">
                <f7-col width="33">{{ $t('referrer_performance_records_0009') }}</f7-col>
                <f7-col width="33">{{ $t('referrer_performance_records_0010') }}</f7-col>
                <f7-col width="33">{{ $t('referrer_performance_records_0005') }}</f7-col>
              </f7-row>
              <f7-row class="item-balance" v-for="item in detailList" :key="item.GameType" >
                <f7-col width="33" v-html="$options.filters.GameType(item.GameType)"></f7-col>
                <f7-col width="33">{{item.TotalEffectiveBetAmount}}</f7-col>
                <f7-col width="33">{{item.TotalReferrerCommissionAmount}}</f7-col>
              </f7-row>
            </div>
          </f7-block>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getCookie } from '@/config/utils';
import { referrerDownlineMemberEnterpriseList,referrerdownlinememberenterprisedetaillist } from "../../../axios/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      recordsList: [],
      detailList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,

      lookname: '',
      lookfromDate: '',
      looktoDate: '',
    };
  },
  watch: {},
  computed: {},
  methods: {
    toMyDate(date){
        let myYear = date.getFullYear().toString();
        let myMonth = (date.getMonth()+1)<10 ? '0'+(date.getMonth()+1).toString() : (date.getMonth()+1).toString();
        let myDate = date.getDate()<10 ? '0'+date.getDate().toString() : date.getDate().toString();
        let mydate = myYear + myMonth + myDate;
        mydate = parseInt(mydate);
        return mydate;
    },
    recordsListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = self.toMyDate(new Date(queryParam.BeginDate));
      queryParam.EndDate = self.toMyDate(new Date(queryParam.EndDate));
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 4;

      const params = { data: JSON.stringify(queryParam) };
      referrerDownlineMemberEnterpriseList(params).then(data => {
        if (data.Code === "NoError") {
          self.recordsList = self.recordsList.concat(
            data.Data[0]
          );
          self.showData = self.recordsList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.recordsList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.recordsList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.recordsListEvent();
        self.allowInfinite = true;
      }, 1000);
    },
    openDialog(item) {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true ;    //点击背景关闭
      self.$f7.dialog.open('.dialog-balance',true);

      let queryParam = JSON.parse(self.$f7route.params.Info);
      self.lookfromDate = self.toMyDate(new Date(queryParam.BeginDate));
      self.looktoDate = self.toMyDate(new Date(queryParam.EndDate));
      self.lookname= item.UserName.split('.')[1];
      const param = {
          "ReferrerCommissionCalculateListID":item.ReferrerCommissionCalculateListID,
          "UserNameDownline":self.lookname,
          "BeginDate":self.lookfromDate,
          "EndDate":self.looktoDate,
      }
      const params = { data: JSON.stringify(param) };
      referrerdownlinememberenterprisedetaillist(params).then((data)=>{
        if(data.Code === 'NoError'){
          self.detailList = data.Data;
        }
      });
    },
    closeDialogBalance() {
      this.$f7.dialog.close('.dialog-balance');
    },
    balanceOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    balanceCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
  },
  created() {
    this.recordsListEvent();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-direct-records {
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #bfb6b6;
      background-color: #eee;
      margin: 8px;
      color: #464646;
      .co-white {
        color: #fff;
      }
      .co-gray {
        color: #464646;
      }
      .co-green {
        color: #33cd5f;
      }
      .co-blue {
        color: #11c1f3;
      }
      .co-yellow {
        color: #f7da00;
      }
      .co-red {
        color: red;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #1c3b93;
      }
      .card-header {
        display: block;
        font-size: 12px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -3px;
          color:#b19785;
        }
      }
      .card-content {
        font-size: 12px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }
  
  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
  
}
.item-balance{
  >div{
    text-align: center;
  }
}
</style>
<template>
  <f7-page no-toolbar no-swipeback name="change-login-pwd" class="page-change-login-pwd">
    <f7-navbar :title="$t('member_center_0054')" back-link :no-hariline="true"></f7-navbar>

    <van-tabs @click="onClick">
      <van-tab :title="$t('member_center_0043')">
        <f7-block class="block-change-login-pwd">
          <div class="form">
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("change_login_pwd_0002") }}</div>
                <div class="item-input-wrap">
                  <input type="password" :placeholder="$t('change_login_pwd_0003')" v-model="updateLoginPwdForm.OldPassword" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("change_login_pwd_0004") }}</div>
                <div class="item-input-wrap">
                  <input type="password" :placeholder="this.$t('change_login_pwd_0005')" v-model="updateLoginPwdForm.NewPassword" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("change_login_pwd_0006") }}</div>
                <div class="item-input-wrap">
                  <input type="password" :placeholder="$t('change_login_pwd_0007')" v-model="updateLoginPwdForm.ConfirmPassword" />
                </div>
              </div>
            </div>
          </div>
        </f7-block>
        <div class="list inset button-save">
          <ul>
            <li>
              <a href="#" class="list-button color-black" @click="updateLoginSubmit">{{ $t("change_login_pwd_0008") }}</a>
            </li>
          </ul>
        </div>
      </van-tab>
      <van-tab :title="$t('member_center_0044')">
        <div>
          <changeSecurityPwd @custom-event="handleCustomEvent"></changeSecurityPwd>
        </div>
      </van-tab>
    </van-tabs>
  </f7-page>
</template>
<script>
import { updateLoginPwd } from "../../../axios/api";
import changeSecurityPwd from "../../template/change-security-pwd";
export default {
  components: {
    changeSecurityPwd
  },
  props: {},
  data() {
    return {
      updateLoginPwdForm: {
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onClick(){

    },
    handleCustomEvent(value){
      this.$f7router.navigate(`/member-center/`);
    },
    updateLoginSubmit() {
      var pwdReg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/;
      const self = this;
      if (!self.updateLoginPwdForm.OldPassword) {
        self.$f7.dialog.alert(this.$t("change_login_pwd_0009"), this.$t("common_0004"));
        return false;
      }
      if (!self.updateLoginPwdForm.NewPassword || !pwdReg.test(self.updateLoginPwdForm.NewPassword)) {
        self.$f7.dialog.alert(this.$t("change_login_pwd_0010"), this.$t("common_0004"));
        return false;
      }
      if (self.updateLoginPwdForm.NewPassword != self.updateLoginPwdForm.ConfirmPassword) {
        self.$f7.dialog.alert(this.$t("change_login_pwd_0011"), this.$t("common_0004"));
        return false;
      }
      const d = {
        data: JSON.stringify({
          OldPassword: self.updateLoginPwdForm.OldPassword,
          NewPassword: self.updateLoginPwdForm.NewPassword,
        }),
      };
      updateLoginPwd(d).then((data) => {
        if (data.Code === "NoError") {
          self.$f7.dialog.alert(this.$t("change_login_pwd_0012"), this.$t("common_0004"));
          self.updateLoginPwdForm = {
            OldPassword: "",
            NewPassword: "",
            ConfirmPassword: "",
          };
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
  },
  created() {},
  mounted() {},
  updated() {},
};
</script>
<style lang="less" scoped>
.page-change-login-pwd {
  --f7-page-toolbar-bottom-offset: 0;
  .block-change-login-pwd {
    margin: 5px 0 10px;
    padding: 0 10px;
    .form {
      .form-item {
        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }
          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;
            input {
              width: 100%;
            }
          }
          .smart-select .item-after {
            max-width: unset;
          }
        }
      }
    }
  }
  .button-save {
    margin: 10px 12px;

    ul {
      background: var(--f7-button-bg-color);
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
::v-deep .van-tabs__line{
  height: 2px;
  width: 50%;
  background-color: var(--f7-THEME-color);
}
</style>

<template>
  <f7-page
    no-toolbar
    no-swipeback
    name="daily-task-review"
    class="page-daily-task-review"
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar :title="$t('v1_dtr_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-review" v-if="dailyTaskList.length>0">
      <div class="card card-outline" v-for="item in dailyTaskList" :key="item.ActivityBonusAuditID">
        <div class="card-header">
          <div class="row no-gap">
            <div class="col-25 text-right">
              <i class="f7-icons">equal_square</i>
              <span class="co-gray">{{ $t('v1_dtr_0002') }}:</span>
            </div>
            <div class="col-75 co-white text-right">{{item.ActivityBonusAuditID}}</div>
          </div>
        </div>
        <div class="card-content card-content-padding">
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('v1_dtr_0003') }}:</div>
            <div class="col-75 co-gray">{{item.BonusRemark}}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('v1_dtr_0005') }}:</div>
            <div class="col-75 co-gray">
              <template v-if="item.ActivityType=='SpecialActivity'">{{ $t('v1_dtr_0006') }}</template>
              <template v-if="item.ActivityType=='MonthlySalary'">{{ $t('v1_dtr_0007') }}</template>
              <template v-if="item.ActivityType=='BirthdayBonus'">{{ $t('v1_dtr_0008') }}</template>
              <template v-if="item.ActivityType=='FestivalBonus'">{{ $t('v1_dtr_0009') }}</template>
              <template v-if="item.ActivityType=='VIPLevelUp'">{{ $t('v1_dtr_0010') }}</template>
              <template v-if="item.ActivityType=='BonusPoint'">{{ $t('v1_dtr_0011') }}</template>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('v1_dtr_0012') }}:</div>
            <div class="col-75 co-green">{{item.BonusAmount| formatCurrency}}</div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('v1_dtr_0013') }}:</div>
            <div class="col-75">
              <span class="co-blue" v-if="item.VerifyStatus=='Wait'">{{ $t('v1_dtr_0014') }}</span>
              <span class="co-blue" v-if="item.VerifyStatus=='Locked'">{{ $t('v1_dtr_0014') }}</span>
              <span class="co-green" v-if="item.VerifyStatus=='Accept'">{{ $t('v1_dtr_0016') }}</span>
              <span class="co-yellow" v-if="item.VerifyStatus=='Rejected'">{{ $t('v1_dtr_0017') }}</span>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('v1_dtr_0018') }}:</div>
            <div class="col-75 co-gray">{{item.Remark}}</div>
          </div>
        </div>
        <div class="card-footer co-white">{{item.CreateTS.replace('T',' ')}}</div>
      </div>
    </f7-block>

    <f7-card v-else-if="dailyTaskList.length<=0&&!showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" >
    </f7-card>
  </f7-page>
</template>

<script>
import { getCookie } from '@/config/utils';
import { getDailyTaskReviewList } from "../../../axios/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      dailyTaskList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    dailyTaskListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = Math.round(
        new Date(queryParam.BeginDate.replace(/-/g,'/')).getTime() / 1000
      );
      queryParam.EndDate = Math.round(
        new Date(queryParam.EndDate.replace(/-/g,'/')).getTime() / 1000
      );
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 4;

      const params = { data: JSON.stringify(queryParam) };
      getDailyTaskReviewList(params).then(data => {
        if (data.Code === "NoError") {
          self.dailyTaskList = self.dailyTaskList.concat(data.Data[0]);
          self.showData = self.dailyTaskList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.dailyTaskList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.dailyTaskList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.dailyTaskListEvent();
        self.allowInfinite = true;
      }, 1000);
    }
  },
  created() {
    this.dailyTaskListEvent();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-daily-task-review {
  .block-review {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #d2d2d2;
      background-color: #f9f9f9;
      margin: 8px;
      .co-white {
        color: #464646;
      }
      .co-gray {
        color: #8e8e91;
      }
      .co-green {
        color: #17903a;
      }
      .co-blue {
        color: #028cb3;
      }
      .co-yellow {
        color: #c50d0d;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #1c3b93;
      }
      .card-header {
        display: block;
        font-size: 15px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -2px;
          color: #1e56bb;
        }
      }
      .card-content {
        font-size: 15px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }

  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
<template>
  <f7-page no-toolbar no-swipeback name="invite-friends" class="page-messgae-center" infinite
    :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="$f7router.back()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("commission_detail_0001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="commission">
      <div class="one">
        <p>{{ todayProfitAmount | formatCurrency}}</p>
        <p>{{ $t("commission_detail_0002") }}(R$)</p>
      </div>
      <div class="one ones">
        <p>{{ monthProfitAmount | formatCurrency}}</p>
        <p>{{ $t("commission_detail_0015") }}(R$)</p>
      </div>
      <div class="one">
        <p>{{ totalProfitAmount | formatCurrency}}</p>
        <p>{{ $t("commission_detail_0003") }}(R$)</p>
      </div>
    </div>
    <div class="selectcon">
      <div class="custom-select">
        <select v-model="referrerLevel">
          <option value="0">{{ $t("commission_detail_0004") }}</option>
          <option value="1">{{ $t("commission_detail_0005") }}</option>
          <option value="2">{{ $t("commission_detail_0006") }}</option>
          <option value="3">{{ $t("commission_detail_0007") }}</option>
        </select>
      </div>
      <div class="custom-select">
        <select v-model="referrerRebatesType">
          <option value="None">{{ $t("commission_detail_0008") }}</option>
          <option value="OrdersRebates">{{ $t("commission_detail_0010") }}</option>
          <!-- <option value="FinancialProductRebates">{{ $t("commission_detail_0010") }}</option> -->
          <option value="VIPLevelUpRebates">{{ $t("commission_detail_0009") }}</option>
        </select>
      </div>
    </div>
    <div class="content">
      <div class="info" v-for="item in userNameReferrerrebatesgetList" :key="item.index">
        <div class="fl">
          <div style="width: 50%;">{{ $t("commission_detail_0012") }}</div>
          <div style="width: 50%;">{{ item.UserName.split(".")[1]}}</div>
        </div>
        <div class="fl">
          <div>{{ $t("commission_detail_0013") }}</div>
          <div class="datesty">{{ item.InsertTS | time_local }}</div>
        </div>
        <div class="fl">
          <div style="width:50%">{{ $t("commission_detail_0016") }}</div>
          <div style="width:50%" v-if="item.ReferrerRebatesType=='OrdersRebates'">{{$t("commission_detail_0010")}}</div>
          <div style="width:50%" v-if="item.ReferrerRebatesType=='VIPLevelUpRebates'">{{$t("commission_detail_0009")}}</div>
        </div>
        <div class="fl" style="border: none;">
          <div style="width:50%">{{ $t("commission_detail_0014") }}</div>
          <div style="width:50%"><span class="symbol">{{ currencySymbol }}</span>{{ item.Amount | formatCurrency}}</div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getCookie , getStore} from "@/config/utils";
import { userNameReferrerrebatesget  } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: '',
      readActiveIndex: 0,
      todayProfitAmount: 0,//今日收益
      monthProfitAmount: 0,//月收益
      totalProfitAmount: 0, //总收益
      userNameReferrerrebatesgetList: [],
      referrerLevel: 0,
      dateRangeType: 4 ,// 1:天，2周，3月。4 年
      referrerRebatesType : 'None',

      //分页
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {
    referrerRebatesType(newValue, oldValue) {
      this.userNameReferrerrebatesgetList = [];
      this.getUserNameReferrerrebatesget();
    },
    referrerLevel(newValue, oldValue) {
      this.userNameReferrerrebatesgetList = [];
      this.getUserNameReferrerrebatesget();
    }
  },
  computed: {},
  methods: {
    init(){
      let queryParam = JSON.parse(this.$f7route.params.Info);
      this.todayProfitAmount = queryParam.TodayProfitAmount;
      this.monthProfitAmount = queryParam.MonthProfitAmount;
      this.totalProfitAmount = queryParam.TotalProfitAmount;
    },
    getUserNameReferrerrebatesget(){
      const self = this;
      let queryParam = {};
      queryParam.ReferrerRebatesType = self.referrerRebatesType,
      queryParam.ReferrerLevel = self.referrerLevel;
      queryParam.DateRangeType = self.dateRangeType,  
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 20;
      const params = { data: JSON.stringify(queryParam) };
      userNameReferrerrebatesget(params).then(data => {
        if (data.Code === "NoError") {
          self.userNameReferrerrebatesgetList = self.userNameReferrerrebatesgetList.concat(data.Data[0]);
          self.showData = self.userNameReferrerrebatesgetList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.userNameReferrerrebatesgetList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },

    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      if (!self.financialList) return;
      setTimeout(() => {  
        if (self.financialList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.getUserNameReferrerrebatesget();
        self.allowInfinite = true;
      }, 1000);
    },
    backMemberCenter() {
      this.$f7router.navigate("/member-center/invite-friends/");
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    },
  },
  created() { 
    this.currencySymbol = getStore('currencySymbol');
    this.init();
    this.getUserNameReferrerrebatesget();
  },
  mounted() { },
};
</script>
<style lang="less" scoped>
  .commission{
    position: relative;
    border-radius: 10px;
    width: 90%;
    margin: 10px auto;
    display: flex;
    color: white;
    background: var(--f7-THEME-color);
    text-align: center;
    height: 88px;
    .one{
      width: 50%;
      position: relative;
      p:nth-child(1){
        // font-size: 20px;
        line-height: 66px;
      }
      p{
        margin: 0;
        height: 50px;
        padding: 0 10px;
      }
    }
    .one:nth-child(2):after {
      content: '';
      position: absolute;
      top: 19px;
      right: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 50px;
      background-color: var(--f7-THEME-color2);
    }
    .one:nth-child(2):before {
      content: '';
      position: absolute;
      top: 19px;
      left: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 50px;
      background-color: var(--f7-THEME-color2);
    }
    
  }
  .content{
    width:90%;
    margin:10px auto;
    .info{
      border-radius: 10px;
      border: 1px solid var(--f7-THEME-color2);
      padding: 0 10px;
      margin-bottom: 10px;
      .fl{
        display: flex;
        border-bottom: 1px solid #ededed;
        position: relative;
        div{
          
          height: 40px;
          line-height: 40px;
        }
        div:nth-child(1){
          width: 30%;
        }
        div:nth-child(2){
          width: 70%;
          text-align: right;
        }
        img{
          margin: 6px 10px 0 0;
        }
        // span{
        //   position:absolute;
        //   top: 0;
        //   left: 38px;
        // }
        .datesty{
          font-size: 14px;
          color: #969799;
          font-size: 12px;
        }
      }
    }
  }
  .selectcon{
  background: var(--f7-THEME-color2);
  padding: 10px 86px 0px 20px;
  display: flex;
  justify-content: space-between;
}
.custom-select {
  position: relative;
  display: inline-block;
  width: 72px;;
  height: 40px;
}

.custom-select select {
  width: 140px;
  height: 32px;
  padding: 7px 6px 5px 10px;
  font-size: 12px;
  border: 1px solid var(--f7-THEME-color);
  border-radius: 5px;
  background: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #000;
  outline: none;
}

.custom-select::after {
  content: '▼';
  font-size: 12px;
  color: var(--f7-THEME-color);
  position: absolute;
  right: -56px;
  top: 17px;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-select select:focus {
  border-color: var(--f7-THEME-color);
  /* Darker red border on focus */
}
</style>

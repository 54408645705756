<template>
  <f7-page no-toolbar no-swipeback  @page:init="init" >
    <f7-popup  class="loadding-popup" :opened="loadingPopupOpened" tablet-fullscreen>
      <f7-page>
        <f7-block>
          <div class="loader-ring">
            <div class="loader-ring-light"></div>
            <div class="loader-ring-track"></div>
            <div class="loader-logo">
                <div>{{ $t('deposit_0057') }}</div>
            </div>
          </div>
        </f7-block>
      </f7-page>
    </f7-popup>
   
  </f7-page>
</template>

<script>
import {onlinePayRequest} from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      
    };
  },
  watch: {},
  computed: {
    loadingPopupOpened(){
        return true;
    },
  },
  methods: {
    init() {
      const self = this;
      var payInfo = sessionStorage.getItem('payInfo');
      var param ;
      if(payInfo){
        param = { data: payInfo };
        sessionStorage.removeItem('payInfo');
      }else{
       setTimeout(() => {
          self.$f7.popup.close('.loadding-popup');
          let flag = self.$f7.dialog.alert(this.$t('init_online_payment_0001'), this.$t('common_0004'),() =>{
              window.close();
          });
        }, 1500);
        
      }
      onlinePayRequest(param).then(data => {
        setTimeout(() => {
          self.$f7.popup.close('.loadding-popup');
          if (data.Code === "NoError") {
              const d = data.Data;
              if (d[5] === 2) {
                document.write(d[2]);
              }else if(d[5] === 1)
              {
                window.location.href=d[2];
              }
          }else{
            let flag = self.$f7.dialog.alert(this.$t('init_online_payment_0002'), this.$t('common_0004'),() =>{
              window.close();
            });
          }
        }, 1500);
        
      });
    }
   
  },
  created() {
    
  },
  mounted() {
    
  },
  updated() {
    
  }
};
</script>
<style lang="less" scoped>
.loader-logo{
    position: absolute;
    top: 75%!important;
    left: 47%!important;
    margin: -60px 0 0 -60px;
    color:#464646;
}
</style>
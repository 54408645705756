<template>
  <f7-page no-toolbar no-swipeback name="activity" class="page-messgae-center" @page:init="onPageInit()" infinite
    :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="backMemberCenter">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("finanaicl_center_0001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block class="menu">
      <div style="margin: 0;" :class="{ 'activeMenu': readActiveIndex == 0 }" @click="toggleMessage(0)">{{
        $t("finanaicl_center_0002") }}</div>
      <div :class="{ 'activeMenu': readActiveIndex == 1 }" @click="toggleMessage(1)">{{ $t("finanaicl_center_0003") }}</div>
      <div :class="{ 'activeMenu': readActiveIndex == 3 }" @click="toggleMessage(3)">{{ $t("finanaicl_center_0005") }}</div>
      <div :class="{ 'activeMenu': readActiveIndex == 2 }" @click="toggleMessage(2)">{{ $t("finanaicl_center_0004") }}</div>

    </f7-block>
    <f7-block class="block" v-if="financialList.length > 0" style="margin-top: 73px;">
      <div class="card-message card-user-message card financial" v-for="item in financialList" :key="item.index">
        <div class="financialone">
          <img :src="item.ImgUrl" style="border: 1px solid rgb(204, 204, 204);border-radius: 5px;">
          <span class="nameSpan">{{ item.CnName_FinancialProductName }}</span>
          <span>{{ $t("finanaicl_center_0006") }}:</span>
          <span class="enUs_style_time">{{ item.CreateTS | time_local1 }}</span>
        </div>
        <div class="financialtwo">
          <div>{{ $t("finanaicl_center_0007") }}:</div>
          <div style="text-align: right"><span class="symbol">{{ currencySymbol }}</span>{{ item.OrderAmount | formatCurrency2}}</div>
        </div>
        <div class="financialtwo" v-show="readActiveIndex != 2">
          <div style="width:70%;">{{ $t("finanaicl_center_0008") }}:</div>
          <div style="width:30%;text-align: right;" v-if="item.EnableFixedDailyResponseRate">{{
            item.FixedDailyResponseRate }}%</div>
          <div style="width:30%;text-align: right;" v-else>{{ item.MinDailyResponseRate }}% - {{
            item.MaxDailyResponseRate }}%</div>
        </div>
        <div class="financialtwo" v-show="readActiveIndex != 2">
          <div style="width: 60%;" >{{ $t("activity_0043") }}：</div>
          <div style="text-align: right;width: 40%;">{{ item.InterestCalculationMethod=='SimpleInterest'?$t("activity_0044"):$t("activity_0045") }}</div>
        </div>
        <div class="financialtwo" v-show="readActiveIndex != 2">
          <div>{{ $t("finanaicl_center_0009") }}:</div>
          <div style="text-align: right;"><span class="symbol">{{ currencySymbol }}</span>{{ item.TotalProfit | formatCurrency }}</div>
        </div>
        <div class="financialtwo">
          <div>{{ $t("finanaicl_center_0013") }}:</div>
          <div style="text-align: right;" >{{ item.ReleaseTS | time_release }}</div>
        </div>
        <div class="financialthree" :style="{ height: '43px' }">
          <div class="gobutton noGobutton" v-if="item.IsRelease">{{ $t("finanaicl_center_0011") }}</div>
          <div class="gobutton" v-else>{{ $t("finanaicl_center_0012") }}</div>
        </div>
      </div>
    </f7-block>
    <f7-card v-else-if="financialList.length <= 0 && !showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%"
        :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data">
    </f7-card>
  </f7-page>
</template>


<script>
import { getCookie,getStore } from "@/config/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getMsgSystemList, getUserNewsList, alreadyRead, memberFinancialproductlist, memberFinancialproductlistbymemberPending } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: '',
      readActiveIndex: 0,
      popupMsgOpened: false,
      popupMessageItem: [],
      showMsgPopup: true,
      memberfinancialproductlist: [],
      memberFinancialproductlistbymemberPendinglist: [],

      //分页
      financialList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
      //理财类型
      lowRiskProductsList: [],  //低风险
      highYieldProductsList: [],  //高收益
      charityDonationProductsList: [],    //慈善
      exclusiveFundProductsList: [],       //专属
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    ...mapMutations(["SETFINANCIAL_DETAIL"]),
    onPageInit() {
      // this.$f7.emit("myCustomEvent", "activity");
      this.readActiveIndex = this.$f7route.params.Id;
      this.toggleMessage(this.readActiveIndex);
    },
    getFinancialList() {
      const self = this;
      const params = { "PageNow": self.pageNow, "PageSize": 20 };
      if (self.readActiveIndex == 0) {
        params.FinancialProductType = 'LowRiskProducts';
      } else if (self.readActiveIndex == 1) {
        params.FinancialProductType = 'HighYieldProducts';
      } else if (self.readActiveIndex == 2) {
        params.FinancialProductType = 'CharityDonationProducts';
      } else if (self.readActiveIndex == 3) {
        params.FinancialProductType = 'ExclusiveFundProducts';
      }
      const d = { data: JSON.stringify(params) };
      memberFinancialproductlistbymemberPending(d).then((data) => {
        if (data.Code === "NoError") {
          self.financialList = self.financialList.concat(data.Data[0]);
          self.showData = self.financialList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.financialList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }

      });

    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.financialList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.getFinancialList();
        self.allowInfinite = true;
      }, 2000);
    },

    toggleMessage(index) {
      this.readActiveIndex = index;

      this.infoInit();
      this.getFinancialList()
    },
    infoInit() {
      //数据初始化
      this.financialList = [];
      this.allowInfinite = true;
      this.showPreloader = true;
      this.pageNow = 1;
      this.pageTotal = 0;
      this.showData = true;
      this.$f7.$(".page-messgae-center").scrollTo(0, 0);    //滚动条回顶部
    },
    openCardPopup(item) {
      const self = this;
      self.popupMessageItem = item;
      self.popupMsgOpened = true;
      if (!item.IsRead) {
        alreadyRead({ data: item.Id }).then((data) => {


        });
      }
    },
    backMemberCenter() {
      this.$f7router.navigate("/member-center/");
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    }

  },
  created() {
    this.currencySymbol = getStore('currencySymbol');
  },
  mounted() {

  },
};
</script>
<style lang="less" scoped>
.page-messgae-center {
  --f7-page-toolbar-bottom-offset: 0px;

  .tabs-message {
    margin: auto;
    width: 95%;
  }

  .buttons-message {
    height: 48px;
    width: var(--f7-width-size-pt100);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    box-sizing: var(--f7-border-box);
    align-items: var(--f7-text-center);
    align-content: var(--f7-text-center);
    overflow: var(--f7-hidden);

    // background-color: #0c1939;
    // border-bottom: 1px solid #0534a0;
    a.tab-link {
      width: var(--f7-width-size-pt100);
      height: var(--f7-height-size-pt100);
      box-sizing: var(--f7-border-box);
      display: var(--f7-display-flex);
      justify-content: var(--f7-text-center);
      align-items: var(--f7-text-center);
      flex-direction: var(--f7-flex-direction);
      text-transform: var(--f7-tabbar-link-text-transform);
      font-weight: var(--f7-tabbar-link-font-weight);
      letter-spacing: var(--f7-tabbar-link-letter-spacing);
      overflow: var(--f7-hidden);
      color: #808080;
      font-size: var(--f7-px-14);
    }

    a.tab-link.tab-link-active {
      color: #464646;
      // background: url(../../../static/images/yt999/signInNav.png) no-repeat 0px
      //   0px;
      // background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
      border-bottom: 1px solid;
    }
  }

  .scroll-container::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
    //隐藏水平滚动条
  }

  .menu {
    position: fixed;
    background: #fff;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 0;
    padding-left: 4%;
    width: 96%;
    margin: 0;
    display: flex;
    div {
      // display: inline-table;
      // min-width: 90px;
      // height: 30px;
      // line-height: 30px;
      // border: 1px solid var(--f7-THEME-color);
      // border-radius: 5px;
      // font-weight: bold;
      // text-align: center;
      // margin-left: 3px;
      // padding: 0 5px;
      // overflow: hidden;

      height: 30px;
      border: 1px solid var(--f7-THEME-color);
      border-radius: 5px;
      font-weight: bold;
      margin-left: 3px;
      width: 57px;
      height: 45px;
      font-size: 12px;
      white-space: normal;
      text-align: center;
      padding: 3px 12px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .activeMenu {
      background: var(--f7-THEME-color);
      color: white;
    }
  }

  .tabs-message {
    position: relative;
    overflow: hidden;
    min-height: calc(100% - 52px);
    height: calc(100% - 52px);

    .tab {
      padding: 0;

      .block {
        margin: 0;
        padding: 0;

        .card-message {
          margin: 0 4px 13px 4px;
          padding: 10px;
          background-color: #fff;
          color: #464646;
          border: 1px solid #f3f3f3;

          i.iconfont {
            font-size: 16px;
            color: #1e56bb;
            margin-right: 6px;
          }

          .card-footer {
            justify-content: flex-end;
            color: #464646;
            font-size: 14px;
            min-height: 20px;
            padding: 7px 15px;
          }

          .card-footer:before {
            height: 0;
          }
        }

        .card-user-message {
          .card-content {
            padding: 8px 15px 0;
            font-size: 16px;
          }

          .card-footer {
            //justify-content: flex-start;
            border-top: 1px solid #e3e3e3;
            width: 95%;
            margin: auto;
            margin-top: 28px;
          }
        }

        .card-user-message:first-child {
          // margin-top: 54px;
        }
      }

      .no-data {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 80%;
      }
    }
  }
}

.message-popup {
  .block {
    margin: 10px 0;
    color: #464646;
  }
}

.buttonstyle .one {
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 10px 0 0 10px !important;
}

.buttonstyle .two {
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 0 10px 10px 0 !important;
}

.financial {
  border: 1px solid #e5e5e5;
  padding-top: 10px;
  margin: 10px 0;

  .financialone {
    padding: 0 10px 10px 10px;
    height: 60px;
    border-bottom: 1px solid #e5e5e5;

    img {
      position: absolute;
      height: 60px;
    }

    span {
      padding-left: 70px;
      display: block;
    }

    .nameSpan {
      font-weight: bold;
      color: #000;
      width: 80%;
      display: block;
      padding-left: 70px;
      margin: 0 0 3px 0;
      white-space: nowrap;
      /* 禁止换行 */
      overflow: hidden;
      /* 超出部分隐藏 */
      text-overflow: ellipsis;
      /* 超出部分显示省略号 */
    }
  }

  .financialtwo {
    padding: 5px 10px;
    display: flex;
    border-bottom: 1px solid #e5e5e5;

    div {
      width: 50%;
    }
  }

  .financialthree {
    position: relative;

    .gobutton {
      background: var(--f7-THEME-color);
      border-radius: 15px;
      color: #fff;
      text-align: center;
      height: 30px;
      min-width: 80px;
      line-height: 30px;
      position: absolute;
      right: 7px;
      bottom: 5px;
      padding: 1px 10px;
      font-size: 13px;
      
    }

    .noGobutton {
      background: #ccc;
    }

    .circulation {
      display: flex;
      font-size: 12px;

      div {
        width: 50%;
      }

      div:nth-child(1) {
        text-align: left;
      }

      div:nth-child(2) {
        text-align: right;
      }
    }
  }

}

.financial-have {
  // height: 220px;
  height: 195px;

  .financialthree {
    width: 62%;
    margin-left: 15px;
    text-align: right;
    margin: 40px 10px 0px 0px;
    position: relative;

    div {
      margin-top: 6px;
    }

    .gobutton {
      background: var(--f7-THEME-color);
      border-radius: 15px;
      color: #fff;
      text-align: center;
      height: 33px;
      width: 130px;
      line-height: 33px;
      position: absolute;
      right: 0;
    }
  }
}

.no-data {
  background-color: transparent;
  box-shadow: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 50%;
}


// 设置无限滚动距离顶部高度
::v-deep .infinite-scroll-preloader {
  margin-top: 70px;
}

</style>

<template>
  <f7-page no-toolbar no-swipeback name="referrer-search" class="page-referrer">
    <f7-navbar :title="$t('referrer_0001')" back-link :no-hariline="true"></f7-navbar>
    <div class="top">
      <div class="info">
        <div class="">
          <span>{{ $t('referrer_0002') }}：</span>
          <span v-if="info.ReferrerDownlineCount && info.ReferrerDownlineIncrementCount || (info.ReferrerDownlineCount==0 || info.ReferrerDownlineIncrementCount==0)">{{info.ReferrerDownlineCount}}({{info.ReferrerDownlineIncrementCount}})</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
          <span class="refer" @click="getreferrerinfo()">{{ $t('referrer_0017') }}</span>
        </div>
        <div>
          <span>{{ $t('referrer_0003') }}：</span>
          <span v-if="info.TodayDownlineTotalPerformance || info.TodayDownlineTotalPerformance==0">{{info.TodayDownlineTotalPerformance| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div class="">
          <span>{{ $t('referrer_0004') }}：</span>
          <span v-if="info.ReferrerDownlineCount && info.ReferrerDownlineIncrementCount || (info.ReferrerDownlineCount==0 || info.ReferrerDownlineIncrementCount==0)">{{info.ReferrerDownlineCount_L2}}({{info.ReferrerDownlineIncrementCount_L2}})</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div>
          <span>{{ $t('referrer_0005') }}：</span>
          <span v-if="info.TodayDownlineTotalPerformance || info.TodayDownlineTotalPerformance==0">{{info.TodayDownlineTotalPerformance_L2| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
        <div>
          <span>{{ $t('referrer_0006') }}：</span>
          <span v-if="estimatedCommissionToday || estimatedCommissionToday==0">{{estimatedCommissionToday| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
          <span class="refer" @click="getcommisionforecast()">{{ $t('referrer_0017') }}</span>
        </div>
        <div>
          <span>{{ $t('referrer_0007') }}：</span>
          <span v-if="info.YestodayCommision || info.YestodayCommision==0">{{info.YestodayCommision_L1| formatCurrency}}+{{info.YestodayCommision_L2| formatCurrency}}</span>
          <preloader :list="4" v-else style="float:left;margin:0;"></preloader>
        </div>
      </div>
      <div class="line"></div>
      <div class="share">
        <span class="sharedesc">{{ $t('referrer_0008') }}</span>
        <span class="sharebutton" @click="$f7router.navigate('/member-center/referrer-share/')">{{ $t('referrer_0009') }}</span>
      </div>
      <div class="share">
        <span class="sharedesc">{{link}}</span>
      </div>
      <div class="copy">
        <span class="copybutton" v-clipboard:copy="link" v-clipboard:success="onCopy" v-clipboard:error="onError">{{ $t('referrer_0010') }}</span>
      </div>
    </div>
    <div class="middle row">
      <div class="col-50 wallet rightafter">
        <div class="text-center">
          <span v-if="info.HistoryTotalReferrerCommission || info.HistoryTotalReferrerCommission==0">{{info.HistoryTotalReferrerCommission| formatCurrency}}</span>
          <preloader :list="4" v-else style="width:16px;margin:0 auto;"></preloader>
        </div>
        <div class="text-center">{{ $t('referrer_0011') }}</div>
        <div class="text-center rebtn" @click="$f7router.navigate('/member-center/referrer-receive-search/')">{{ $t('referrer_0012') }}</div>
      </div>
      <div class="col-50 wallet">
        <div class="text-center">
          <span v-if="info.PendingReferrerCommission || info.PendingReferrerCommission==0">{{info.PendingReferrerCommission| formatCurrency}}</span>
          <preloader :list="4" v-else style="width:16px;margin:0 auto;"></preloader>
        </div>
        <div class="text-center">{{ $t('referrer_0013') }}</div>
        <div class="text-center rebtn" @click="getreferreramounttomainwallet()">{{ $t('referrer_0014') }}</div>
      </div>
    </div>
    <div class="bottom row">
      <div class="col-50" @click="$f7router.navigate('/member-center/referrer-directReport-search/')">
        <div class="text-center"><img src="../../../static/images/yt999/referrer/ico1.png" alt="" width="30px"></div>
        <div class="text-center s-title">{{ $t('referrer_0015') }}</div>
      </div>
      <div class="col-50" @click="$f7router.navigate('/member-center/referrer-performance-search/')">
        <div class="text-center"><img src="../../../static/images/yt999/referrer/ico2.png" alt="" width="30px"></div>
        <div class="text-center s-title">{{ $t('referrer_0016') }}</div>
      </div>
      <!-- <div class="col-33">
        <div class="text-center"><img src="../../../static/images/yt999/referrer/ico3.png" alt="" width="30px"></div>
        <div class="text-center s-title">Referral Illustrate</div>
      </div> -->
    </div>  
  </f7-page>
</template>

<script>

import {referrerinfo,commisionforecast,referreramounttomainwallet} from "../../../axios/api";
import preloader from "../../template/preloader";
import { getCookie } from "../../../config/utils";

export default {
  components: {preloader},
  props: {},
  data() {
    return {
      info: {
        

      },
      estimatedCommissionToday: 0,
      link: '',
    };
  },
  watch: {},
  computed: {

  },
  methods: {
    getreferrerinfo() {
      const self = this;
      this.link=window.location.protocol+'//'+ window.location.host + '/#/register/' + '?r='+getCookie("LoginName");
      self.info = {
        ReferrerDownlineCount: null,
        ReferrerDownlineIncrementCount: null,
        TodayDownlineTotalPerformance: null,
        YestodayCommision: null,
        HistoryTotalReferrerCommission: null,
        PendingReferrerCommission: null
      }
      referrerinfo().then((data)=>{
        if(data.Code === 'NoError'){
          setTimeout(()=>{
            self.info = data.Data;
          },1000);
        }
      });
    },
    onCopy(e) {
      const self = this;
      if(e.text) {
        let copyLoading = self.$f7.dialog.create({
          title: '',
          text: this.$t('common_0003'),
          cssClass: "dialog-preloadgame",
        }).open();

        setTimeout(() => {
          copyLoading.close();
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    getcommisionforecast() {
      const self = this;
      self.estimatedCommissionToday = null;
      commisionforecast().then((data)=>{
        if(data.Code === 'NoError'){
          setTimeout(()=>{
            self.estimatedCommissionToday = data.Data;
          },1000);
        }
      });
    },
    getreferreramounttomainwallet() {
      const self = this;
      if(self.info.PendingReferrerCommission === 0){
        self.$f7.dialog.alert(this.$t('referrer_0018'), this.$t('common_0004'));
        return false;
      }
      referreramounttomainwallet().then((data)=>{
        if(data.Code === 'NoError'){
          self.$f7.dialog.alert(this.$t('referrer_0019'), this.$t('common_0004'));
          self.getreferrerinfo();
        }else{
          self.$f7.dialog.alert(this.$t('referrer_0020'), this.$t('common_0004'));
        }
      });
    },
  },
  created() {
    this.getreferrerinfo();
    this.getcommisionforecast();
  },
  mounted() {

  }
};
</script>
<style lang="less" scoped>
.page-referrer {
  color: #b1b9ce;
  .top{
    background: #cfcfd0;
    border: 1px solid #bfb6b6;
    margin: 10px;
    font-size: 12px;
    .info{
      width: 90%;
      margin: auto;
      div{
        background: #464646;
        border-radius: 5px;
        height: 28px;
        line-height: 28px;
        margin-top: 15px;
        position: relative;
        span:nth-child(1){
          float: left;
          margin-left: 10px;
          color: #e0e0e0;
        }
        span:nth-child(2){
          color: #d9b961;
        }
        .refer{
          background: linear-gradient(#decabc, #b19785);
          color: #000;
          height: 26px;
          border-radius: 4px;
          line-height: 28px;
          width: 50px;
          display: inline-block;
          text-align: center;
          position: absolute;
          right: 1px;
          top: 1px;
        }
      }
    }
    .line{
      width: 95%;
      margin: auto;
      margin-top: 15px;
      border-top: 1px solid #1b336e;
    }
    .share{
      width: 90%;
      background: #2d2d2d;
      color: #b1b9ce;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      margin: 15px auto;
      position: relative;
      padding-left: 10px;
      .sharedesc{
        width: 96%;
        color: #96e8ec;
        white-space:nowrap;
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
      }
      .sharebutton{
        width: 80px;
        height: 28px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        color: #000;
        background: linear-gradient(#decabc, #b19785);
        border-radius: 15px;
      }
    }
    .copy{
      width: 90%;
      //background: #2d2d2d;
      color: #b1b9ce;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      margin: 15px auto;
      position: relative;
      padding-left: 10px;
      .sharedesc{
        color: #9cfaff;
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
      }
      .copybutton{
        width: 100%;
        height: 28px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        color: #000;
        background: linear-gradient(#decabc, #b19785);
        border-radius: 15px;
        border: #000 solid 1px;
      }
    }
  }
  .middle{
    border-top: 1px solid #bfbfbf;
    .wallet{
      background: url('../../../static/images/yt999/referrer/icon-up.png') bottom  no-repeat,url('../../../static/images/yt999/referrer/highlight.png') bottom #efefef no-repeat;
      background-size: 100%;
      padding-top: 15px;
      width: 50%;
      position: relative;
      >div{
        margin-bottom: 15px!important;
      }
      color: #101010;
      .rebtn{
        background: linear-gradient(#decabc, #b19785);
        border-radius: 4px;
        width: 82%;
        height: 30px;
        line-height: 30px;
        color: #000;
        margin: auto;
      }
      
    }
    .rightafter::after{
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      width: 2px;
      height: 128px;
      background: url('../../../static/images/yt999/navafter.png') bottom  no-repeat;
      background-size: 100% 100%;
    }
  }
  
  .bottom{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    color: #101010;
    .s-title{
      font-size: 12px;
    }
  }
}
</style>
<template>
  <f7-page no-toolbar no-swipeback hide-toolbar-on-scroll name="transfer" class="page-transfer">
    <f7-navbar  :no-hariline="true" >
      <f7-nav-left>
        <f7-link @click="$f7router.navigate('/member-center/')" class="icon-only">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('safe_box_0001') }}</f7-nav-title>
      <f7-nav-right class="navright">
        <f7-link @click="$f7router.navigate('/member-center/safe-records/')">
          {{ $t('safe_box_0002') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block class="block-wallet-list">
      <div class="card card-select-wallte card-bg-bright">
        <div class="card-content card-content-padding text-center">
            <f7-col width="40" class="text-center text-16 color-white">{{ $t('safe_box_0003') }}</f7-col>
            <f7-col width="60" class="text-center text-16 color-white">
              <span v-if="safetyboxbalance || safetyboxbalance==0">{{safetyboxbalance| formatCurrency}}</span>
              <preloader :list="4" v-else style="width:16px;margin:0 auto;"></preloader>
            </f7-col>
        </div>
      </div>
    </f7-block>
    <f7-block class="block-transfer-type">
      <f7-row no-gap class="trnasfer-type">
        <f7-col width="30" class="text-center text-16 color-white">{{ $t('safe_box_0004') }}</f7-col>
        <f7-col width="70" class="text-16 color-transferType">
          <span v-if="transferFrom === 0">&lt;{{ $t('safe_box_0005') }}&gt;{{ $t('safe_box_0006') }}&lt;{{ $t('safe_box_0001') }}&gt;</span>
          <span v-if="transferFrom === 1">&lt;{{ $t('safe_box_0001') }}&gt;{{ $t('safe_box_0006') }}&lt;{{ $t('safe_box_0005') }}&gt;</span>
        </f7-col>
      </f7-row>
    </f7-block>
    <div class="block-transfer-from block">
      <div class="trnasfer-from row no-gap">
        <div
          class="text-center text-16 col"
          :class="{'active':transferFrom === 0}"
          @click="toggleTransferForm(0)"
        >{{ $t('safe_box_0007') }}</div>
        <div
          class="text-center text-16 col"
          :class="{'active':transferFrom === 1}"
          @click="toggleTransferForm(1)"
        >{{ $t('safe_box_0008') }}</div>
      </div>
    </div>
    <div class="list inline-labels list-transfer">
      <ul>
        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">{{ $t('safe_box_0005') }}:</div>
              <div class="item-input-wrap">
                <input v-if="walletbalance || walletbalance== 0"
                  type="text"
                  class="input-walletBlance"
                  readonly
                  disabled
                  :value="walletbalance| formatCurrency"
                >
                <preloader :list="4" v-else></preloader>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">
                <span>{{ $t('safe_box_0009') }}:</span>
              </div>
              <div class="item-input-wrap">
                <input type="number" :placeholder="$t('safe_box_0010')" v-model="enterPrice">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <f7-block class="block-buttons">
      <f7-row tag="p">
        <f7-col tag="span">
          <f7-button large raised class="button-submit" @click="confrim(transferFrom+1)">{{ $t('safe_box_0011') }}</f7-button>
        </f7-col>
        <f7-col tag="span">
          <f7-button large raised fill class="button-recycling" @click="update()">{{ $t('safe_box_0012') }}</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {getCookie} from '../../../config/utils';
import preloader from "../../template/preloader";
import {safetyboxbalance,safetyboxtransfer,safetyboxtransferhistorymemeberlist,getMemberBalance} from "../../../axios/api";

export default {
  components: {
    preloader
  },
  props: {},
  data() {
    return {
      transferFrom: 0,
      walletbalance: 0,
      safetyboxbalance: 0,
      enterPrice: '',
     
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["getsecurityCode"])
  },
  methods: {
    toggleTransferForm(type) {
      this.transferFrom = type;
    },
    //确认转入转出
    confrim (type){
      const self = this;
      if(!self.enterPrice){
          self.$f7.dialog.alert(this.$t('safe_box_0013'), this.$t('common_0004'));
          return false;
      }
      if(self.enterPrice == 0 ){
          self.$f7.dialog.alert(this.$t('safe_box_0014'), this.$t('common_0004'));
          return false;
      }else{
          if(type == 1){
              if(self.enterPrice > self.walletbalance){
                  self.$f7.dialog.alert(this.$t('safe_box_0015'), this.$t('common_0004'));
                  return false;
              }
          }else{
              if(self.enterPrice > self.safetyboxbalance){
                  self.$f7.dialog.alert(this.$t('safe_box_0016'), this.$t('common_0004'));
                  return false;
              }
          }
      }
      if(!parseFloat(self.enterPrice)){
          return ;
      }
      const param = {
        data: JSON.stringify({
          "Amount": parseFloat(self.enterPrice),
          "Type": type,
          "SecurePassword": self.getsecurityCode?self.getsecurityCode:''
        })
      }
      safetyboxtransfer(param).then(data => {
          if(data.Code === 'NoError'){
              self.$f7.dialog.alert(this.$t('safe_box_0017'), this.$t('common_0004'));
          }else{
              self.$f7.dialog.alert(this.$t('safe_box_0018'), this.$t('common_0004'));
          }
          self.update();
      });
    },

    //刷新金额
    update() {
      const self = this;
      self.getSafetyboxbalance();
      self.getMemberBalance();
    },

    //获取保险箱余额
    getSafetyboxbalance (){
        const self = this;
        self.safetyboxbalance = null;
        safetyboxbalance().then(data => {
            if(data.Code === 'NoError'){
              setTimeout(()=>{
                self.safetyboxbalance = data.Data;
              },1500);
            }
        });
    },
     //钱包余额
    getMemberBalance (){
        const self = this;
        self.walletbalance = null;
        getMemberBalance().then(data => {
            if (data.Code == "NoError") {
              setTimeout(()=>{
                self.walletbalance = data.Data;
              },1500);
              
            }
        });
    }
  },
  created() {
    this.update();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-transfer {
  --f7-px-80:0px;
  --f7-page-toolbar-bottom-offset:0;
  .block-wallet-list {
    margin: 0px;
    padding: 0px;
    .card-select-wallte {
      background-color: transparent;
      border-radius: 10px;
      color: #000;
      font-size: 16px;
      .card-content-padding {
        padding: 40px 15px;
      }
    }
    .card-selected-wallet {
      background-color: transparent;
      border-radius: 10px;
      color: #fff;
      font-size: 16px;
      .card-header {
          .wallet-name {
              color: #000;
              font-weight: bold;
          }

          .wallet-select {
            .button {
              color: #fff;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background-color: #394158;
            }
          }
      }

      .card-header:after {
          width: 90%;
          left: 5%;
          background-color: #000;
      }

      .card-content-padding {
          padding: 10px 15px;

          >.row {
              padding: 8px 0;

              .col-balance {
                line-height: 24px;
                color: #f1e21d;
              }

              .wallet-parent-names {
                  color: #1b1e23;
                  font-size: 14px;
              }
          }

          .col-btns {
              .button {
                height: 24px;
                line-height: 24px;
                min-width:30px;
                color: var(--f7-color-white);
                padding: var(--f7-button-padding-vertical, 0) var(--f7-padding-size-0);
                font-size: var(--f7-size-ps-12);
                border-radius: var(--f7-px-3);
                background-color: transparent;
              }

              
              .btn-refresh {
                background-color: #185818;
              }
              .btn-transfer {
                background-color: #a23876;
              }

              .btn-transferOut {
                background-color: #1f498e;
              }
          }
      }
    }
    
    .card-bg-bright {
      // background: url(../../../static/images/partner/transfer-bg-bright.png) no-repeat center;
      // background-size: 100% 100%;
      background-color: #689fd0;
    }
    .card-bg-dark {
      // background: url(../../../static/images/partner/transfer-bg-dark.png) no-repeat center;
      // background-size: 100% 100%;
      background-color: #7b85b9;
    }
  }
  .block-transfer-type {
    margin: 0;
    padding: 12px 15px;
    border-top: 1px solid #25428b;
    border-bottom: 1px solid #25428b;
    .color-transferType {
      color: rgb(3, 169, 244);
    }
  }
  .block-transfer-from {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #25428b;
    .trnasfer-from {
      .col {
        height: 48px;
        line-height: 48px;
        color: #bdbdbd;
      }
      .col.active {
        background: url(../../../static/images/yt999/signInNav.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .list-transfer {
    margin: 0;
    ul {
      background-color: transparent;
      .item-input {
        .item-inner {
          .item-title {
            padding-top: 8px;
            color: #fff;
            width: 25%;
          }
          .input-walletBlance {
            color: #ffe012;
          }
        }
        .item-inner:after {
          height: 0;
        }
      }
    }
    ul:before,
    ul:after {
      height: 0;
    }
  }
  .block-buttons {
    padding: 0 12px;
    margin: 10px 0 80px;
    .button-submit {
      background: #eca954;
    }
    .button-recycling {
      border: 1px solid #be9a44;
      color: #be9a44;
      background: #111f42;
    }
  }
  
}
.navright{
  margin-right: 10px; 
}
</style>
<template>
  <f7-page class="page-home" name="game-category-list" @page:init="onPageInit" @page:beforein="onPageBeforeIn">
    <!-- Top Navbar -->
    <f7-navbar back-link :sliding="false">
      <f7-nav-title sliding>
        {{ $t('game_category_list_0001') }}
      </f7-nav-title>
      <f7-nav-right v-if="getLoginState" class="nav-balance">
        <f7-link @click="openBalancePopup">
          <img class="btn-dowload" src="../static/images/yt999/balance.png" height="22">
        </f7-link>
      </f7-nav-right>
      <f7-subnavbar class="sub-nav nav-80">
        <div class="nav-buttons-tab">
          <f7-link tab-link="#tab-egame" tab-link-active icon="iconfont icon-youxi" :text="$t('common_0006')"></f7-link>
          <f7-link tab-link="#tab-casino" icon="iconfont icon-shixun" :text="$t('common_0007')"></f7-link>
          <f7-link tab-link="#tab-card" icon="iconfont icon-qipaishi" :text="$t('common_0008')"></f7-link>
          <f7-link tab-link="#tab-fish" icon="iconfont icon-yu" :text="$t('common_0009')"></f7-link>
          <f7-link tab-link="#tab-sports" icon="iconfont icon-icon-test" :text="$t('common_0010')"></f7-link>  
          <f7-link tab-link="#tab-lottery" icon="iconfont icon-caipiao" :text="$t('common_0011')"></f7-link>               
        </div>
      </f7-subnavbar>
    </f7-navbar>

    <!-- Game Navbutton -->
    <f7-tabs swipeable class="tabs-game">
      <f7-tab @tab:show="initTab('egame')" id="tab-egame" class="page-content tab-egame" tab-active>
        <f7-block class="tab-content">
          <f7-row no-gap>
            <f7-col
              v-for="item in egameList"
              :key="item.Id"
              width="50"
              @click="goGameList(item.Id)"
            >
              <img
                class="lazy lazy-fade-in game-lazy"
                :data-src="'../static/images/yt999/gameList/game' + item.Id + '.png'"
                width="100%"
              >
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="initTab('casino')" id="tab-casino" class="page-content tab-casino">
        <f7-block class="tab-content">
          <f7-row no-gap>
            <f7-col v-for="item in casinoList" :key="item.Id" width="50" @click="playGame(item)">
              <img
                class="lazy lazy-fade-in game-lazy"
                :data-src="`../static/images/yt999/gameList/game${item.Id}.png`"
                width="100%"
              >
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="initTab('card')" id="tab-card" class="page-content tab-card">
        <f7-block class="tab-content">
          <f7-row no-gap>
            <f7-col v-for="item in cardList" :key="item.Id" width="100" @click="playGame(item)">
              <img
                class="lazy lazy-fade-in game-lazy"
                :data-src="'../static/images/yt999/gameList/game' + item.Id + '.png'"
                width="100%"
              >
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="initTab('fish')" id="tab-fish" class="page-content tab-fish">
        <f7-block class="tab-content">
          <f7-row no-gap>
            <f7-col v-for="item in fishList" :key="item.Id" width="100" @click="playGame(item)">
              <img
                class="lazy lazy-fade-in game-lazy"
                :data-src="'../static/images/yt999/gameList/game' + item.Id + '.png'"
                width="100%"
              >
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="initTab('sports')" id="tab-sports" class="page-content tab-sports">
        <f7-block class="tab-content">
          <f7-row no-gap>
            <f7-col v-for="item in sportsList" :key="item.Id" width="100" @click="playGame(item)">
              <img
                class="lazy lazy-fade-in game-lazy"
                :data-src="'../static/images/yt999/gameList/game' + item.Id + '.png'"
                width="100%"
              >
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-tab>
      <f7-tab @tab:show="initTab('lottery', selectLotteryDefault)" id="tab-lottery" class="page-content tab-lottery">
        <div class="card-child-menu">
          <ul>
            <li
              v-for="item in lotteryList"
              :key="item.Id"
              v-bind:class="[item.Code, item.ProviderId===selectLotteryDefault ? 'active': '']"
              @click="selectLotteryDefault = item.ProviderId"
            >
              <!-- <img :src="`../static/images/yt999/lottery/menu_${item.Code.toLowerCase()}.png`" alt > -->
            </li>
          </ul>
        </div>
        <f7-block class="block-lottery tab-content">
          <transition
            name="custom-classes-transition"
            enter-active-class="animated tada"
            leave-active-class="animated bounceOutRight"
          >
            <f7-row no-gap v-show="selectLotteryDefault === 31" class="row-31">
              <f7-col
                v-for="item in lotteryHotList.KKLottery"
                :key="item.GameCode"
                width="25"
                @click="playGame(item)"
              >
                <img
                  class="lazy lazy-fade-in game-lazy"
                  :data-src="`../static/images/yt999/lottery/31/${item.GameCode}.png`"
                >
                <p>{{item.GameName}}</p>
              </f7-col>
            </f7-row>
          </transition>
          <transition
            name="custom-classes-transition"
            enter-active-class="animated tada"
            leave-active-class="animated bounceOutRight"
          >
            <f7-row no-gap v-show="selectLotteryDefault === 25" class="row-25">
              <f7-col 
                v-for="item in lotteryHotList.VRLottery" 
                :key="item.Id" width="25"
                 @click="playGame(item)"
              >
                <img
                  class="lazy lazy-fade-in game-lazy"
                  :data-src="`../static/images/yt999/lottery/25/${item.GameCode}.png`"
                  width="100%"
                >
                <p>{{item.GameName}}</p>
              </f7-col>
            </f7-row>
          </transition>
          <transition
            name="custom-classes-transition"
            enter-active-class="animated tada"
            leave-active-class="animated bounceOutRight"
          >
            <f7-row no-gap v-show="selectLotteryDefault === 509" class="row-509">
              <f7-col 
                v-for="item in lotteryHotList.BGLottery" 
                :key="item.Id" width="25" 
                @click="playGame(item)">
                <img
                  class="lazy lazy-fade-in game-lazy"
                  :data-src="`../static/images/yt999/lottery/509/${item.GameCode}.png`"
                  width="100%"
                >
                <p>{{item.GameName}}</p>
              </f7-col>
            </f7-row>
          </transition>
          <transition
            name="custom-classes-transition"
            enter-active-class="animated tada"
            leave-active-class="animated bounceOutRight"
          >
            <f7-row no-gap v-show="selectLotteryDefault === 505" class="row-505">
              <f7-col 
                v-for="item in lotteryHotList.BBINLottery" 
                :key="item.Id" width="25" 
                @click="playGame(item)">
                <img
                  class="lazy lazy-fade-in game-lazy"
                  :data-src="`../static/images/yt999/lottery/505/${item.GameCode}.png`"
                  width="100%"
                >
                <p>{{item.GameName}}</p>
              </f7-col>
            </f7-row>
          </transition>
        </f7-block>
      </f7-tab>
    </f7-tabs>

    <!-- Quick Transfer -->
    <div class="dialog dialog-balance dialog-move" @dialog:closed="balanceCloseEvent" @dialog:open="balanceOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t('home_0010') }}
          <f7-link @click="closeDialogBalance">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <f7-block-header class="block-header-totalBalance">
              <f7-row>
                <f7-col width="35">{{ $t('home_0011') }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{totalBalance| formatCurrency}}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-refresh" @click="getAllBalanceList">{{ $t('home_0012') }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t('home_0013') }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{walletBalance| formatCurrency}}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-button raised fill class="btn-recycling" @click="oneClickRecycling">{{ $t('common_one_key_transfer_game_balance_back') }}</f7-button>
                </f7-col>
              </f7-row>
              <f7-row>
                <f7-col width="35">{{ $t('home_0015') }}</f7-col>
                <f7-col width="35">
                  <span v-if="totalWalletFlag">{{paddingBalance| formatCurrency}}</span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30"></f7-col>
              </f7-row>
            </f7-block-header>
            <div class="block-content">
              <f7-row class="item-balance" v-for="item in gameBalanceList" :key="item.ProviderId" :class="`item-balance-${item.ProviderId}`">
                <f7-col width="35">{{item.Name}}</f7-col>
                <f7-col width="35" class="col-balance">
                  <span v-if="totalWalletFlag">
                    <span v-if="item.IsMaintain">{{ $t('common_under_maintenance') }}</span>
                    <span v-else-if="!item.QuerySuccess">N/A</span>
                    <span v-else>{{item.Balance| formatCurrency}}</span>
                  </span>
                  <preloader :list="4" v-else></preloader>
                </f7-col>
                <f7-col width="30">
                  <f7-row class="btn-group">
                    <f7-col>
                      <f7-button raised fill class="btn-transfer" :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="mainWalletToGame(item)">{{ $t('common_balance_transfer_in') }}</f7-button>
                    </f7-col>
                    <f7-col>
                      <f7-button raised fill class="btn-transferOut"  :class="{'btn-disabled':item.IsMaintain || !item.QuerySuccess}" @click="gameWalletToMain(item)" >{{ $t('common_balance_transfer_out') }}</f7-button>
                    </f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-recharge border-radius-left" @click="jumpPage(0, 'member-center/deposit')" >{{ $t('home_deposit') }}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button large fill class="btn-withdrawal border-radius-right" @click="jumpPage(0, 'member-center/withdraw')">{{ $t('home_withdrawal') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
        
      </div>
    </div>

  </f7-page>
</template>
<script>
import marquee from "./template/marquee";
import preloader from "./template/preloader";
import api from "../config/api";
import { getCookie, setStore, removeStore, getStore } from "../config/utils";
import { mapGetters, mapMutations } from "vuex";
import {
  getInfo,
  getCarouseladvertList,
  getLampList,
  getGameListp,
  getGameListByPId,
  getBalances,
  getGameEntry,
  getValidProviders,
  mainWalletToGame,
  gameWalletToMain,
  recyclingFunds,
  alreadyRead
} from "../axios/api";
import http from "../axios/http";
import common from '../service/common';

export default {
  components: {
    marquee,
    preloader
  },
  data() {
    return {
      app_version:VERSION,
      dowloadLink: "",
      bannerList: [],
      bannerBaseUri: "",
      bannerShow: false,
      swiperParams: {
        speed: 500,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 2500
        },
        lazy: {
          loadPrevNext: true
        }
      },
      lampList: [], //以下关于游戏变量
      casinoList: [],
      sportsList: [],
      egameList: [],
      cardList: [],
      fishList: [],
      lotteryList: [],
      // lotteryHotList: {
      // },
      kyCardList: [],
      lyCardList: [],
      ytCardList:[],
      vgCardList:[],
      selectCardDefault: 23,
      selectLotteryDefault: 31,
      loadingSkeleton: true, //加载消息弹窗特效标识
      skeletonTimeout: null, //消息弹窗timeout 对象
      gameBalanceList: [], //以下是钱包变量
      totalWalletFlag: false,
      totalBalance: 0.0,
      walletBalance: 0.0,
      paddingBalance: 0.0,
      infiniteLoading: false, //加载条
      userMessageList: [], //个人消息
      selectedMessage:{},
      checkRead: false,
      messageNum: 0,
      isAppDevice: getStore('device'),
      getInfoStatus:false,
      getGameListStatus: false,
      timeoutStatus: false,
    };
  },
  computed: {
    ...mapGetters(["getMerchantInfo", "getAllBalances", "firstLoadApp", 'getLoginState']),
      loadingPopupOpened(){
        return this.$f7router.currentRoute.name == 'home' && this.firstLoadApp;
      },
  },
  methods: {
    ...mapMutations(["SETMERCHAT_INFO", "SETALLBALANCES", "SETFIRSTLOADAPP"]),
    getInfo() {
      const self = this;
      getInfo().then(data => {
        if (data.Code === "NoError" && data.Data) {
          self.getInfoStatus = true;
          const d = data.Data;
          self.SETMERCHAT_INFO(d); //存储Vuex store merchantinfo
          setStore(`ImagesURL`, d.ImagesURL);
          self.bannerBaseUri = d.ImagesURL + window.configs.carouseladvertImg;
          self.dowloadLink = d.BaseInfo.AppSite;
          self.getBannerList({ data: 2 });
        }
      });
    },
    getBannerList(param) {
      const self = this;
      getCarouseladvertList(param).then(data => {
        if (data.Code === "NoError") {
          self.bannerShow = true;
          self.bannerList = data.Data;
        }
      });
    },
    getLampList() {
      const self = this;
      getLampList().then(data => {
        if (data.Code === "NoError") {
          self.lampList = data.Data;
        }
      });
    },
    getGameList() {
      const self = this;
      getGameListp().then(data => {
        if (data.Code === "NoError" && data.Data && data.Data.length) {
          self.getGameListStatus = true;
          const d = data.Data;
          self.casinoList = d.filter(item => {
            return item.Type === "Casino";
          });
          self.sportsList = d.filter(item => {
            return item.Type === "SportsBook";
          });
          self.egameList = d.filter(item => {
            return item.Type === "Egames";
          });
          self.cardList = d.filter(item => {
            return item.Type === "Card";
          });
          self.fishList = d.filter(item => {
            return item.Type === "Fish" && item.ProviderId !== 6;
          });
          self.lotteryList = d.filter(item => {
            return item.Type === "Lottery";
          });
          if(self.lotteryList) self.selectLotteryDefault = self.lotteryList[0].ProviderId;

          self.getGameListByPId(); //等待所有游戏列表加载完成 请求棋牌子游戏列表
        }
      });
    },
    getGameListByPId() {
      const self = this;
      const app = self.$f7;
      self.selectCardDefault = self.cardList[0].ProviderId;
      self.cardList.forEach((item, index) => {
        let params = {
          ParentClassId: item.Id,
          ChildClassId: 0,
          IsHot: false,
          Platform: 1
        };
        getGameListByPId({ data: JSON.stringify(params) }).then(data => {
          self.infiniteLoading = false;
          app.progressbar.hide();
          if (data.Code === "NoError" && data.Data) {
            if (item.ProviderId === 23) {
              self.kyCardList = data.Data;
            } else if (item.ProviderId === 506) {
              self.lyCardList = data.Data;
            } else if (item.ProviderId === 508) {
              self.ytCardList = data.Data;
            } else if (item.ProviderId === 515) {
              self.vgCardList = data.Data;
            }
          }
          
          self.showTabActive();
        });
      });
    },
    playGame(item) {
      const self = this;
      const router = self.$f7router;
      if (self.getLoginState && getCookie("zz")) {
        const params = {
          ProviderId: item.ProviderId,
          gameCode: item.ProviderGameCode,
          Language: "zhCN",
          View: "v1",
          TemplateName: "flashtechgray",
          Platform: 2,
          Name: item.Name||item.GameName,
          Host: window.location.origin,
        };
        const d = {type: 1, data: JSON.stringify(params), z: getCookie('zz')||getStore('zz')};
        const isApp = getStore('device') && getStore('device') === 'App';
        if(!isApp && !common.getBrowser.isSougou) {
          setStore('playGameParam', JSON.stringify(params));
          this.common.redirectURL(`/#/loading-game/`);
          return;
        }

        let promtLoadingGame = self.$f7.dialog.create({
          title: '',
          text: this.$t('common_game_loading_format', [params.Name]),
          cssClass: "dialog-preloadgame",
        }).open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        //self.$f7.$('.dialog-backdrop').css('background-color','transparent');
        
        getGameEntry(d).then(data => {
          self.$f7.params.dialog.closeByBackdropClick = true;
          //self.$f7.$('.dialog-backdrop').css('background-color','rgba(0, 0, 0, 0.74)');
          //promtLoadingGame.close();

          if (data.Code === "NoError" && data.Data) {
            if(params.ProviderId == 512) {
              const entryData = JSON.parse(data.Data);
              let encode = btoa(encodeURI(data.Data));
              window.location.href = entryData.GameEntryURL + '/index.html?info=' + encode;
            } else { 
              window.location.href = data.Data;
            }
          } else {
            promtLoadingGame.close()
            let content =
              data.Code === "ProviderMaintain" &&
              data.Data &&
              data.Data.length >= 2
                ? this.$t('common_0024') + "</br>" + this.$t('game_category_list_0002') + "：" +
                  data.Data[0].replace("T", " ") +
                  "<br/>" + this.$t('game_category_list_0003') + "：" +
                  data.Data[1].replace("T", " ")
                : this.$t('common_loading_game_failed');
            //self.$f7.dialog.alert(content, "");
            self.promptMaintain(content);
          }
        });
      } else {
        router.navigate("/login/");
      }
    },
    promptMaintain(content){
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false;
      //self.$f7.$('.dialog-backdrop').css('background-color','transparent');
      let promptMaintain = self.$f7.dialog.create({
        title: '',
        text: content,
        cssClass: "dialog-game",
      }).open();
      setTimeout(() => {
        self.$f7.params.dialog.closeByBackdropClick = true;
        //self.$f7.$('.dialog-backdrop').css('background-color','rgba(0, 0, 0, 0.74)');
        promptMaintain.close();
      }, 2000);
    },
    goGameList(id) {
      this.$f7router.navigate(`/game-list/${id}/`);
    },
    showDeterminate(multiColor) {
      // 顶部 progressbar加载条
      const self = this;
      const app = self.$f7;
      if (self.infiniteLoading) return;
      self.infiniteLoading = true;
      if (multiColor) {
        app.progressbar.show("multi");
      } else {
        app.progressbar.show(app.theme === "md" ? "yellow" : "blue");
      }
    },
    async getValidProviders() {
      const self = this;
      let res = await http.post(api.validproviders);
      if (res.Code === "NoError") {
        self.$f7.dialog.open('.dialog-balance',true);
        self.gameBalanceList = res.Data;

        self.getAllBalanceList();
      }
    },
    getAllBalanceList() {
      const self = this;
      self.totalWalletFlag = false; //总钱包余额是否请求完成标识
      getBalances().then(data => {
        if (data.Code === "NoError") {
          const res = data.Data;
          self.totalWalletFlag = true;
          self.SETALLBALANCES(res); //存储Vuex store balance
          self.gameBalanceList = res[0];
          self.totalBalance = res[1];
          self.paddingBalance = res[2];
          self.walletBalance = res[3];
        }
      });
    },
    jumpPage(type, param) {
      const self = this;
      const router = self.$f7router;
      if (type === 0) {
        //balance popup 弹窗
        self.$f7.dialog.close('.dialog-balance');
        router.navigate(`/${param}/`);
      }
    },
    oneClickRecycling(event) {
      const self = this;
      if (!self.totalWalletFlag) return;
      self.$f7.$('.btn-recycling').addClass('progressbar');
      self.$f7.$('.btn-recycling').text(this.$t('common_game_balance_transfer_back'));
      recyclingFunds().then(data => {
        self.$f7.$('.btn-recycling').removeClass('progressbar');
        self.$f7.$('.btn-recycling').text(this.$t('common_one_key_transfer_game_balance_back'));
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    mainWalletToGame(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !self.walletBalance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: self.walletBalance
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass('progressbar');
      mainWalletToGame(d).then(data => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass('progressbar');
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    gameWalletToMain(item) {
      const self = this;
      if (!self.totalWalletFlag || item.IsMaintain || !item.QuerySuccess || !item.Balance) return;
      const params = {
        ProviderId: item.ProviderId,
        Amount: item.Balance
      };
      const d = { data: JSON.stringify(params) };
      self.$f7.$(`.item-balance-${item.ProviderId}`).addClass('progressbar');
      gameWalletToMain(d).then(data => {
        setTimeout(() => {
          self.$f7.$(`.item-balance-${item.ProviderId}`).removeClass('progressbar');
        }, 1000);
        if (data.Code === "NoError") {
          self.getAllBalanceList();
        }
      });
    },
    marqueePopupOpened() {
      var self = this;
      self.skeletonTimeout = setTimeout(() => {
        self.loadingSkeleton = false;
      }, 3000);
    },
    marqueePopupClose() {
      clearTimeout(this.skeletonTimeout);
      this.loadingSkeleton = true;
    },
    openBalancePopup() {
      const self = this;
      if (self.getLoginState) {
        self.getValidProviders();
      }
    },
    closeDialogBalance() {
      this.$f7.dialog.close('.dialog-balance');
    },
    balanceOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    balanceCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    loadingStatusEvent() {
      const self = this;
      const start = new Date();
      let timeoutShowReload = setInterval(() => {
        const end = new Date();
        let time = (end - start) / 1000;
        if (time >= 10) {
            self.timeoutStatus = true;
            clearInterval(timeoutShowReload);
        }
      }, 1000)
      let loadingInterval = setInterval(() => {
        const end = new Date();
        let time = (end - start) /1000;
        if(time > 3) {
          if(this.firstLoadApp && this.bannerShow && this.getInfoStatus && this.getGameListStatus) {
            //this.loadingPopupOpened = false;
            this.$f7.popup.close('.loadding-popup');
            this.SETFIRSTLOADAPP(false);
            clearInterval(loadingInterval);
            clearInterval(timeoutShowReload);
          }
        }
      }, 1000);
    },
    showTabActive(){
      const tabActiveIndex = getStore("home-tab-active") || "1";
      switch (tabActiveIndex) {
        case "1":
          this.$f7.tab.show("#tab-casino");
          break;
        case "2":
          this.$f7.tab.show("#tab-lottery");
          break;
        case "3":
          this.$f7.tab.show("#tab-sports");
          breakk;
        case "4":
          this.$f7.tab.show("#tab-egame");
          break;
        case "5":
          this.$f7.tab.show("#tab-card");
          break;
        case "6":
          this.$f7.tab.show("#tab-fish");
          break;
        default:
          break;
      }
      setTimeout(() => {
        removeStore("home-tab-active");
      }, 500);

      //this.initTab('casino');
    },
    toggleCardType(id) {
      this.selectCardDefault = id;
      this.initTab('card', id);
    },
    onPageInit(e, page) {
      const self = this;
      self.$f7ready(f7 => {
        self.getInfo();
        self.getLampList();
        self.getGameList();
        var targetGameType=self.$f7route.params.GameType;
        setStore("home-tab-active",targetGameType);
      });
    },
    onPageBeforeIn() {
      this.$f7.emit("myCustomEvent", "home");
      removeStore("mc-tab-active");
    },
    initTab(tab, id) {
      const self = this;
      if(tab === 'lottery') {
        setTimeout(() => {
          let height = self.$f7.$(`#tab-${tab} .tab-content .row-${id}`).height() + 70;
          self.$f7.$(`.tabs-game`).css('height', `${height}px`);
        }, 500);
      } else {
        let height = self.$f7.$(`#tab-${tab} .tab-content`).height() + 30;
        self.$f7.$(`.tabs-game`).css('height', `${height}px`);
      }
    },
  },
  created() {},
  mounted() {},
  updated() {
    this.$f7.lazy.create(this.$el);
    this.$f7.swiper.create(this.$el);
  }
};
</script>
<style lang="less" scoped>
  .page-home{
    --f7-page-subnavbar-offset:80px;
  }
  .nav-80{
    height:77px;
  }
</style>
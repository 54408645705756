<template>
  <f7-page
    no-toolbar
    no-swipeback
    name="redPacket-history"
    class="page-redPacket-history"
   
  >
    <f7-navbar :title="$t('red_packet_history0001')" back-link :no-hariline="true"></f7-navbar>
    <div class="buttons-tab buttons-message">
      <f7-link tab-link="" :tab-link-active='readActiveIndex==0' @click="getSendList()" :text="$t('red_packet_history0002')"></f7-link>
      <f7-link tab-link="" :tab-link-active='readActiveIndex==1' @click="getReceivelist()" :text="$t('red_packet_history0003')"></f7-link>
    </div>
    <div v-if="readActiveIndex==0">
      <f7-block class="block-records" v-if="recordsList.length>0">
        <div class="card card-outline" v-for="item in recordsList" :key="item.Id" @click="openredPacketDetail(item.MemberRedPacketSerialKey,item.MemberRedPacketStatus)" >
          <div class="card-content card-content-padding">
            <div class="row no-gap">
              <div class="col-40 text-left co-black padding-left-20" style="font-weight: bold;">{{(item.TotalAmount-item.RemainAmount)|formatCurrency}}/{{item.TotalAmount|formatCurrency}}</div>
              <div class="col-60 co-black" style="font-weight: bold;">{{item.MemberRedPacketType=='FixedAmountRedPacket'?'-- Identical Amount':'-- Random Amount'}}</div>
            </div>
            <div class="row no-gap">
              <div class="col-40 text-left co-gray padding-left-20">{{$t('red_packet_history0003')}} {{item.TotalPacketCount-item.RemainPacketCount}}/{{item.TotalPacketCount}}</div>
              <div class="col-60 co-gray">{{$t('red_packet_history0005')}}&nbsp;&nbsp;{{item.ValidFrom.replace('T',' ')}}</div>
            </div>
            <div class="row no-gap">
              <div class="col-40 text-left co-gray padding-left-20">  
                <span style="color: red;" v-if="item.MemberRedPacketStatus=='Active'">(Active)</span>
                <span style="color: blue;" v-if="item.MemberRedPacketStatus=='Finish'">(Finish)</span>
                <span style="color: #b16404;" v-if="item.MemberRedPacketStatus=='Expired'">(Expired)</span>
              </div>
              <div class="col-60 co-gray">
                {{$t('red_packet_history0006')}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{item.ValidTo.replace('T',' ')}}
              </div>
            </div>
            <span class="arrow">></span>
          </div>
        </div>
      </f7-block>
      <f7-card v-else-if="recordsList.length<=0&&!recordsShowData" class="no-data">
        <img src="../../../static/images/yt999/noData.png" width="45%">
      </f7-card>
    </div>
    <div v-else>
      <f7-block class="block-records" v-if="receivelist.length>0">
        <div class="card card-outline" v-for="item in receivelist" :key="item.Id" @click="openredPacketDetail(item.MemberRedPacketSerialKey,'')" style="background: linear-gradient(to right,white 40%,  #f3f3f3 90%);">
          <div class="card-content card-content-padding">
            <div class="row no-gap">
              <div class="padding-left-20 co-gray">
                <span v-if="item.IsLucky">{{$t('red_packet_history0009')}}</span>&nbsp;
                {{$t('red_packet_history0007')}}:&nbsp;{{item.ReceiveAmount|formatCurrency}}
              </div>
            </div>
            <div class="row no-gap">
              <div class="padding-left-20 co-gray">{{$t('red_packet_history0008')}}:&nbsp;{{item.ReceiveTS.replace('T',' ')}}  </div>
            </div>
            <span class="arrow">></span>
          </div>
        </div>
      </f7-block>
      <f7-card v-else-if="receivelist.length<=0&&!receivelistShowData" class="no-data">
        <img src="../../../static/images/yt999/noData.png" width="45%">
      </f7-card>
    </div>

    <!-- 红包详情 -->
    <div class="dialog dialog-redPacketDetail" @dialog:closed="messageCloseEvent" @dialog:open="messageOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          <h3>{{redPacketDetail.Sender}}{{$t('red_packet_history0010')}}</h3>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <div class="block-content" >
              <p class="remaining">
                <span>{{$t('home_0102')}}{{redPacketDetail.TotalPacketCount-redPacketDetail.RemainPacketCount}}/{{redPacketDetail.TotalPacketCount}}</span> , 
                <span>{{$t('home_0103')}}{{(redPacketDetail.TotalAmount-redPacketDetail.RemainAmount)|formatCurrency}}/{{redPacketDetail.TotalAmount|formatCurrency}}</span>
              </p>
              <p class="row" v-for="(item,index) in  redPacketDetail.ReceivedList" :key="index">
                <span class="line">{{item.UserName}}</span>
                <span>{{item.ReceiveAmount|formatCurrency}}</span>
                <span v-if="item.IsLucky"><span class="luck">{{$t('home_0159')}}</span></span>
              </p>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button
                    large
                    class="btn-next border-radius-left"
                    @click="closeredPacketDetail()"
                  >{{$t('red_packet_history0014')}}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button
                    large
                    fill
                    class="btn-close border-radius-right"
                    :class="redPacketStatus?``:`button-disabled`"
                    @click="redPacketStatus?getredpacketmsg():''"
                  >{{$t('red_packet_history0013')}}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
    <!-- 红包码 -->
    <div class="dialog dialog-Tip" @dialog:closed="messageCloseEvent" @dialog:open="messageOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          <h3>{{$t('send_red_packet_0018')}}</h3>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <div class="block-content" >
              <p style="margin-top: 0px;font-size: 14px;"> {{$t('send_red_packet_0019')}}</p>
              <p class="line"></p>
              <p style="font-size: 14px;">{{redPacketCode}}</p>
              <p class="copy-result">{{copyResult}}</p>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button
                    large
                    class="btn-next border-radius-left"
                    @click="closeTip()"
                  >{{$t('send_red_packet_0021')}}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button
                    large
                    fill
                    class="btn-close border-radius-right"
                    v-clipboard:copy="redPacketCode"
                    v-clipboard:error="onError"
                    v-clipboard:success="onCopy"
                  >{{$t('send_red_packet_0020')}}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { 
  getFundsRecordsList,
  sendlist,
  receivelist,
  redpacketDetail,
  getredpacketmsg
} from "../../../axios/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      recordsList: [],
      receivelist: [],
      redPacketDetail: {},
      recordsShowData: true,
      receivelistShowData: true,
      readActiveIndex: 0,
      redPacketStatus: false,
      redPacketKey: '',
      redPacketCode: '',
      copyResult: '--'
    };
  },
  watch: {},
  computed: {},
  methods: {
    getSendList() {
      const self = this;
      self.readActiveIndex = 0;
      sendlist().then(data => {
        if (data.Code === "NoError") {
          self.recordsList = data.Data;
          self.recordsShowData = self.recordsList.length > 0;
        }
      });
    },
    getReceivelist(){
      const self = this;
      self.readActiveIndex = 1;
      receivelist().then(data => {
        if (data.Code === "NoError") {
          self.receivelist = data.Data;
          self.receivelistShowData = self.receivelist.length > 0;
        }
      });
    },
    getRedpacketDetail(key,status){
      const self = this;
      self.redPacketKey = key;
      self.redPacketStatus = status==='Active'? true : false;
      const param={
        data: JSON.stringify({"Key":key})
      };
      redpacketDetail(param).then(data => {
        if (data.Code === "NoError") {
          self.redPacketDetail = data.Data;
        }
      });
    },
    getredpacketmsg(key) {
      const self = this;
      self.closeredPacketDetail();
      const param={
        data: JSON.stringify({"Key":self.redPacketKey})
      };
      getredpacketmsg(param).then(data => {
        if (data.Code === "NoError") {
          self.$f7.$('.dialog-backdrop').css('background-color','transparent');
          self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
          self.openTip();
          self.redPacketCode = data.Data;
        }
      });
    },
    openredPacketDetail(key,status){
      const self = this;
      self.getRedpacketDetail(key,status);
      self.$f7.dialog.open('.dialog-redPacketDetail');
    },
    closeredPacketDetail(){
      const self = this;
      self.$f7.dialog.close('.dialog-redPacketDetail');
    },
    messageOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    messageCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    openTip(){
      const self = this;
      self.$f7.dialog.open('.dialog-Tip');
    },
    closeTip(){
      const self = this;
      self.$f7.dialog.close('.dialog-Tip');
    },
    onCopy(e) {
      const self = this;
      if(e.text) {
        self.copyResult=self.$i18n.t('send_red_packet_0022');
        setTimeout(() => {
          self.copyResult='--';
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
  },
  created() {
    this.getSendList();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-redPacket-history {
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #ccc;
      // background-color: #eeeeee;
      background: linear-gradient(to right, #f3f3f3 35%, white 35%);
      margin: 8px;
      .co-white {
        color: #464646;
      }
      .co-gray {
        color: #464646;
      }
      .co-black {
        color: #101010;
      }
      .co-green {
        color: #33cd5f;
      }
      .co-blue {
        color: #11c1f3;
      }
      .co-yellow {
        color: #f11f1f;
      }
      .co-red {
        color: #fd6565;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #b7b7b7;
      }
      .card-header {
        display: block;
        font-size: 12px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -3px;
          color: blue;
        }
      }
      .card-content {
        font-size: 12px;
        padding: 2px 4px;
        position: relative;
        .row {
          padding: 4px 0;
        }
        .arrow{
          position: absolute;
          right: 10px;
          top: 40%;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
        font-size: 12px;
      }
    }
  }
  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
  .padding-left-20{
    padding-left: 20px;
  }
}
.dialog-redPacketDetail {
  width: 94%;
  margin: 0 3%;
  background-color: white;
  // border: 1px solid rgba(177,177,177,0.58);
  left: 0;

  .dialog-inner {
      padding: 0;
      text-align: left;

      .dialog-title {
          text-align: center;
          position: relative;
          font-size: 18px;
          color: #423232;
          h3 {
            margin: 0px;
            padding: 10px 0;
            font-size: 18px;
          }

          h5 {
              margin: 0;
              font-weight: normal;
          }
      }
      .line{
        position: relative;
        margin:0px 0 25px 0px!important;
      }
      .remaining{
        padding-left: 18px;
        margin: 0px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #464646;
      }
      .dialog-title:after,.line::after {
          content: '';
          position: absolute;
          z-index: 1;
          background: url(../../../static/images/yt999/light.png) no-repeat;
          background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
          height: var(--f7-height-size-2);
          width: 100%;
          display: block;
          top: auto;
          right: auto;
          bottom: 0;
          left: 0;
          transform-origin: 50% 100%;
          transform: scaleY(1);
          transform: scaleY(calc(1/var(--f7-device-pixel-ratio)));
      }

      .dialog-text {
          .block-message {
              margin: var(--f7-margin-size-0);
              padding: var(--f7-padding-size-0);
              width: var(--f7-width-size-pt100);

              .block-content {
                  padding: var(--f7-padding-size-8) var(--f7-padding-size-10);
                  overflow: hidden scroll;
                  font-size: 15px;
                  min-height: 300px;
                  .money{
                    text-align: center;
                    p:nth-child(1){
                      font-size: 18px;
                      font-weight: bold;
                    }
                  }
                  .tipp{
                    margin-top: 0px;
                    padding-left: 15px;
                    p{
                      margin-top: 0px;
                      margin-bottom: 10px;
                    }
                  }
                  .input-code{
                    border: 1px solid #e2e2e2;
                    width: 96%;
                    border-radius: 4px;
                    margin-left: 2%;
                    padding: 8px;
                  }
                  .red-packet-message{
                    margin-bottom: -8px;
                    text-align: center;
                    color: #101010;
                  }
                  .row{
                    width: 95%;
                    margin: 0 auto;
                    background: #dedede;
                    margin-bottom: 10px;
                    border-radius: 5px;
                    height: 35px;
                    line-height: 35px;
                    .luck{
                      background: #a07676;
                      color: #fff;
                      border-radius: 5px;
                      width: 52px;
                      height: 26px;
                      line-height: 26px;
                      font-size: 12px;
                    }
                    span{
                      display: inline-block;
                      text-align: center;
                      width: 33%;
                    }
                    .line{
                      position: relative;
                    }
                    .line:after{
                      content: '';
                      position: absolute;
                      top: -15px;
                      left: 105px;
                      width: 1px;
                      height: 40px;
                      background: #ada1a1;
                    }
                  }
              }

              .row-toggle {
                  margin: 10px 10px 0;

                  span {
                      color: #40baff;
                  }

                  .toggle {
                      margin-top: var(--f7-margin-size-f2);
                  }
              }

              .block-footer {
                  margin-top: 8px;

                  .row.no-gap {
                      --f7-grid-gap: 0px;
                  }

                  .button {
                      height: 50px;
                      line-height: 50px;
                      color: var(--f7-color-white);
                      font-size: var(--f7-px-14);
                  }

                  .btn-next {
                      background-color: #ccc;
                      color: #000;
                  }

                  .button-disabled {
                      opacity: .6;
                  }

                  .btn-close {
                      background-color: var(--f7-THEME-color);
                      color: white;
                      border-radius: 0;
                  }
                  .width100{
                    width: 100%;
                  }
              }
          }
      }
  }
}
.dialog-Tip {
    width: 94%;
    margin: 0 3%;
    background-color: white;
    // border: 1px solid rgba(177,177,177,0.58);
    left: 0;

    .dialog-inner {
        padding: 0;
        text-align: left;

        .dialog-title {
            text-align: center;
            position: relative;
            font-size: 18px;
            color: #423232;
            h3 {
              margin: 0px;
              padding: 10px 0;
              font-size: 18px;

            }

            h5 {
                margin: 0;
                font-weight: normal;
            }
        }
        .line{
          position: relative;
          margin:0px 0 40px 0px!important;
        }
        .dialog-title:after,.line::after {
            content: '';
            position: absolute;
            z-index: 1;
            background: url(../../../static/images/yt999/light.png) no-repeat;
            background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
            height: var(--f7-height-size-2);
            width: 100%;
            display: block;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            transform-origin: 50% 100%;
            transform: scaleY(1);
            transform: scaleY(calc(1/var(--f7-device-pixel-ratio)));
        }

        .dialog-text {
            .block-message {
                margin: var(--f7-margin-size-0);
                padding: var(--f7-padding-size-0);
                width: var(--f7-width-size-pt100);

                .block-content {
                    padding: var(--f7-padding-size-8) var(--f7-padding-size-10);
                    overflow: hidden scroll;
                    font-size: 15px;
                    p{
                      margin: 20px 0;

                    }
                    .copy-result{
                      margin-bottom: -8px;
                      text-align: center;
                      color: #101010;
                    }
                }

                .row-toggle {
                    margin: 10px 10px 0;

                    span {
                        color: #40baff;
                    }

                    .toggle {
                        margin-top: var(--f7-margin-size-f2);
                    }
                }

                .block-footer {
                    margin-top: 8px;

                    .row.no-gap {
                        --f7-grid-gap: 0px;
                    }

                    .button {
                        height: 50px;
                        line-height: 50px;
                        color: var(--f7-color-white);
                        font-size: var(--f7-px-14);
                    }

                    .btn-next {
                        background-color: #ccc;
                        color: #101010;
                    }

                    .button-disabled {
                        opacity: .6;
                    }

                    .btn-close {
                        background-color: var(--f7-THEME-color);
                        color: white!important;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}
.buttons-message {
    height: 49px;
    width: var(--f7-width-size-pt100);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    box-sizing: var(--f7-border-box);
    align-items: var(--f7-text-center);
    align-content: var(--f7-text-center);
    overflow: var(--f7-hidden);
    background-color:var(--f7-bars-bg-color);


    height: 35px;
    border-radius: 8px;
    margin: 10px auto;
    width: 90%;
    border: 1px solid blue;
    a.tab-link {
      width: var(--f7-width-size-pt100);
      height: var(--f7-height-size-pt100);
      box-sizing: var(--f7-border-box);
      display: var(--f7-display-flex);
      justify-content: var(--f7-text-center);
      align-items: var(--f7-text-center);
      flex-direction: var(--f7-flex-direction);
      text-transform: var(--f7-tabbar-link-text-transform);
      font-weight: var(--f7-tabbar-link-font-weight);
      letter-spacing: var(--f7-tabbar-link-letter-spacing);
      overflow: var(--f7-hidden);
      color: var(--f7-color-gray-tint);
      font-size: var(--f7-px-14);

      color: black;
    }

    a.tab-link.tab-link-active {
      color: white;
      background-color: var(--f7-THEME-color);
      background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
    }
  }
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './action'
import getters from './getters'
import { getCookie } from '../config/utils'

Vue.use(Vuex)

const state = {
	merchantInfo: {},
	isLogin: false,
	loginInfo: [],
	allBalances: [],
	userInfo: {},
	firstLoadApp: true,
	securityCode: '',
	onlineCustomLoaded: false,
	currency: getCookie('currency'),
	currencyList: [],
	missionList: []
}

export default new Vuex.Store({
	actions,
	getters,
	state,
	mutations
})
<template>
  <f7-page no-toolbar no-swipeback name="order-records" class="page-betting-records" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar :title="$t('betting_records_0001')" back-link :no-hariline="true" innerClass="inner-betting">
      <f7-nav-right>
        <f7-link>
          <f7-button outline class="button-total" @click="openBettingDialog">{{ $t("common_0032") }}</f7-button>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="block accordion-list block-records" v-if="bettingList.length > 0">
      <div class="statistics" v-for="item in formatBettingList" :key="item.TicketNo">
        <ul style="line-height: 22px;padding-bottom: 10px;">
          <div class="list-a">
            <li class="list-title">
              <span>{{ item.TicketNo }}</span>
            </li>
            <li class="list-ts">
              <span>{{ item.BetTs | time_local }}</span>
            </li>
          </div>
          <li class="list-img"><img :src="item.ProviderGameExtensionData" /></li>
          <li v-if="item.IsLuckyOrder == true" class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_lucky.png" />
            <img />
          </li>
          <li v-else class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_normal.png" />
            <img />
          </li>
          <li class="list-detail">
            <p class="p-name">{{ item.GameName }}</p>
            <p> × 1</p>
            <p>
              <span v-if="item.ShowPendingReviewStatus" class="col-yellow">({{ $t("betting_records_0021") }})</span>
              <span v-else>
                <span v-if="item.WLStatus == 'P'" class="col-yellow">({{ $t("betting_records_0003") }})</span>
                <span v-if="item.WLStatus == 'WA'" class="col-green">({{ $t("betting_records_0020") }})</span>
                <span v-if="item.WLStatus == 'WH'" class="col-blue">({{ $t("betting_records_0008") }})</span>
                <span v-if="item.WLStatus == 'C'" class="col-gray">({{ $t("betting_records_0004") }})</span>
              </span>
            </p>
            <p v-if="item.WLStatus !== 'C'">
              {{ $t("winloserp_0015") }}
              <span style="color: red"><span class="symbol">{{ currencySymbol }}</span>{{ item.APIWLAmount }}</span>
            </p>
            <p v-else>
              {{ $t("winloserp_0015") }}
              <span class="col-gray"><span class="symbol">{{ currencySymbol }}</span>0.00</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <f7-card v-else-if="bettingList.length <= 0 && !showDataBetting" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${$f7.params.currLang}.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" />
    </f7-card>

    <div class="dialog dialog-balance dialog-betting dialog-move" @dialog:closed="actionBettingTotalCloseEvent" @dialog:open="actionBettingTotalOpenEvent">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t("common_0032") }}
          <f7-link @click="closeDialogBalance">
            <i class="f7-icons">chevron_right</i>
          </f7-link>
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <div class="block-content">
              <f7-row class="item-balance">
                <f7-col width="55">{{ $t("betting_records_0017") }}</f7-col>
                <f7-col width="45" class="col-balance">{{ pageTotal }}</f7-col>
              </f7-row>
              <f7-row class="item-balance">
                <f7-col width="55">{{ $t("betting_records_0018") }}</f7-col>
                <f7-col width="45" class="col-balance">{{ totalBetAmount | formatCurrency }}</f7-col>
              </f7-row>
              <f7-row class="item-balance">
                <f7-col width="55">{{ $t("betting_records_0015") }}</f7-col>
                <f7-col width="45" class="col-balance">{{ totalEffectiveAmount | formatCurrency }}</f7-col>
              </f7-row>
              <f7-row class="item-balance">
                <f7-col width="55">{{ $t("betting_records_0019") }}</f7-col>
                <f7-col width="45" class="col-balance">{{ totalWLAmount | formatCurrency }}</f7-col>
              </f7-row>
            </div>
          </f7-block>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getTransactionlist } from "../../../axios/api";
import common from "../../../service/common";
import { getCookie,getStore  } from "../../../config/utils";

export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: '',
      bettingList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      totalBetAmount: 0,
      totalEffectiveAmount: 0,
      totalWLAmount: 0,
      showDataBetting: true,
    };
  },
  watch: {},
  computed: {
    formatBettingList() {
      if (!this.bettingList.length) return this.bettingList;
      let betList = this.bettingList;
      return betList;
    },
  },
  methods: {
    getBettingListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = Math.round(new Date(queryParam.BeginDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.EndDate = Math.round(new Date(queryParam.EndDate.replace(/-/g, "/")).getTime() / 1000);
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 10;

      const params = { data: JSON.stringify(queryParam) };
      getTransactionlist(params).then((data) => {
        if (data.Code === "NoError") {
          self.bettingList = self.bettingList.concat(data.Data.Data);
          self.showDataBetting = self.bettingList.length > 0;
          self.pageTotal = data.Data.Total;
          self.totalBetAmount = data.Data.TotalBetAmount;
          self.totalEffectiveAmount = data.Data.TotalEffectiveAmount;
          self.totalWLAmount = data.Data.TotalWLAmount;
          if (self.bettingList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.bettingList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.getBettingListEvent();
        self.allowInfinite = true;
      }, 1000);
    },
    actionBettingTotalOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    actionBettingTotalCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    closeDialogBalance() {
      this.$f7.dialog.close(".dialog-betting");
    },
    openBettingDialog() {
      this.$f7.dialog.open(".dialog-betting");
    },
  },
  created() {
    this.currencySymbol = getStore('currencySymbol');
    this.getBettingListEvent();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.statistics {
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
  width: 96%;
  margin: auto;
  border: 1px solid #ddd9d9;
  border-radius: 4px;
  text-align: center;
  margin-top: 10px;
  background-color: #f9f9f9;
  .list-a {
    border-bottom: 1px solid #ededed;
    .list-title {
      width: 96%;
      line-height: 26px;
      display: inline-block;
      margin-top: 0px;
      text-align: left;
      margin-left: 40px;
      font-weight: bold;
      font-size: 13px;
      margin-bottom: -3px;
    }
    .list-ts {
      width: 90%;
      display: inline-block;
      text-align: left;
      margin-left: 39px;
      //border-bottom: 1px solid #ddd9d9;
      //margin: auto;
    }
  }

  .list-img {
    width: 30%;
    line-height: 30px;
    display: inline-block;
    margin-top: 10px;
    text-align: left;
    margin: 10px 0px 10px 0px;
    img {
      position: absolute;
      top: 50px;
      left: 3%;
      width: 90px;
      height: 97px;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
    }
  }
  .list-type-ico {
    position: absolute;
    top: -3px;
    left: 10px;
  }
  .list-detail {
    width: 66%;
    line-height: 30px;
    display: inline-block;
    margin-top: 10px;
    p {
      font-weight: 500;
      line-height: 1;
      margin-top: 0;
      text-align: left;
      margin-bottom: 9px;
    }
    .p-name {
      font-weight: bold;
      font-size: 13px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .list-ico {
    position: absolute;
    right: 3%;
    top: 10px;
    color: #3e66ce;
  }
  .col-blue {
    color: #1576dd;
  }
  .col-green {
    color: #17903a;
  }

  .col-yellow {
    color: #ff7e00;
  }
  .col-gray {
    color: #777777;
  }
  .title {
    text-align: center;
    font-size: 18px;
    padding-top: 6px;
    font-weight: bold;
    color: #ad5c09;
  }
  .amount {
    text-align: center;
    font-size: 18px;
    padding-top: 6px;
    font-weight: bold;
    color: #ad5c09;
  }
  .desc {
    border-top: 1px solid #e0dbdb;
    margin-top: 10px;
    .p1 {
      margin: 10px;
      font-weight: bold;
    }
    .p2 {
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: 10px;
      word-wrap: break-word;
    }
  }
}
</style>

<template>
  <f7-page no-toolbar no-swipeback name="sign-in" class="page-sign-in">
    <f7-navbar :title="$t('v1_sign_in_0001')" back-link :no-hariline="true"></f7-navbar>
    <div class="buttons-tab buttons-signIn">
      <f7-link tab-link="#tab-signIn-center" tab-link-active :text="$t('v1_sign_in_0002')"></f7-link>
      <f7-link tab-link="#tab-signIn-instructions" :text="$t('v1_sign_in_0003')"></f7-link>
    </div>
    <f7-tabs swipeable class="tabs-signIn">
      <f7-tab id="tab-signIn-center" class="page-content tab-signIn-center" tab-active>
        <f7-block class="block-signIn">
          <div class="signIn-title text-center">
            <p>
              <span>{{userLevelName}}</span> {{ $t('v1_sign_in_0004') }} <span>{{dailyAmount?dailyAmount:0}}</span>{{ $t('v1_sign_in_0005') }}
            </p>
          </div>
          <div class="signIn-main" id="js-signIn-main">
            <div class="header">
              <div>{{ $t('v1_sign_in_0006') }}</div>
              <div>{{ $t('v1_sign_in_0007') }}</div>
              <div>{{ $t('v1_sign_in_0008') }}</div>
              <div>{{ $t('v1_sign_in_0009') }}</div>
              <div>{{ $t('v1_sign_in_0010') }}</div>
              <div>{{ $t('v1_sign_in_0011') }}</div>
              <div>{{ $t('v1_sign_in_0012') }}</div>
            </div>
            <!--签到内容-->
            <ul class="signIn-list" id="js-signIn-list"></ul>
          </div>

          <f7-list inset class="button-signIn" :class="{'disabled-status': buttonSinginStatus}">
            <f7-list-button :title="$t('v1_sign_in_0013')" @click="signInEvent"></f7-list-button>
          </f7-list>
          <div class="signIn-footer">
            {{ $t('v1_sign_in_0014') }}：
            <span>{{usernameSignindetailDayMoney?usernameSignindetailDayMoney+ $t('v1_sign_in_0005') : $t('v1_sign_in_0015') }}</span>
          </div>
        </f7-block>
      </f7-tab>
      <f7-tab id="tab-signIn-instructions" class="page-content tab-signIn-instructions">
        <f7-block>
          <table>
            <thead>
              <tr class="row-header">
                <th>{{ $t('v1_sign_in_0016') }}</th>
                <th>{{ $t('v1_sign_in_0017') }}</th>
                <!-- <th>{{ $t('v1_sign_in_0018') }}</th>
                <th>{{ $t('v1_sign_in_0019') }}</th> -->
                <th>{{ $t('v1_sign_in_0020') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="row-detail" v-for="(item, index) in dailySettingList" :key="index">
                <td>{{item.Name}}</td>
                <td class="daily-amount">{{item.DailyAmount}}</td>
                <!-- <td>7/15/25&&nbsp{{ $t('v1_sign_in_0021') }}</td> -->
                <!-- <td class="signIn-reward">{{item.Days7Amount}}/{{item.Days15Amount}}/{{item.Days25Amount}}</td> -->
                <td>{{item.ActivateMonthlyDeposit}}</td>
              </tr>
            </tbody>
          </table>
          <div class="instructions-footer">
            <p class="footer-title">{{ $t('common_0004') }}:</p>
            <p>1.&nbsp;{{ $t('v1_sign_in_0022') }}</p>
            <p>2.&nbsp;{{ $t('v1_sign_in_0023') }}</p>
            <p>3.&nbsp;{{ $t('v1_sign_in_0024') }}</p>
            <p>4.&nbsp;{{ $t('v1_sign_in_0025', [$f7.params.name]) }}</p>
          </div>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import {
  getUserInfo,
  getVipsigninsettinglist,
  signIn,
  getUsernameSignindetailDay
} from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      dailySettingList: [],
      dailyAmount: 0,
      dailyLevelName: "",
      userLevelName: "",
      usernameSignindetailDay: [],
      usernameSignindetailDayMoney: 0,
      buttonSinginStatus: false
    };
  },
  watch: {},
  computed: {},
  methods: {
    userInfo() {
      const self = this;
      getUserInfo().then(data => {
        if (data.Code === "NoError") {
          self.userLevelName = data.Data.VipLevelName;
          self.signInSettingList();
        }
      });
    },
    signInSettingList() {
      const self = this;
      getVipsigninsettinglist().then(data => {
        if (data.Code === "NoError") {
          self.dailySettingList = data.Data;
          data.Data.forEach(item => {
            if (self.userLevelName === item.Name) {
              self.dailyAmount = item.DailyAmount;
              self.dailyLevelName = self.userLevelName;
            }
          });
        }
      });
    },
    signInEvent() {
      const self = this;
      if (self.buttonSinginStatus) return;
      signIn().then(data => {
        self.$f7.dialog.alert(data.Data, this.$t('common_0004'), () => {
          self.signInDetailDay(true);
        });
      });
    },
    signInDetailDay(f) {
      const self = this;
      const param = { data: JSON.stringify({ Choose: f }) };
      getUsernameSignindetailDay(param).then(data => {
        if (data.Code === "NoError") {
          self.usernameSignindetailDay = data.Data[0];
          self.usernameSignindetailDayMoney = data.Data[1];
          self.buttonSinginStatus = data.Data[2];
          self.signCalendar(self.usernameSignindetailDay);
        }
      });
    },
    signCalendar(dayData) {
      const self = this;
      const $$ = self.$f7.$;
      let $dateBox = $$("#js-signIn-list"),
        $currentDate = $$(".current-date"),
        _html = "",
        _handle = true,
        myDate = new Date();
      $currentDate.text(
        myDate.getFullYear() +
          this.$t('v1_sign_in_0026') +
          parseInt(myDate.getMonth() + 1) +
          this.$t('v1_sign_in_0027') +
          myDate.getDate() +
          this.$t('v1_sign_in_0006')
      );
      let monthFirst = new Date(
        myDate.getFullYear(),
        parseInt(myDate.getMonth()),
        1
      ).getDay();
      let totalDay = new Date(
        myDate.getFullYear(),
        parseInt(myDate.getMonth() + 1),
        0
      ).getDate();
      let monthLast = new Date(
        myDate.getFullYear(),
        parseInt(myDate.getMonth()),
        totalDay
      ).getDay();
      let num1 = totalDay - (7 - monthFirst);
      let num2 = num1 - (monthLast + 1);
      let num3 = num2 / 7;
      let tableNum = (num3 + 2) * 7;
      for (let i = 0; i < tableNum; i++) {
        _html +=
          ' <li class="sign-date"><div class="signin-icon"></div><i></i></li>';
      }
      $dateBox.html(_html);
      let $dateLi = $dateBox.find("li");
      for (let i = 0; i < totalDay; i++) {
        $dateLi.eq(i + monthFirst).addClass("date" + parseInt(i + 1));
        let index = i + 1;
        if (dayData.indexOf(index) != -1) {
          $dateLi.eq(i + monthFirst).addClass("signIn-cls");
        }
      }
      let times = myDate.getDate();
      if (dayData.indexOf(times) != -1) {
      } else {
        $$(".date" + times).addClass("able-qiandao");

        $$(".able-qiandao").click(function() {
          //$scope.signIn();
          $$(".date" + times).removeClass("able-qiandao");
        });
      }
    }
  },
  created() {
    this.userInfo();
    this.signInDetailDay(false);
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
</style>
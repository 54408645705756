<template>
  <f7-page no-toolbar no-swipeback name="invite-friends" class="page-messgae-center">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="$f7router.back()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("invite_overview_0001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="content">
      <div class="info">
        <div class="fl">
          <div><img src="../../../static/images/yt999/home/ico/ZZicon/Frame1.png" alt=""
              style="height: 28px;"><span>{{ $t("invite_overview_0002") }}</span></div>
          <div>{{ totalReferrerDownlineCount }}</div>
        </div>
        <div class="fl">
          <div><img src="../../../static/images/yt999/home/ico/ZZicon/Frame2.png" alt=""
              style="height: 28px;"><span>{{ $t("invite_overview_0004") }}</span></div>
          <div>{{ totalReferrerSubPeople }}</div>
        </div>
        <div class="fl">
          <div><img src="../../../static/images/yt999/home/ico/ZZicon/Frame3.png" alt=""
              style="height: 28px;"><span>{{ $t("invite_overview_0005") }}</span></div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ totalVIPLevelUpSubAmount | formatCurrency2}}</div>
        </div>
        <!-- <div class="fl" style="border: none;">
          <div><img src="../../../static/images/yt999/home/ico/ZZicon/Frame4.png" alt=""
              style="height: 28px;"><span>{{ $t("invite_overview_0006") }}</span></div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ totalFinancialProductSubAmount }}</div>
        </div> -->
      </div>

      <div class="info uninfo">
        <div class="fl">
          <div>{{ $t("invite_overview_0007") }}</div>
          <div>{{ referrerinfoList["TotalReferrerDownlineCount_L1"] }}</div>
        </div>
        <div class="fl">
          <div>{{ $t("invite_overview_0008") }}</div>
          <div>{{ referrerinfoList["TotalVIPLevelUpSubCount_L1"] }}</div>
        </div>
        <div class="fl" style="border-bottom: 1px solid #ededed;">
          <div>{{ $t("invite_overview_0009") }}</div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ referrerinfoList["TotalVIPLevelUpSubAmount_L1"] | formatCurrency2}}</div>
        </div>
        <!-- <div class="fl" style="border-bottom: 1px solid #ededed;">
          <div class="enUs_fontSize_12px">{{ $t("invite_overview_0010") }}</div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ referrerinfoList["TotalFinancialProductSubAmount_L1"] }}</div>
        </div> -->

        <div class="fl">
          <div>{{ $t("invite_overview_0011") }}</div>
          <div>{{ referrerinfoList["TotalReferrerDownlineCount_L2"] }}</div>
        </div>
        <div class="fl">
          <div>{{ $t("invite_overview_0012") }}</div>
          <div>{{ referrerinfoList["TotalVIPLevelUpSubCount_L2"] }}</div>
        </div>
        <div class="fl" style="border-bottom: 1px solid #ededed;">
          <div>{{ $t("invite_overview_0013") }}</div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ referrerinfoList["TotalVIPLevelUpSubAmount_L2"] | formatCurrency2}}</div>
        </div>
        <!-- <div class="fl" style="border-bottom: 1px solid #ededed;">
          <div class="enUs_fontSize_12px">{{ $t("invite_overview_0014") }}</div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ referrerinfoList["TotalFinancialProductSubAmount_L2"] }}</div>
        </div> -->
        
        <div class="fl">
          <div>{{ $t("invite_overview_0015") }}</div>
          <div>{{ referrerinfoList["TotalReferrerDownlineCount_L3"] }}</div>
        </div>
        <div class="fl">
          <div>{{ $t("invite_overview_0016") }}</div>
          <div>{{ referrerinfoList["TotalVIPLevelUpSubCount_L3"] }}</div>
        </div>
        <div class="fl">
          <div>{{ $t("invite_overview_0017") }}</div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ referrerinfoList["TotalVIPLevelUpSubAmount_L3"] | formatCurrency2}}</div>
        </div>
        <!-- <div class="fl" style="border-bottom: 1px solid #ededed;">
          <div class="enUs_fontSize_12px">{{ $t("invite_overview_0018") }}</div>
          <div><span class="symbol">{{ currencySymbol }}</span>{{ referrerinfoList["TotalFinancialProductSubAmount_L3"] }}</div>
        </div> -->
      </div>

      <div>
        <p style="font-size: 16px;font-weight: bold;color: var(--f7-THEME-color);">{{ $t("invite_overview_0019") }}：{{ userInfo.VipLevelName }}</p>
        <p>{{ $t("invite_overview_0020") }}</p>
        <p><a href="" @click="$f7router.navigate(`/member-center/vip/${userInfo.VipLevel}/`)" style="text-decoration: underline;">{{ $t("invite_overview_0021") }}</a>
        </p>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getCookie, getStore } from "@/config/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getMsgSystemList, getUserNewsList, alreadyRead,referrerinfo  } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: '',
      readActiveIndex: 0,
      referrerinfoList: [],
      totalReferrerDownlineCount: 0,
      totalReferrerSubPeople: 0,
      totalVIPLevelUpSubAmount: 0,
      totalFinancialProductSubAmount: 0
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo", "getLoginInfo", "getLoginState"])
  },
  methods: {
    getreferrerinfo() {
      const self = this;
      referrerinfo().then((data) => {
        if (data.Code == 'NoError') {
          self.referrerinfoList = data.Data;
          self.totalReferrerDownlineCount = data.Data["TotalReferrerDownlineCount_L1"] + data.Data["TotalReferrerDownlineCount_L2"] + data.Data["TotalReferrerDownlineCount_L3"];
          self.totalReferrerSubPeople = data.Data["TotalVIPLevelUpSubCount_L1"] + data.Data["TotalVIPLevelUpSubCount_L2"] + data.Data["TotalVIPLevelUpSubCount_L3"];
          self.totalVIPLevelUpSubAmount = data.Data["TotalVIPLevelUpSubAmount_L1"] + data.Data["TotalVIPLevelUpSubAmount_L2"] + data.Data["TotalVIPLevelUpSubAmount_L3"];
          self.totalFinancialProductSubAmount = data.Data["TotalFinancialProductSubAmount_L1"] + data.Data["TotalFinancialProductSubAmount_L2"] + data.Data["TotalFinancialProductSubAmount_L3"];
        }
      });
    },
    backMemberCenter() {
      this.$f7router.navigate("/member-center/invite-friends/");
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    },
    // 购买弹窗
    logout() {
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      self.$f7.dialog.create({
        title: this.$t(""),
        text: this.$t("member_center_0055"),
        cssClass: "dialog-logout",
        buttons: [
          {
            text: this.$t("common_0001"),
            cssClass: "dialog-ok",
            onClick: () => {
              self.$f7.$(".dialog-backdrop").css("background", "transparent");

            },
          },
        ],
      }).open();
    },
  },
  created() {
    this.currencySymbol = getStore('currencySymbol');
    this.getreferrerinfo();
  },
  mounted() { },
};
</script>
<style lang="less" scoped>
.content {
  width: 90%;
  margin: 10px auto;

  .info {
    border-radius: 10px;
    border: 1px solid var(--f7-THEME-color2);
    padding: 10px;
    margin-bottom: 10px;

    .fl {
      display: flex;
      border-bottom: 1px solid #ededed;
      position: relative;

      div {
        
        height: 40px;
        line-height: 40px;
      }
      div:nth-child(1) {
        display: flex;
        width: 75%;
      }

      div:nth-child(2) {
        width: 25%;
        text-align: right;
        font-weight: bold;
        font-size: 16px;
      }

      img {
        margin: 6px 10px 0 0;
      }

      // span {
      //   position: absolute;
      //   top: 0;
      //   left: 38px;
      // }
    }
  }

  .uninfo {
    .fl {
      border: none;

      div:nth-child(2) {
        font-weight: 0;
        font-size: 14px;
      }
    }

  }

  .upbtn {
    background: #da291c;
    color: white;
    height: 37px;
    line-height: 37px;
    width: 80%;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
  }
}
</style>

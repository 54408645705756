import { render, staticRenderFns } from "./activities-review.vue?vue&type=template&id=eed94204&scoped=true"
import script from "./activities-review.vue?vue&type=script&lang=js"
export * from "./activities-review.vue?vue&type=script&lang=js"
import style0 from "./activities-review.vue?vue&type=style&index=0&id=eed94204&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eed94204",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./change-login-pwd.vue?vue&type=template&id=bc82ee44&scoped=true"
import script from "./change-login-pwd.vue?vue&type=script&lang=js"
export * from "./change-login-pwd.vue?vue&type=script&lang=js"
import style0 from "./change-login-pwd.vue?vue&type=style&index=0&id=bc82ee44&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc82ee44",
  null
  
)

export default component.exports

import { getInfo, getUserInfo } from '../axios/api';
import { SETLOGINSTATE, SETUSERINFO } from './mutation-types'
import { getCookie, setStore, getStore } from '../config/utils'
import common from '@/service/common'
export default {
  saveLoginState({
    commit,
    state
  }) {
    let zz = !!getCookie('zz');
    commit(SETLOGINSTATE, zz);
  },
  getUserInfo({ commit }) {
    let localUserInfo = getStore('userInfo')
    if (localUserInfo) {
      commit(SETUSERINFO, localUserInfo)
    }
    getUserInfo().then(data => {
      if (data.Code === "NoError") {
        let vipLevelCode = common.getLevelCode(data.Data.VipLevelName);
        let userInfo = { ...data.Data, vipLevelCode }
        setStore('userInfo', userInfo)
        setStore('isDefaultVIPLevel', userInfo.IsDefaultVIPLevel)
        commit(SETUSERINFO, userInfo)
      }
    });
  }
}
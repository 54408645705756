<template>
  <f7-page no-toolbar no-swipeback name="invite-friends" class="page-messgae-center" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="$f7router.back()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("friends_detail_0001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="selectcon">
      <div class="custom-select">
        <select v-model="referrerLevel">
          <option :value="0">{{ $t("friends_detail_0002") }}</option>
          <option :value="1">{{ $t("friends_detail_0003") }}</option>
          <option :value="2">{{ $t("friends_detail_0004") }}</option>
          <option :value="3">{{ $t("friends_detail_0005") }}</option>
        </select>
      </div>
      <div class="custom-select">
        <select v-model="dateRangeType">
          <option :value="0">{{ $t("friends_detail_0006") }}</option>
          <option :value="3">{{ $t("friends_detail_0007") }}</option>
          <option :value="13">{{ $t("friends_detail_0008") }}</option>
        </select>
      </div>
    </div>

    <div class="content">
      <div class="info" v-for="item in userNameReferrerrebatesgetList" :key="item.index">
        <div class="fl">
          <div>{{ $t("friends_detail_0009") }}</div>
          <div>{{ item.UserName.split(".")[1] }}</div>
        </div>
        <div class="fl">
          <div style="width: 35%">{{ $t("friends_detail_0012") }}：</div>
          <div v-if="item.IsDefault" style="width: 65%" class="datesty">{{ item.VIPName }}</div>
          <div v-else style="width: 65%; color: blue" class="datesty">{{ item.VIPName }}</div>
        </div>
        <div class="fl">
          <div style="width: 35%">{{ $t("friends_detail_0010") }}</div>
          <div style="width: 65%" class="datesty">{{ item.InsertTS | time_local }}</div> 
        </div>
        <div class="fl">
          <div style="width: 35%">{{ $t("friends_detail_0014") }}：</div>
          <div v-if="item.ReferrerLevelName == ''" style="width: 65%" class="datesty">- -</div>
          <div v-else style="width: 65%" class="datesty">{{ item.ReferrerLevelName }}</div>
        </div>
        <!-- <div class="fl">
          <div style="width: 35%">{{ $t("friends_detail_0013") }}：</div>
          <div style="width: 65%" class="datesty">{{ item.FirstVIPActiveTS | time_local }}</div>
        </div> -->
        <!-- <div class="fl" style="border: none;">
          <div >{{ $t("friends_detail_0011") }}</div>
          <div ><span class="symbol">{{ currencySymbol }}</span>{{ item.BaseAmount | formatCurrency}}</div>
        </div> -->
      </div>
    </div>
  </f7-page>
</template>
<script>
import moment from "moment";
import { getCookie, getStore } from "@/config/utils";
import { userNameInviteByMemberGet } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      currencySymbol: "",
      readActiveIndex: 0,
      userNameReferrerrebatesgetList: [],
      dateRangeType: 0, // 0:所有，3本月，13上月。
      referrerLevel: 0,

      //分页
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {
    dateRangeType(newValue, oldValue) {
      this.userNameReferrerrebatesgetList = [];
      this.getUserNameReferrerrebatesget();
    },
    referrerLevel(newValue, oldValue) {
      this.userNameReferrerrebatesgetList = [];
      this.getUserNameReferrerrebatesget();
    },
  },
  computed: {},
  methods: {
    getUserNameReferrerrebatesget() {
      const self = this;
      let queryParam = {};
      (queryParam.DateRangeType = self.dateRangeType), (queryParam.ReferrerRebatesType = "VIPLevelUpRebates"), (queryParam.ReferrerLevel = self.referrerLevel);
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 20;
      const params = { data: JSON.stringify(queryParam) };
      userNameInviteByMemberGet(params).then((data) => {
        if (data.Code === "NoError") {
          self.userNameReferrerrebatesgetList = self.userNameReferrerrebatesgetList.concat(data.Data[0]);
          self.showData = self.userNameReferrerrebatesgetList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.userNameReferrerrebatesgetList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },

    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.financialList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.getUserNameReferrerrebatesget();
        self.allowInfinite = true;
      }, 1000);
    },
    ///
    backMemberCenter() {
      this.$f7router.navigate("/member-center/invite-friends/");
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    },
  },
  created() {
    this.currencySymbol = getStore("currencySymbol");
    this.getUserNameReferrerrebatesget();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.content {
  width: 90%;
  margin: 10px auto;

  .info {
    border-radius: 10px;
    border: 1px solid var(--f7-THEME-color2);
    padding: 0 10px;
    margin-bottom: 10px;

    .fl {
      display: flex;
      border-bottom: 1px solid #ededed;
      position: relative;

      div {
        height: 40px;
        line-height: 40px;
      }
      div:nth-child(1) {
        width: 50%;
      }

      div:nth-child(2) {
        width: 50%;
        text-align: right;
      }

      img {
        margin: 6px 10px 0 0;
      }

      // span {
      //   position: absolute;
      //   top: 0;
      //   left: 38px;
      // }

      .datesty {
        font-size: 12px;
        color: #969799;
      }
    }
  }
}

.selectcon {
  background: var(--f7-THEME-color2);
  padding: 5px 67px 0px 17px;
  display: flex;
  justify-content: space-between;
  .dateTime{
    div{
      background: #fff;
      border: 1px solid var(--f7-THEME-color);
      border-radius: 5px;
      margin-bottom: 3px;
    }
  }
}

.custom-select {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 72px;
  height: 40px;
}

.custom-select select {
  width: 120px;
  height: 32px;
  padding: 7px 6px 5px 10px;
  font-size: 12px;
  border: 1px solid var(--f7-THEME-color);
  border-radius: 5px;
  background: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #000;
  outline: none;
}

.custom-select::after {
  content: "▼";
  font-size: 12px;
  color: var(--f7-THEME-color);
  position: absolute;
  right: -40px;
  top: 17px;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-select select:focus {
  border-color: var(--f7-THEME-color);
  /* Darker red border on focus */
}
</style>

<template>
  <f7-page name="gameList" class="page-gamelist" no-swipeback>
    <!-- <f7-page name="gameList" class="page-gamelist" no-toolbar no-swipeback> -->
    <!-- <f7-navbar title="电子游艺" back-link :no-hariline="true"></f7-navbar> -->
    <f7-navbar :no-hariline="true">
      <f7-nav-left>
        <f7-link @click="$f7router.navigate('/')" class="icon-only">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('egame_list_0001') }}</f7-nav-title>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".game-name"
        search-item=".item-game"
        :disable-button-text="$t('common_0002')"
        :placeholder="$t('egame_list_0003')"
      ></f7-searchbar>
    </f7-navbar>
    <f7-block class="block-egame">
      <!-- <img
        class="lazy lazy-fade-in"
        data-src="../../static/images/yt999/egame-banner.jpg"
        alt
        width="100%"
      > -->
      <f7-swiper pagination next-button prev-button data-lazy="{'enabled': true}" :params="swiperParams" class="swiper-banner">
        <f7-swiper-slide v-for="index in 12" :key="index">
          <img 
            :src="`../../static/images/yt999/egameSwiper/${String(index).padStart(2, 0)}_${$f7.params.currLang}.jpg`" 
            :onerror="`this.onerror=''; src='../../static/images/yt999/egameSwiper/${String(index).padStart(2, 0)}_enUS.jpg'`"
            class="swiper-lazy"
          >
          <div class="preloader swiper-lazy-preloader"></div>
        </f7-swiper-slide>
      </f7-swiper>
    </f7-block>
    <f7-block class="block-filter">
      <f7-row no-gap>
        <f7-col width="45">
          <div class="list no-hairlines-md">
            <ul>
              <li>
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="text" readonly="readonly" id="picker-gameType" ref="gameType"/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </f7-col>
        <f7-col width="45">
          <div class="list no-hairlines-md">
            <ul>
              <li>
                <div class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="text" readonly="readonly" id="picker-classType" ref="classType"/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </f7-col>
        <f7-col width="10" class="search">
          <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-ios="f7:search" icon-md="material:search" icon-aurora="f7:search"></f7-link>
        </f7-col>
      </f7-row>
    </f7-block>
    <f7-block class="game-list">
      <f7-list class="searchbar-not-found">
        <f7-list-item :title="$t('common_have_no_data')"></f7-list-item>
      </f7-list>
      <f7-row no-gap class="search-list searchbar-found">
        <f7-col class="item-game" width="25" v-for="item in loadList" :key="item.GameId" @click="playGame(item)">
          <img
            v-if="GameImagesURL==''" 
            class="lazy lazy-fade-in"
            :data-src="`../../static/images/${selectType.key}/${item.GameCode.toLowerCase()}.jpg`"
          >
          <img
            v-if="GameImagesURL!=''" 
            class="lazy lazy-fade-in"
            :data-src="`${GameImagesURL}static/images/${selectType.key}/${item.GameCode.toLowerCase()}_enUS.jpg`"
          >
          <p class="text-center game-name">{{item.GameName}}</p>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import {
  getGameListp,
  getGameClassList,
  getClassChildList,
  getGameEntry,
} from "../../axios/api";
import api from "../../config/api";
import http from "../../axios/http";
import { getCookie, setStore, getStore } from '../../config/utils';
import common from '../../service/common';

export default {
  components: {},
  props: {},
  data() {
    return {
      egameList: [],
      gameClassList: [],
      selectType: {
        key: "",
        Name: ""
      },
      selectClassType: {
        key: "",
        Name: ""
      },
      loadList: [],
      GameImagesURL: '',

      swiperParams: {
        speed: 700,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 8000
        },
        lazy: {
          loadPrevNext: true
        }
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    getEgameList() {
      const self = this;
      if(getStore("GameImagesURL") && getStore("GameImagesURL") != ''){
        self.GameImagesURL = getStore("GameImagesURL");
      }
      getGameListp().then(data => {
        if (data.Code === "NoError") {
          self.egameList = data.Data.filter(item => {
            return item.Type === "Egames";
          });
          const defaultGame = self.egameList.filter(item => {
            return item.Id === parseInt(self.$f7route.params.Id);
          });
          self.selectType.key = defaultGame[0].Id;
          self.selectType.Name = defaultGame[0].Name;
          self.$refs.gameType.value = self.selectType.Name;
          self.getGameClassList(self.selectType.key);

          self.initPicker();
        }
      });
    },
    getGameClassList(id) {
      const self = this;
      const param = { data: JSON.stringify({ ParentClassId: id }) };
      getGameClassList(param).then(data => {
        if (data.Code === "NoError") {
          self.gameClassList = data.Data;
          self.gameClassList.unshift(
            {
              Code: "",
              Id: 0,
              Hot: false,
              Name: this.$t('egame_list_0005'),
              OrderId: 0,
              ProviderId: 0,
              Type: "0"
            },
            {
              Code: "",
              Id: -1,
              Hot: true,
              Name: this.$t('egame_list_0006'),
              OrderId: 0,
              ProviderId: -1,
              Type: "0"
            }
          );
          self.selectClassType.key = self.gameClassList[0].Id;
          self.selectClassType.Name = self.gameClassList[0].Name;
          self.$refs.classType.value = self.selectClassType.Name;
          self.getClassChildList(self.selectType.key, self.selectClassType.key);
          if(self.pickerClassType)self.pickerClassType.destroy();
          self.initPickerClass();
        }
      });
    },
    initPicker(){
      const self = this;
      let values = [];
      let keys = [];
      self.egameList.forEach(item => {
        keys.push(item.Id);
        values.push(item.Name);
      });
      self.pickerGameType = self.$f7.picker.create({
        inputEl: '#picker-gameType',
        rotateEffect: true,
        routableModals: false,
        //value:[self.selectType.Name],
        formatValue(values, displayValues) {
          return displayValues[0];
        },
        cols: [
          {
            textAlign: 'center',
            values: keys,
            displayValues: values,
            onChange(picker, value, displayValue) {
              self.selectType.key = value;
              self.selectType.Name = displayValue;
            }
          },
        ],
        on: {
          closed: function () {
            self.getGameClassList(self.selectType.key);
          }
        }
      });
    },
    initPickerClass(){
      const self = this;
      let values = [];
      let keys = [];
      self.gameClassList.forEach(item => {
        keys.push(item.Id);
        values.push(item.Name);
      });
      self.pickerClassType = self.$f7.picker.create({
        inputEl: '#picker-classType',
        rotateEffect: true,
        routableModals: false,
        //value:[self.selectClassType.Name],
        formatValue(values, displayValues) {
          return displayValues[0];
        },
        cols: [
          {
            textAlign: 'center',
            values: keys,
            displayValues: values,
            onChange(picker, value, displayValue) {
              self.selectClassType.key = value;
              self.selectClassType.Name = displayValue;
            }
          },
        ],
        on: {
          closed: function () {
              self.getClassChildList(self.selectType.key, self.selectClassType.key);
          }
        }
      });
    },
    getClassChildList(type, classType) {
      const self = this;
      const param = {
        data: JSON.stringify({
          ParentClassId: type,
          ChildClassId: classType == -1 ? 0 : classType,
          IsHot: classType == -1,
          Platform: 2
        })
      };
      getClassChildList(param).then(data => {
        if (data.Code === "NoError") {
          self.loadList = data.Data;
        }
      });
    },
    playGame(item) { 
      const self = this;
      const router = self.$f7router;
      if(getCookie('zz')) {
        const params = {
          ProviderId: item.ProviderId,
          gameCode: item.ProviderGameCode,
          Language: "zhCN",
          Platform: 2,
          Name: item.GameName,
          Host: window.location.origin,
        }
        const d = { data :JSON.stringify(params) };
        const isApp = getStore('device') && getStore('device') === 'App';
        if(!isApp  && !common.getBrowser.isSougou) {
          setStore('playGameParam', JSON.stringify(params));
          this.common.redirectURL(`/#/loading-game/`);
          return;
        }
        
        let promtLoadingGame = self.$f7.dialog.create({
          title: '',
          // text: `正在载入&lt;${params.Name}&gt;...`,
          text: this.$t('common_game_loading_format').Format(item.GameName),
          cssClass: "dialog-preloadgame",
        }).open();
        self.$f7.params.dialog.closeByBackdropClick = false;
        //self.$f7.$('.dialog-backdrop').css('background-color','transparent');

        getGameEntry(d).then(data => {
          self.$f7.params.dialog.closeByBackdropClick = true;
          //self.$f7.$('.dialog-backdrop').css('background-color','rgba(0, 0, 0, 0.74)');
          //promtLoadingGame.close();

          if(data.Code === 'NoError' && data.Data) {
            if(params.ProviderId == 512) {
              const entryData = JSON.parse(data.Data);
              let encode = btoa(encodeURI(data.Data));
              window.location.href = entryData.GameEntryURL + '/index.html?info=' + encode;
            } else { 
              window.location.href = data.Data;
            }
          } else {
            promtLoadingGame.close()
            let content = (data.Code === 'ProviderMaintain' && data.Data && data.Data.length >= 2)
                                ? this.$t('common_game_maintenance_from') + data.Data[0].replace('T', ' ') + this.$t('common_game_maintenance_to') + data.Data[1].replace('T', ' ') : this.$t('common_loading_game_failed');
            
            self.promptMaintain(content);
          }
        });
      } else {
        let promtLoadingGame = self.$f7.dialog.create({
          title: '',
          text: this.$t('common_need_login'),
          cssClass: "dialog-preloadgame",
        }).open();   
        setTimeout(function(){ 
          promtLoadingGame.close(); }
          , 2000
          );
        //router.navigate('/login/');
      }
    },
    
    promptMaintain(content){
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false;
      //self.$f7.$('.dialog-backdrop').css('background-color','transparent');
      let promptMaintain = self.$f7.dialog.create({
        title: '',
        text: content,
        cssClass: "dialog-game",
      }).open();
      setTimeout(() => {
        self.$f7.params.dialog.closeByBackdropClick = true;
        //self.$f7.$('.dialog-backdrop').css('background-color','rgba(0, 0, 0, 0.74)');
        promptMaintain.close();
      }, 2000);
    },
  },
  created() {
    const self = this;
    const routeParam = self.$f7route.params.Id;
    self.getEgameList();
  },
  mounted() {},
  updated() {
    this.$f7.lazy.create(this.$el);
  }
};
</script>
<style lang="less" scoped>
.page-gamelist {
  .block-egame {
    margin: var(--f7-margin-size-0);
    padding: var(--f7-padding-size-0);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .block-filter {
    background: #636363;
    color: var(--f7-color-white);
    border-bottom: 1px solid #636363;
    margin: var(--f7-margin-size-0);
    padding: var(--f7-padding-size-0);
    width: 100%;
    .list {
      margin: var(--f7-margin-size-0);
      ul {
        background-color: transparent;
        position: relative;
        .item-content {
          .item-inner {
            input {
              color: var(--f7-color-white);
            }
          }
          .item-inner:before {
            font-family: framework7-core-icons;
            font-weight: 400;
            
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            -moz-font-feature-settings: "liga";
            font-feature-settings: "liga";
            text-align: center;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            width: 8px;
            height: 14px;
            margin-top: -7px;
            font-size: 20px;
            font-size: var(--f7-list-chevron-icon-font-size);
            line-height: 14px;
            color: #c7c7cc;
            color: var(--f7-list-chevron-icon-color);
            pointer-events: none;
            right: calc(var(--f7-list-item-padding-horizontal) + 0px);
            right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
            content: "chevron_down";
          }
        }
      }
      ul:before {
        height: 0;
      }
      ul:after {
        right: 0;
        width: 1px;
        height: 25px;
        background-color: #033a58;
        bottom: 10px;
        transform: none;
        left: auto;
      }
    }

    .search {
      a.link{
        line-height: 44px;
        height: 44px;
        color: #fff;
      }
    }
  }
  .game-list {
    margin: 8px var(--f7-margin-size-0);
    padding: var(--f7-margin-size-0);
    .search-list {
      justify-content:flex-start;
      .item-game {
        margin: 0 3px;
      }
    }
    img {
      width: 100%;
      border-radius: 4px;
    }
    .game-name {
      margin: var(--f7-px-1) 0 var(--f7-px-8);
      color: #464646;
    }
    .row.no-gap {
      --f7-grid-gap: 8px;
    }
    .searchbar-not-found {
      margin: 0 3px;
    }
  }
}
</style>
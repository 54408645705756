<template>
  <f7-page no-toolbar no-swipeback name="deposit-search" class="page-deposit-search">
    <f7-navbar :title="$t('deposit_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-search">
      <div class="form">
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0029") }}</div>
            <div class="item-input-wrap">
              <input style="text-align: left" type="date" id="dateF" v-model="BeginDay" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0030") }}</div>
            <div class="item-input-wrap">
              <input style="text-align: left" type="date" id="dateT" v-model="EndDay" />
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-search">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="searchSubmit">{{ $t("common_0028") }}</a>
        </li>
      </ul>
    </div>
    <div class="curr-time">{{ utcMinusFourTime | time_local }}</div>
  </f7-page>
</template>

<script>
import { getCookie, getStore } from "../../../config/utils";
import moment from "moment";

export default {
  components: {},
  props: {},
  data() {
    return {
      intervalId: null,
      utcMinusFourTime: "",
      queryParam: {
        BeginDate: "",
        EndDate: "",
      },
      BeginDay: "",
      EndDay: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    updateTime() {
      var  timeZome = 0;
      timeZome = getStore("TimeZome");
      const date = new Date(); // Get current date/time
      const offset = timeZome * 60; // Offset in minutes (UTC-4 is 4 hours behind UTC)
      const utcMinusFourDate = new Date(date.getTime() + offset * 60000);
      const formattedTime = this.formatDate(utcMinusFourDate);

      this.utcMinusFourTime = formattedTime + " (UTC-3)";
    },
    formatDate(date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "UTC",
      };

      return date.toLocaleString("en-US", options).replace(",", "");
    },
    searchSubmit() {
      this.queryParam.BeginDate = this.BeginDay + " 00:00:00";
      this.queryParam.EndDate = this.EndDay + " 23:59:59";
      if (new Date(this.queryParam.BeginDate).getTime() > new Date(this.queryParam.EndDate).getTime()) {
        this.$f7.dialog.alert(this.$t("common_0031"), this.$t("common_0004"));
        return false;
      }
      this.$f7router.navigate(`/member-center/deposit-records/${JSON.stringify(this.queryParam)}/`);
    },
    getUTCMinus4Date(diffDays) {
      // let now = new Date();
      // let utcTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // let utcMinus4 = new Date(utcTime.getTime() - 4 * 60 * 60 * 1000);
      // utcMinus4.setDate(utcMinus4.getDate() + diffDays);
      // let year = utcMinus4.getFullYear();
      // let month = utcMinus4.getMonth() + 1;
      // let day = utcMinus4.getDate();
      // return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

      const now = new Date();
      now.setDate(now.getDate() + diffDays);
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  created() {
    this.BeginDay = this.getUTCMinus4Date(-3);
    this.EndDay = this.getUTCMinus4Date(0);
  },
  mounted() {
    this.updateTime(); // Call updateTime() when the component is mounted
    this.intervalId = setInterval(this.updateTime, 1000); // Update time every second
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style lang="less" scoped>
.page-deposit-search {
  .block-search {
    margin: 15px 10px;
    padding: 0;
    .form {
      .form-item {
        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }
          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;
            input {
              width: 100%;
            }
          }
          .smart-select .item-after {
            max-width: unset;
          }
        }
      }
    }
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 8px;
              width: 25%;
            }
            input {
              color: #464646;
              font-size: 14px;
            }
            input.input-title {
              color: #464646;
              font-size: 16px;
            }
          }
          .item-inner:after {
            height: 0;
          }
        }
        .item-title {
          color: #464646;
          font-size: 16px;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-search {
    margin: 10px 12px;

    ul {
      background: var(--f7-button-bg-color);
      li {
        a {
          color: var(--f7-button-text-color);
        }
      }
    }
  }
  .curr-time {
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
    color: #404040;
  }
}
</style>

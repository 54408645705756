<template>
  <f7-page no-toolbar no-swipeback hide-toolbar-on-scroll name="transfer" class="page-transfer">
    <f7-navbar :title="$t('safe_0001')" back-link :no-hariline="true"></f7-navbar>
    <div class="content">
        <div class="tip">{{ $t('safe_0002') }}</div>
        <div class="password">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="keyboard">
            <div @click="onkeyboard(1)">1</div>
            <div @click="onkeyboard(2)">2</div>
            <div @click="onkeyboard(3)">3</div>
            <div @click="onkeyboard(4)">4</div>
            <div @click="onkeyboard(5)">5</div>
            <div @click="onkeyboard(6)">6</div>
            <div @click="onkeyboard(7)">7</div>
            <div @click="onkeyboard(8)">8</div>
            <div @click="onkeyboard(9)">9</div>
            <div @click="onkeyboard(0)">0</div>
            <div class="delbackground" @click="delkeyboard()"  >{{ $t('safe_0003') }}</div>
            <div class="confirmbackground" @click="confirmButton()">{{ $t('safe_0004') }}</div>
        </div>
      </div>
  </f7-page>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import preloader from "../../template/preloader";
import {checksecuritypass} from "../../../axios/api";

export default {
  components: {
    preloader
    
  },
  props: {},
  data() {
    return {
      kayboardArr: [],
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['getsecurityCode']),
  },
  methods: {
    ...mapMutations(["SETSECURITYCODE"]),
    onkeyboard (num) {
        const self = this;
        if(self.kayboardArr.length < 6){
            self.kayboardArr.push(num);
            self.confirmkey();
        }
    },
    confirmButton (){
        const self = this;
        if(self.kayboardArr.length < 6){
            self.$f7.dialog.alert(this.$t('safe_0002'), this.$t('common_0004'));
            return false;
        }else{
            var password = self.kayboardArr.join('');
            self.gochecksecuritypass(password);
        }
    },
    confirmkey (){
        const self = this;
        const $$ = self.$f7.$;
        $$(".password div:nth-child("+self.kayboardArr.length+")").html("*");
    },
    delkeyboard (){
        const self = this;
        const $$ = self.$f7.$;
        if(self.kayboardArr.length > 0){
            $$(".password div:nth-child("+self.kayboardArr.length+")").html("");
            self.kayboardArr.pop();
        }
    },

    //检测安全密码是否正确
    gochecksecuritypass (password){
      const self = this;
      let gosafe = self.$f7.dialog.create({
        title: '',
        text: this.$t('safe_0005'),
        cssClass: "dialog-preloadgame",
      }).open();
      var param = {
        data: JSON.stringify({
          "SecurePassword": password
        })
      }
      checksecuritypass(param).then(data => {
        if(data.Code === 'NoError'){
          self.SETSECURITYCODE(password);
          setTimeout(()=>{
            gosafe.close();
            self.$f7router.navigate("/member-center/safeBox/");
          },2000);
        }else{
          setTimeout(()=>{
            gosafe.close();
            self.$f7.dialog.alert(this.$t('safe_0006'), this.$t('common_0004'));
          },2000);
          
        }
      });
    }
    
  },
  created() {
    
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
  .content{
    margin: 0 auto;
    color: #c6a47a;
    padding: 10px;
    .tip{
      font-size: 16px;
      padding: 15px 0;
    }
    .password{
      font-size: 0px;
      >div{
        width: 15%;
        height: 60px;
        margin-right: 2%;
        font-size: 50px;
        border-bottom: 1px solid #c6a47a;
        display: inline-block;
        line-height: 60px;
        text-align: center;
        vertical-align: top;
      }
      >div:nth-child(6){
        margin-right: 0px;
      }
    }
    .keyboard{
      font-size: 0px;
      >div{
        width: 30%;
        height: 60px;
        font-size: 30px;
        line-height: 60px;
        margin: 15px 4% 0 0;
        display: inline-block;
        text-align: center;
        background: #000432;
        border: 1px solid #736048;
      }
      >div:nth-child(3n){
        margin-right:0;
      }
      .delbackground{
        background: #561f0d;
      }
      .confirmbackground{
        background: #0a440d;
      }
    }
  }
</style>
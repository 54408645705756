<template>
  <f7-page no-toolbar no-swipeback name="direct-search" class="page-direct-search">
    <f7-navbar :title="$t('referrer_directReport_search_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-search">
      <div class="form">
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("referrer_directReport_search_0002") }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly class="input-title" value="" v-model="dirctNumber" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("referrer_directReport_search_0003") }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly class="input-title" value="" v-model="todayTotalPerformance" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0029") }}</div>
            <div class="item-input-wrap">
              <input type="text" id="dateF" readonly v-model="queryParam.BeginDate" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0030") }}</div>
            <div class="item-input-wrap">
              <input type="text" id="dateT" readonly v-model="queryParam.EndDate" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("referrer_directReport_records_0002") }}</div>
            <div class="item-input-wrap">
              <input type="text" class="input-title" value="" v-model="queryParam.UserNameDownline" />
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-search">
      <ul>
        <li>
          <a href="#" class="list-button" @click="searchSubmit">{{ $t("common_0028") }}</a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>

<script>
import moment from "moment";
import { referrerdownlinememberlist } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      queryParam: {
        BeginDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        EndDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        UserNameDownline: "",
      },
      dirctNumber: 0,
      todayTotalPerformance: 0,
    };
  },
  watch: {},
  computed: {
    dateF() {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() - 8);
      let year = date2.getFullYear();
      let month = date2.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = date2.getDate();
      return `${year}-${month}-${day} 00:00:00`;
    },
  },
  methods: {
    initPickerDateF() {
      // Inline date-time
      const self = this;
      const app = self.$f7;
      let date1 = new Date();
      const today = new Date(date1);
      today.setDate(date1.getDate() - 8);
      const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      self.pickerInline = app.picker.create({
        inputEl: "#dateF",
        rotateEffect: true,
        routableModals: false,
        value: [today.getFullYear(), month, today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(), "00", "00", "00"],
        formatValue(values, displayValues) {
          let year = values[0];
          let month = values[1];
          let day = values[2];
          return `${year}-${month}-${day} ${values[3]}:${values[4]}:${values[5]}`;
        },
        cols: [
          // Years
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 2010; i <= 2088; i += 1) {
                arr.push(i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Months
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 12; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Days
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 31; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          // Hours
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // Minutes
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // second
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
        ],
        on: {
          change(picker, values, displayValues) {
            const daysInMonth = new Date(picker.value[0], picker.value[1] * 1 + 1, 0).getDate();
            if (values[2] > daysInMonth) {
              //picker.cols[2].setValue(daysInMonth);
            }

            self.queryParam.BeginDate = `${values[0]}-${values[1]}-${values[2]} ${values[3]}:${values[4]}:${values[5]}`;
          },
        },
      });
    },
    initPickerDateT() {
      // Inline date-time
      const self = this;
      const app = self.$f7;
      const today = new Date();
      const month = today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
      self.pickerInline = app.picker.create({
        inputEl: "#dateT",
        rotateEffect: true,
        routableModals: false,
        value: [today.getFullYear(), month, today.getDate(), "23", "59", "59"],
        formatValue(values, displayValues) {
          let year = values[0];
          let month = values[1];
          let day = values[2];
          return `${year}-${month}-${day} ${values[3]}:${values[4]}:${values[5]}`;
        },
        cols: [
          // Years
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 2010; i <= 2088; i += 1) {
                arr.push(i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Months
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 12; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: "-",
          },
          // Days
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 1; i <= 31; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          // Hours
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 23; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // Minutes
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
          {
            divider: true,
            content: ":",
          },
          // second
          {
            values: (function createValues() {
              const arr = [];
              for (let i = 0; i <= 59; i += 1) {
                arr.push(i < 10 ? `0${i}` : i);
              }
              return arr;
            })(),
            textAlign: "left",
          },
        ],
        on: {
          change(picker, values, displayValues) {
            const daysInMonth = new Date(picker.value[0], picker.value[1] * 1 + 1, 0).getDate();
            if (values[2] > daysInMonth) {
              //picker.cols[2].setValue(daysInMonth);
            }
            self.queryParam.EndDate = `${values[0]}-${values[1]}-${values[2]} ${values[3]}:${values[4]}:${values[5]}`;
          },
        },
      });
    },
    searchSubmit() {
      if (new Date(this.queryParam.BeginDate).getTime() > new Date(this.queryParam.EndDate).getTime()) {
        this.$f7.dialog.alert(this.$t("common_0031"), this.$t("common_0004"));
        return false;
      }
      this.$f7router.navigate(`/member-center/referrer-directReport-records/${JSON.stringify(this.queryParam)}/`);
    },
    getreferrerdownlinememberlist() {
      const self = this;
      var data = {
        UserNameDownline: "",
        BeginDate: new Date(this.queryParam.BeginDate).getTime() / 1000,
        EndDate: new Date(this.queryParam.EndDate).getTime() / 1000,
        PageNow: 1,
        PageSize: 10,
      };
      const params = { data: JSON.stringify(data) };
      referrerdownlinememberlist(params).then((data) => {
        if (data.Code === "NoError" && data.Data.length >= 3) {
          self.dirctNumber = data.Data[2];
          self.todayTotalPerformance = data.Data[3];
        }
      });
    },
  },
  created() {
    this.queryParam.BeginDate = this.dateF;
  },
  mounted() {
    this.initPickerDateF();
    this.initPickerDateT();
    this.getreferrerdownlinememberlist();
  },
};
</script>
<style lang="less" scoped>
.page-direct-search {
  .block-search {
    margin: 15px 10px;
    padding: 0;
    .form {
      .form-item {
        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }
          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;
            input {
              width: 100%;
            }
          }
          .smart-select .item-after {
            max-width: unset;
          }
        }
      }
    }
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 12px;
              width: 45%;
              font-size: 12px;
              color: #464646;
            }
            .item-input-wrap {
              font-size: 12px;
              color: #464646;
            }
            input {
              color: #fff;
              font-size: 12px;
              color: #464646;
            }
            input.input-title {
              font-size: 12px;
              color: #464646;
            }
          }
          .item-inner:after {
            right: 10px;
            left: auto;
          }
        }
        .item-title {
          color: #fff;
          font-size: 12px;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-search {
    margin: 10px 12px;
    .list-button {
      color: #fff;
      background-color: var(--f7-THEME-color);
    }
    ul {
      background: var(--f7-button-bg-color);
      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
</style>

<template>
  <f7-page no-toolbar no-swipeback name="change-login-pwd" class="page-change-login-pwd">
    <f7-navbar :title="$t('send_red_packet_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-change-login-pwd">
      <div class="list inline-labels list-change-login-pwd">
        <div class="head">
          <div class="head-2">{{memberBalance|formatCurrency}}</div>
          <div class="head-1">{{$t('send_red_packet_0002')}}</div>
        </div>
        <ul >
          <li>
            <a class="item-link smart-select smart-select-init" data-open-in="sheet">
              <select v-model="type">
                <option value="0" checked="checked" >{{$t('send_red_packet_0008')}}</option>
                <option value="1">{{$t('send_red_packet_0009')}}</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title" style="font-size: 14px;">{{$t('send_red_packet_0003')}}:</div>
                  <div class="item-after" style="color: #000;">{{$t('send_red_packet_0008')}}</div>
                </div>
              </div>
            </a>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label" style="font-size: 14px;">{{$t('send_red_packet_0004')}}:</div>
                <div class="item-input-wrap text-right">
                  <input
                    type="text"
                    :placeholder="$t('send_red_packet_0010')"
                    v-model="redPacket.TotalAmount"
                  >
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label" style="font-size: 14px;">{{$t('send_red_packet_0005')}}:</div>
                <div class="item-input-wrap text-right">
                  <input
                    type="text"
                    :placeholder="$t('send_red_packet_0011')"
                    v-model="redPacket.TotalPacketCount"
                  >
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-label" style="font-size: 14px;">{{$t('send_red_packet_0006')}}:</div>
                <div class="item-input-wrap text-right">
                  <input
                    type="password"
                    :placeholder="$t('send_red_packet_0012')"
                    v-model="redPacket.SecurityPassword"
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </f7-block>
    <div class="list inset button-save">
      <ul>
        <li>
          <a href="#" class="list-button button-single" @click="send()" style="color: white;">{{$t('send_red_packet_0007')}}</a>
        </li>
      </ul>
    </div>

    
    <div class="dialog dialog-Tip" @dialog:closed="messageCloseEvent" @dialog:open="messageOpenEvent">
      <div class="dialog-inner">
        <!-- <div class="dialog-title">
          <h3>{{$t('send_red_packet_0018')}}</h3>
        </div> -->
        <div class="dialog-text">
          <f7-block class="block-message">
            <div class="block-content" >
              <p class="p1"> {{$t('send_red_packet_0019')}}</p>
              <!-- <p class="line"></p> -->
              <p class="p2">{{noErrorTip}}</p>
              <p class="copy-result p">{{copyResult}}</p>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button
                    large
                    class="btn-next border-radius-left"
                    @click="closeTip()"
                  >{{$t('send_red_packet_0021')}}</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button
                    large
                    fill
                    class="btn-close border-radius-right"
                    v-clipboard:copy="noErrorTip"
                    v-clipboard:error="onError"
                    v-clipboard:success="onCopy"
                  >{{$t('send_red_packet_0020')}}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
  </f7-page>
</template>
<script>

import api from '../../../config/api'
import { 
  getMemberBalance,
  createredpacket
} from "../../../axios/api"
import http from '../../../axios/http'
import common from "../../../service/common"

export default {
  components: {},
  props: {},
  data() {
    return {
      memberBalance: 0,
      type: 0,
      redPacket:   {
          "MemberRedPacketType":"FixedAmountRedPacket",
          "TotalAmount":100,
          "TotalPacketCount":1,
          "SecurityPassword":"",
      },
      noErrorTip: '',
      copyResult: '--',
    };
  },
  watch: {},
  computed: {},
  methods: {
    send() {
      const self = this;
      if(self.redPacket.TotalAmount > self.redPacket.memberBalance){
        self.$f7.dialog.alert(self.$i18n.t('send_red_packet_0014'),self.$i18n.t('send_red_packet_0013'));
        return false;
      }
      if(self.redPacket.TotalAmount == '' || self.redPacket.TotalAmount < 0.01){
        self.$f7.dialog.alert(self.$i18n.t('send_red_packet_0015'),self.$i18n.t('send_red_packet_0013'));
        return false;
      }
      if(self.redPacket.TotalPacketCount < 1){
        self.$f7.dialog.alert(self.$i18n.t('send_red_packet_0016'),self.$i18n.t('send_red_packet_0013'));
        return false;
      }
      if(self.redPacket.SecurityPassword == ''){
        self.$f7.dialog.alert(self.$i18n.t('send_red_packet_0017'),self.$i18n.t('send_red_packet_0013'));
        return false;
      }

      if(parseInt(self.type) === 0 ){
        self.redPacket.MemberRedPacketType = 'FixedAmountRedPacket';
      }else if(parseInt(self.type) === 1){
        self.redPacket.MemberRedPacketType = 'LuckyAmountRedPacket';
      }
      const param = {
        data: JSON.stringify(self.redPacket)
      }
      createredpacket(param).then(data => {
        if(data.Code === 'NoError') {
          self.noErrorTip = data.Data;
          self.openTip();
          self.getBalance();
        }else{
          self.$f7.dialog.alert(data.Data,self.$i18n.t('send_red_packet_0013'));
        }
      });

    },
    openTip(){
      const self = this;
      self.$f7.dialog.open('.dialog-Tip');
    },
    closeTip(){
      const self = this;
      self.$f7.dialog.close('.dialog-Tip');
    },
    messageOpenEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
    },
    messageCloseEvent() {
      this.$f7.$(".dialog-backdrop").css("background", "transparent");
    },
    getBalance(){
      const self = this;
      getMemberBalance().then(data => {
        if (data.Code === "NoError") {
          self.memberBalance = data.Data;
        }
      });
    },
    onCopy(e) {
      const self = this;
      if(e.text) {
        self.copyResult=self.$i18n.t('send_red_packet_0022');
        setTimeout(() => {
          self.copyResult='--';
        }, 1000);
      }
    },
    onError(e) {
      console.log(e);
    },
    
  },
  created() {
    const self = this;
    self.type = 0;
    self.getBalance();
  },
  mounted() {
  },
  updated() {}
};
</script>
<style lang="less" scoped>
.page-change-login-pwd {
  --f7-page-toolbar-bottom-offset: 0;
  .block-change-login-pwd {
    margin: 5px 0 10px;
    padding: 0 10px;
    .list-change-login-pwd {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .item-title {
              padding-top: 12px;
              color: #464646;
              width: 36%;
              max-width: none!important;
              font-size: 12px;
            }
            input {
              color: #464646;
              font-size: 12px;
            }
          }
          .item-inner:after {
            right: 10px;
            left: auto;
          }
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-save {
    margin: 10px 12px;

    ul {
      background: var(--f7-THEME-color);
    }
  }
  .head{
    background: var(--f7-THEME-color);
    color: white;
    padding: 10px;
    border-radius: 10px;
    .head-1{
      text-align: center;
      font-size: 12px;
    }
    .head-2{
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .item-title{
    font-size: 14px;
  }
  .item-inner:after {
    right: 10px;
    left: auto;
  }
  .item-after{
    width: 100%;
    font-size: 14px;
  }
}
.dialog-Tip {
    width: 94%;
    margin: 0 3%;
    background-color: white;
    // border: 1px solid rgba(177,177,177,0.58);
    left: 0;

    .dialog-inner {
        padding: 0;
        text-align: left;

        .dialog-title {
            text-align: center;
            position: relative;
            font-size: 18px;
            padding-bottom: 4px;
            color: #423232;
            h3 {
              margin: 0px;
              padding: 15px 0;
            }

            h5 {
                margin: 0;
                font-weight: normal;
            }
        }
        .line{
          position: relative;
          margin:0px 0 40px 0px!important;
        }
        .dialog-title:after,.line::after {
            content: '';
            position: absolute;
            z-index: 1;
            background: url(../../../static/images/yt999/light.png) no-repeat;
            background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
            height: var(--f7-height-size-2);
            width: 100%;
            display: block;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            transform-origin: 50% 100%;
            transform: scaleY(1);
            transform: scaleY(calc(1/var(--f7-device-pixel-ratio)));
        }

        .dialog-text {
            .block-message {
                margin: var(--f7-margin-size-0);
                padding: var(--f7-padding-size-0);
                width: var(--f7-width-size-pt100);

                .block-content {
                    padding: var(--f7-padding-size-8) var(--f7-padding-size-10);
                    overflow: hidden scroll;
                    font-size: 15px;
                    padding: 15px;
                    p{
                      margin: 0;

                    }
                    .p1{
                      margin-top: 0px;
                      font-weight: bold;
                      // line-height: 30px;
                      margin-bottom: 15px;
                    }
                    .p2{
                      background: #f3f3f3;
                      padding: 10px;
                      color: #7f7f7f;
                      margin-bottom: 10px;
                      word-wrap: break-word;
                      word-break: break-word;
                    }
                    .copy-result{
                      margin-bottom: -8px;
                      text-align: center;
                      color: #101010;
                    }
                }

                .row-toggle {
                    margin: 10px 10px 0;

                    span {
                        color: #40baff;
                    }

                    .toggle {
                        margin-top: var(--f7-margin-size-f2);
                    }
                }

                .block-footer {
                    margin-top: 8px;

                    .row.no-gap {
                        --f7-grid-gap: 0px;
                    }

                    .button {
                        height: 50px;
                        line-height: 50px;
                        color: var(--f7-color-white);
                        font-size: var(--f7-px-14);
                    }

                    .btn-next {
                        background-color: #ccc;
                        color: #101010;
                    }

                    .btn-next.button-disabled {
                        opacity: .6;
                        color: #101010 !important;
                    }

                    .btn-close {
                        background-color: var(--f7-THEME-color);
                        color: white!important;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}
.page-change-login-pwd .block-change-login-pwd .list-change-login-pwd ul .item-input .item-inner input{
  font-size: 14px;
}
</style>
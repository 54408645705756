<template>
  <div class="activity-rotate">
    <div class="title">
      <img :src="`static/image/yt999/activity/t_05_${$f7.params.currLang}.png`" :onerror="`this.onerror=''; src='../static/images/yt999/activity/t_05_en.png'`">
    </div>
    <div class="rules" @click="openDialog('dialog-rules')">
      <img class="ico" src="static/images/yt999/activity/8.png" alt="">
      <span class="label">{{ $t('activity_center_0002') }}</span>
    </div>
    <div class="task-list">
      <div class="task-header">
        <div class="label">{{ $t('activity_center_0027') }}<span class="value">{{ item.OwnQuantity }}</span></div>
        <div class="label">{{ $t('activity_center_0028') }} <span class="value"> {{ item.Amount }}</span> </div>
      </div>
      <div class="rotate-warpper">
        <LuckyWheel class="lucky-wheel" ref="myLucky" width="280px" height="280px" :prizes="prizes" :blocks="blocks" :buttons="buttons" @start="startCallback" @end="endCallback" />
        <img class="bottom" src="static/images/yt999/activity/12.png" alt="">
      </div>
    </div>

    <div class="dialog dialog-result" id="dialog-result" @dialog:closed="$f7.$('.dialog-backdrop').css('background', 'transparent');" @dialog:open="$f7.$('.dialog-backdrop').css('background', 'rgba(0, 4, 7, 0.77)')">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t('activity_center_0029') }}
        </div>
        <div class="dialog-body">
          {{ tips }}
        </div>
        <div class="dialog-footer">
          <f7-link class="btn-close" @click="closeDialog('dialog-result')">{{ $t('common_close') }}</f7-link>
          <f7-link class="btn-agent" @click="tryAgain()">{{ $t('activity_center_0030') }}</f7-link>
        </div>
      </div>
    </div>
    
    <div class="dialog dialog-rules" id="dialog-rules" @dialog:closed="$f7.$('.dialog-backdrop').css('background', 'transparent');" @dialog:open="$f7.$('.dialog-backdrop').css('background', 'rgba(0, 4, 7, 0.77)')">
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ $t('activity_center_0008') }}
        </div>
        <div class="dialog-body">
            <ul class="list">
                <li class="item">{{ $t('activity_center_0031') }}</li>
                <li class="item">{{ $t('activity_center_0032') }}</li>
                <li class="item">{{ $t('activity_center_0033') }}</li>
                <li class="item">{{ $t('activity_center_0010') }}</li>
                <li class="item">{{ $t('activity_center_0011', [getGameTypeLimitDesc()]) }}</li>
                <li class="item">{{ $t('activity_center_0012') }}</li>
            </ul>
        </div>
        <div class="dialog-footer">
          <f7-link class="btn-close" @click="closeDialog('dialog-rules')">{{ $t('common_close') }}</f7-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { receiveBouns } from '@/axios/api'
import { getCookie } from '../../../config/utils'
import { mapGetters } from 'vuex'
export default {
  name: "TotalTurnoverBonus",
  props: {
    item: {
      type: Object,
      default() {
        return {
          TurnTable: []
        }
      }
    }
  },
  data() {
    return {
      running: false,
      tips: '',

      blocks: [
        { padding: '18px', background: '#f5c043', 
        imgs: [
            { src: '../../../static/images/yt999/activity/13.png', width: 280, height: 280, rotate: true }
        ] }],
      prizes: [],
      buttons: [{
        radius: '30%',
        pointer: true,
        fonts: [{ text: this.$t('activity_center_0034'), top: '-10px', fontColor: '#f9da4a', fontSize: 18 }],
        imgs: [{ src: '../../../static/images/yt999/activity/11.png', width: 74, height: 100, top: '-60px' }]
      }],
    }
  },
  computed: {
    ...mapGetters(['getLoginState'])
  },
  watch: {
    "item.TurnTable": {
      immediate: true,
      handler(list) {
        if (!Array.isArray(list)) return
        const awardList = list.map((item, i) => {
          return {
            BonusAmount: item.BonusAmount,
            fonts: [{ text: item.BonusAmount.toFixed(2), top: '10%' }, { text: this.$t('activity_center_0036'), top: '36%', fontSize: 12 }],
            background: i % 2 ? '#f5c043' : '#fcefc4'
          }
        })
        if (awardList.length % 2 != 0) {
          awardList.push({
            BonusAmount: 0,
            fonts: [{ text: this.$t('activity_center_0035'), top: '10%' }],
            background: '#f5c043'
          })
        }
        this.prizes = awardList
      }
    }
  },
  methods: {
    delay() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true)
        }, 3000)
      })
    },
    async startCallback() {
      let index = this.prizes.length - 1
      try {
        if (this.running) {
          return;
        }
        if (!this.getLoginState || !getCookie("zz")) {
          const promtLoadingGame = this.$f7.dialog.create({
            title: '',
            text: this.$t('common_need_login'),
            cssClass: "dialog-preloadgame",
          }).open();
          setTimeout(() => {
            promtLoadingGame.close();
            this.$f7router.navigate('/login/')
          }, 1500);
          return
        }
        this.running = true;

        this.$refs.myLucky.play()
        // 获取奖品
        let [done, res] = await Promise.all([this.delay(), receiveBouns({ data: JSON.stringify({ missionID: this.item.MissionID, amount: this.item.Amount }) })])
        const data = res.Data
        if (res.Status == 0) {
          index = this.prizes.findIndex(item => item.BonusAmount == data.WinData.BonusAmount)
          // 计算奖品角度
          this.tips = this.prizes[index].fonts.map(item => item.text).join(' ')
          this.$emit('refreshPoint', { missionId: this.item.MissionID, point: data.Point })
        } else {
          this.running = false;
          this.tips = res.Data.Message
          if (res.Data.Point) {
            this.$emit('refreshPoint', { missionId: this.item.MissionID, point: data.Point })
          }
        }
      } finally {
        this.running = false;
        this.$refs.myLucky.stop(index)
      }
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      if (prize.BonusAmount) {
          this.$f7.dialog.open('#dialog-result')
      } else {
        this.$f7.dialog.alert(this.tips || res.Data, this.$t('common_0004'));
      }
    },
    closeDialog(id) {
      this.$f7.dialog.close(`#${id}`)
    },
    openDialog(id) {
      this.$f7.dialog.open(`#${id}`)
    },
    getGameTypeLimitDesc() {
      const gameTypes = this.item.BonusAuditGameTypes
      if (!gameTypes) {
        return this.$t('activity_center_0014')
      } else {
        return gameTypes.map(gameType => gameType).join(' / ')
      }
    },
    tryAgain() {
      this.closeDialog('dialog-result')
      this.startCallback()
    },
  }
}
      </script>
      
      <style lang="less">
.activity-rotate {
  position: relative;
  background: url("../../../static/images/yt999/activity/7.png");
  background-size: 100% 100vh;
  height: calc(100vh - 70px);
  .title {
    position: absolute;
    top: 0;
    img {
      width: 100%;
    }
  }
  .rules {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 160px;
    padding: 0 16px;
    font-weight: bold;
    .ico {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
    .label {
      color: #476af7;
    }
  }

  .task-list {
    background: #fff;
    margin: 16px;
    padding: 16px;
    border-radius: 8px;
    .task-header {
      .label {
        font-size: 14px;
      }
      .value {
        font-size: 16px;
        font-weight: bold;
        color: red;
      }
    }

    .rotate-warpper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      .lucky-wheel {
        position: relative;
        z-index: 2;
      }
      .bottom {
        width: 280px;
        position: absolute;
        bottom: 5px;
        z-index: 1;
      }
    }
  }
}
</style>
<template>
  <f7-page no-toolbar no-swipeback name="financial" class="page-center" @page:init="onPageInit()">
    <f7-navbar innerClass="navbar-inner">
      <f7-nav-left>
        <f7-link @click="$f7router.back()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("myPosition_0001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="content">
      <div  class="rule" style="" @click="$f7router.navigate('/member-center/jobDetail/')"><img src="../../../static/images/yt999/position/wen.png" style="width: 17px;height: 17px;margin-right: 3px;" srcset=""><span>{{ $t("myPosition_0002") }}</span></div>
      <div  class="rule2"  style="" @click="$f7router.navigate('/member-center/jobApplication/')"><img src="../../../static/images/yt999/position/xie.png" style="width: 17px;height: 17px;margin-right: 3px;" srcset=""><span>{{ $t("jobApplication_001") }}</span></div>
      <div class="infotop">
        <div class="two" style="position: relative; padding-bottom: 8px">
          <div><img :src="`../../../static/images/yt999/home/ico/ZZicon/${currentReferrerLevel.Remark}.png`" alt="" style="height: 30px; margin: 13px 0 0 0" /></div>
          <div style="font-size: 16px; font-weight: bold">{{ currentReferrerLevel.ReferrerLevelName }}</div>
          <div style="color: #7f7f7f;font-size: 12px;">{{ $t("myPosition_0003") }}</div>
        </div>
        <div>
          <div><img src="../../../static/images/yt999/home/ico/ZZicon/friends.png" alt="" style="height: 25px; margin: 13px 0 3px 0" /></div>
          <div style="font-size: 16px; font-weight: bold">{{ referrerLevelPageDetail.CurrentMonthlyAdditionalTeamMemberCount }}</div>
          <div style="color: #7f7f7f;font-size: 12px;padding: 0 5px;width: 94%;">{{ $t("myPosition_0004") }}</div>
        </div>
      </div>
      <div class="proportion">
        <div >
          <div style="padding: 20px; font-weight: bold">{{ $t("myPosition_0005") }}</div>
          <div style="display: flex" v-if="VIPProportionList.length > 0">
            <div style="width: 45%">
              <div id="main" style="width: 120px; height: 120px; margin: 0 auto; margin-top: -20px"></div>
            </div>
            <div class="info">
              <ul>
                <li v-for="(item, index) in VIPProportionList" :key="index">
                  <span class="dian" :style="'background:' + myColor[index]"></span>
                  <span>{{ item.VIPLevelName }}({{ item.TotalVIPCount }})&nbsp;-&nbsp;</span>
                  <span style="color: #1616af">{{ (item.Proportion ) | formatCurrency }}%</span>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="tips">
            {{ $t("myPosition_0011") }}
          </div>
        </div>

        <div v-show="ReferrerVIPProportionList.length > 0">
          <div style="padding: 20px; font-weight: bold">{{ $t("myPosition_0006") }}</div>
          <div style="display: flex">
            <div style="width: 45%">
              <div id="main2" style="width: 120px; height: 120px; margin: 0 auto; margin-top: -20px"></div>
            </div>
            <div class="info">
              <ul>
                <li v-for="(item, index) in ReferrerVIPProportionList" :key="index">
                  <span class="dian" :style="'background:' + myColor2[index]"></span>
                  <span>{{ item.ReferrerVIPLevelName }}({{ item.TotalReferrerVIPCount }})&nbsp;-&nbsp;</span>
                  <span style="color: #1616af">{{ (item.Proportion ) | formatCurrency }}%</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="title" style="position: relative">
        <span>{{ $t("myPosition_0007") }}</span>
      </div>
      <div class="boxMonth" v-show="currentPendingBonusRecord.BasicMonthlyBonusAmount">
        <div class="box-body boxs" style="padding: 0px">
          <div class="info" style="width: 100%">
            <p>{{ curTime }}</p>
            <p>
              {{ currentPendingBonusRecord.BasicMonthlyBonusAmount | formatCurrency2 }} + {{ currentPendingBonusRecord.AdditionalMonthlyBonusAmountPerSubordinateActiveVIP | formatCurrency2 }} × {{ currentPendingBonusRecord.AdditionalBonusRatio }}%<br/>
               = <span style="color: var(--f7-THEME-color)">{{ currentPendingBonusRecord.TotalReferrerLevelBonusAmount | formatCurrency2 }}</span>
            </p>
            <p style="color: #c2c2c2">{{ $t("myPosition_0008") }}：{{ currentPendingBonusRecord.EnableReceiveTS | time_local2 }}</p>
          </div>
        </div>
      </div>
      <div class="title" style="position: relative">
        <span>{{ $t("myPosition_0009") }}</span>
      </div>
      <div class="boxMonth" v-show="memberreferrerlevelmonthlybonusrecordsbymemberget.length>0">
        <div class="box-body boxs" v-for="(item, index) in memberreferrerlevelmonthlybonusrecordsbymemberget" :key="index">
          <div class="info">
            <p>{{ item.YearAndMonth | timeYearMonth }}</p>
            <p>
              {{ item.BasicMonthlyBonusAmount | formatCurrency2 }} + {{ item.AdditionalMonthlyBonusAmountPerSubordinateActiveVIP | formatCurrency2 }} × {{ item.AdditionalBonusRatio }}%
              <br />
              =
              <span style="color: var(--f7-THEME-color)">{{ item.TotalReferrerLevelBonusAmount | formatCurrency2 }}</span>
            </p>
            <p style="color: #c2c2c2" v-if="item.TotalReferrerLevelBonusAmount==0">{{ $t("myPosition_0008") }}：- - </p>
            <p style="color: #c2c2c2;font-size: 11px;" v-else>{{ $t("myPosition_0008") }}：{{ item.EnableReceiveTS | time_local2 }}</p>

          </div>
          <div v-show="item.TotalReferrerLevelBonusAmount > 0">
            <button class="button" v-if="item.IsReceived" style="background: #ccc">{{ $t("award_0007") }}</button>
            <button class="button" v-if="!item.IsReceived && item.IsEnableReceive" @click="item.IsEnableReceive > 0 && item.TotalReferrerLevelBonusAmount > 0 ? goAward(item.YearAndMonth) : ''">{{ $t("award_0006") }}</button>
            <button class="button" v-if="!item.IsReceived && !item.IsEnableReceive" style="background: #ccc">{{ $t("award_0005") }}</button>
          </div>
        </div>
      </div>
      <div class="tip">
        <!-- <div><span>注：</span>（工资以当月具体收据为准！）</div>
        <div>
          <ol>
            <li>工资以当月具体收据为准！</li>
            <li>工资以当月具体收据为准！</li>
            <li>工资以当月具体收据为准！</li>
            <li></li>
          </ol>
        </div> -->
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getCookie,getStore } from "@/config/utils";
import { getmemberreferrerlevelmonthlybonusrecordsbymemberget, getReferrerLevelBonus, getreferrerLevelPageDetail,getInfo } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      memberreferrerlevelmonthlybonusrecordsbymemberget: [],
      referrerLevelPageDetail: {},
      currentPendingBonusRecord: {},
      currentReferrerLevel: {},
      VIPProportionList: [],
      ReferrerVIPProportionList: [],
      referrerLevelBonus: {},
      curTime: "",
      myColor: ["#8d4900", "#d90a00", "#d4bbff", "#1b00af", "#8502aa", "#00af46", "#0079b0", "#950900", "#d8d100"],
      myColor2: ["#1b00af", "#8502aa", "#00af46", "#0079b0", "#950900", "#d8d100"],
      enableMemberReferrerLevelUpgrade:false,

    };
  },
  watch: {

  },
  computed: {},
  methods: {
    onPageInit() {
      this.getsmemberreferrerlevelmonthlybonusrecordsbymemberget();
      this.getTime();
      this.getsReferrerLevelPageDetail();
    },
    myChart() {
      const self = this;

      var list = [];
      self.VIPProportionList.forEach((element) => {
        var item = { value: element.Proportion, name: element.VIPLevelName };
        list.push(item);
      });
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      var option = null;
      option = {
        series: [
          {
            type: "pie",
            stillShowZeroSum: false,
            label: {
              show: false,
            },
            color: self.myColor.slice(0, this.VIPProportionList.length),
            data: list,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    myChart2() {
      const self = this;
      var list = [];
      self.ReferrerVIPProportionList.forEach((element) => {
        var item = { value: element.Proportion, name: element.ReferrerVIPLevelName };
        list.push(item);
      });
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("main2"));
      // 指定图表的配置项和数据
      var option = null;
      option = {
        series: [
          {
            type: "pie",
            stillShowZeroSum: false,
            label: {
              show: false,
            },
            color: self.myColor2.slice(0, this.ReferrerVIPProportionList.length),
            data: list,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    getTime() {
      const now = new Date();
      const year = now.getFullYear(); // 获取年份，例如 2024
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      this.curTime = year + "/" + month;
    },
    getsmemberreferrerlevelmonthlybonusrecordsbymemberget() {
      getmemberreferrerlevelmonthlybonusrecordsbymemberget().then((data) => {
        if (data.Code === "NoError") {
          this.memberreferrerlevelmonthlybonusrecordsbymemberget = data.Data;
        }
      });
    },
    getsReferrerLevelPageDetail() {
      getreferrerLevelPageDetail().then((data) => {
        if (data.Code === "NoError") {
          this.referrerLevelPageDetail = data.Data.CurrentPendingBonusRecord;
          this.currentPendingBonusRecord = data.Data.CurrentPendingBonusRecord;
          this.currentReferrerLevel = data.Data.CurrentReferrerLevel;
          this.VIPProportionList = data.Data.VIPProportionList_CurrentMonth_Team;
          this.ReferrerVIPProportionList = data.Data.ReferrerVIPProportionList;

        }
      });
    },
    goAward(id) {
      const self = this;
      const params = { YearAndMonth: id };
      const d = { data: JSON.stringify(params) };
      let tip = "";

      getReferrerLevelBonus(d).then((data) => {
        if (data.Code === "NoError") {
          self.referrerLevelBonus = data.Data;
          let jin = this.$options.filters.formatCurrency2(data.Data.TotalReferrerLevelBonusAmount);
          let yue = this.$options.filters.timeYearMonth(data.Data.YearAndMonth);
          tip = '<div style="text-align: center;"><img src="../../../static/images/yt999/referrerLevelBonus.png" height="150px"></div><h4 style="text-align: center;margin:3px 0;">' + this.$t("myPosition_0010") + '</h4><h4 style="text-align: center;margin: 0;">(' + yue + ')</h4><h4 style="color: #FD4E00;text-align: center;margin: 0;">R$ ' + jin + "</h4>";
          self.getsmemberreferrerlevelmonthlybonusrecordsbymemberget();
        } else {
          tip = data.Data;
        }
        self.logout(tip);
      });
    },
    logout(tip) {
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      self.$f7.dialog
        .create({
          title: this.$t(""),
          text: tip,
          cssClass: "dialog-logout",
          buttons: [
            {
              text: this.$t("common_0001"),
              cssClass: "dialog-ok",
              onClick: () => {},
            },
          ],
        })
        .open();
    },
    getInfo() {
      const self = this;
      getInfo().then((data) => {
        if (data.Code === "NoError" && data.Data) {
         self.enableMemberReferrerLevelUpgrade = data.Data.BaseInfo.EnableMemberReferrerLevelUpgrade;
        }
      });
    },
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.$watch('VIPProportionList', (newValue, oldValue) => {
      if (newValue.length>0) {
        this.myChart();
      }
    });
    this.$watch('ReferrerVIPProportionList', (newValue, oldValue) => {
      if (newValue.length>0) {
        this.myChart2();
      }
    });
  },
};
</script>
<style lang="less" scoped>
::v-deep .navbar-inner {
  //头部颜色更换
  color: white;
  background: linear-gradient(to right, #357ae2, #1f64cd);
  a {
    color: white;
  }
}
.content {
  padding: 10px;
  min-height: 98%;
  background: var(--f7-THEME-color2);
  background-image: url(../../../static/images/yt999/member_center_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 12%;
  position: relative;
  .rule {
    color: white;
    position: absolute;
    top: 0px;
    left: 15px;
    z-index: 200;
    text-align: center;
    border-radius: 25px;
    padding: 0 12px 0 0;
    display: flex;
    justify-items: center;
  }
  .rule2 {
    color: white;
    position: absolute;
    top: 0px;
    right: 15px;
    z-index: 200;
    text-align: center;
    border-radius: 25px;
    padding: 0 12px;
    display: flex;
    justify-items: center;
  }
  .infotop {
    display: flex;
    background: white;
    border-radius: 10px;
    margin-top: 30px;
    div {
      width: 50%;
      div {
        width: 100%;
        text-align: center;
       
      }
    }

    .two:after {
      content: "";
      position: absolute;
      top: 25px;
      right: 0;
      transform: translateX(-50%);
      width: 1px;
      height: 50px;
      background-color: #c6c1c1;
    }
  }
  .proportion {
    background: white;
    border-radius: 10px;
    margin: 10px auto;
    .info {
      display: flex;
      padding-bottom: 20px;
      margin-top: -5px;
      .dian {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #8e4900;
        margin-right: 15px;
      }
      
    }
    .tips{
      padding: 0px 0 20px 10px;
      font-size:13px;
      border-bottom: 2px solid #e7e7e7;
      width: 90%;margin: 0 auto;
      color: var(--f7-THEME-color);
    }
  }
  .txt1 {
    padding-left: 6px;
    color: white;
    font-size: 28px;
    font-weight: bold;
  }
  .txt2 {
    width: 58%;
    padding-left: 6px;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  .title {
    position: relative;
    // font-size: 14px;
    font-weight: bold;
    margin: 10px 0px;
    padding-left: 10px;
    .liwu {
      height: 136px;
      position: absolute;
      right: 30px;
      bottom: -17px;
    }
  }
  .title::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 0px;
    transform: translateX(-50%);
    width: 3px;
    height: 60%;
    background-color: red;
  }

  .boxMonth {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    .box-header {
      border-bottom: 2px solid #e7e7e7;
      padding-bottom: 10px;
    }
    .box-body {
      // height: 50px;
      padding-top: 10px;
      display: flex;

      .info {
        p {
          margin: 0px;
          font-size: 12px;
        }
        p:nth-child(1) {
          margin: 5px 0;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .button {
        line-height: 30px;
        height: 30px;
        border-radius: 15px;
        margin: 0 auto;
        margin-top: 27px;
        width: 80px;
        text-transform: none;
        white-space: normal;
      }
      div:nth-child(1) {
        width: 70%;
        margin-right: 5px;
      }
    }
    .boxs {
      padding-bottom: 10px;
      border-bottom: 2px solid #e7e7e7;
    }
    .boxs:last-child {
      border: none;
    }
  }
  .tip {
    padding: 5px;
    ol {
      list-style-type: decimal;
      padding-left: 48px;
    }
    span {
      color: red;
    }
  }
}
::v-deep .page-content::after {
  height: 0;
}
</style>

<template>
  <f7-page class="page-dailyTask" name="dailyTask">
    <f7-navbar :title="$t('daily_task_0001')"></f7-navbar>

    <div class="buttons-tab tabs">
      <f7-link @click="tabShow(1)" tab-link="#tab-daily-all" tab-link-active :text="$t('common_0016')"></f7-link>
      <f7-link @click="tabShow(1)" tab-link="#tab-daily-casino" :text="$t('common_0007')"></f7-link>
      <f7-link @click="tabShow(2)" tab-link="#tab-daily-lottery" :text="$t('common_0011')"></f7-link>
      <f7-link @click="tabShow(3)" tab-link="#tab-daily-sports" :text="$t('common_0010')"></f7-link>
      <f7-link @click="tabShow(4)" tab-link="#tab-daily-egame" :text="$t('common_0006')"></f7-link>
      <f7-link @click="tabShow(5)" tab-link="#tab-daily-card" :text="$t('common_0008')"></f7-link>
      <f7-link @click="tabShow(6)" tab-link="#tab-daily-fish" :text="$t('common_0009')"></f7-link>
    </div>

    <f7-tabs swipeable class="tabs-promotion">
      <f7-tab id="tab-daily-all" class="page-content" tab-active>
        <template v-if="casinoList.length>0">
          <f7-card class="card-header-pic" v-for="item in activityList" :key="item.SpecialActivityID">
            <f7-card-content>
              <f7-row>
                <f7-col width="10">
                  <img class="img-all" :src="'../static/images/yt999/promotion/'+getIcon(item.SpecialActivityGroupID)" />
                </f7-col>
                <f7-col width="90" class="title">{{item.SpecialActivityTitle}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="progress">{{item.RemarkProgress}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="remark" width="70">{{item.RemarkValidRange}}</f7-col>
                <f7-col width="30">
                  <f7-button fill v-if="item.ButtonStatus=='None'" href="/login/">{{ $t('app_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Disable'" @click="goHomeGame(1)">{{ $t('daily_task_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Enable'" @click="receive(item.SpecialActivityID)">{{ $t('daily_task_0003') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Done'">{{ $t('daily_task_0004') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='pastDue'">{{ $t('daily_task_0005') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='WaitingAudit'">{{ $t('daily_task_0006') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>
        </template>

        <template v-else-if="casinoList.length <=0 && requestData">
          <f7-card class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" >
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab id="tab-daily-casino" class="page-content">
        <template v-if="casinoList.length>0">
          <f7-card class="card-header-pic" v-for="item in casinoList" :key="item.SpecialActivityID">
            <f7-card-header class="no-border" valign="bottom">
              <div class="list accordion-list">
                <ul>
                  <li class="accordion-item">
                    <a href="#" class="item-content item-link">
                      <div class="item-inner">
                        <div class="item-title">
                          <img
                            class="lazy lazy-fade-in promotion-lazy"
                            :data-src="`../../static/images/yt999/promotion/${item.SpecialActivityType}.png`"
                            width="100%"
                          >
                        </div>
                      </div>
                    </a>
                    <div class="accordion-item-content">
                      <div class="block">
                        <img
                          class="lazy lazy-fade-in"
                          :data-src="`../../static/images/yt999/promotion/content_${item.SpecialActivityType}.png`"
                          width="100%"
                          alt
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </f7-card-header>
            <f7-card-content>
              <f7-row>
                <f7-col class="title">{{item.SpecialActivityTitle}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="progress">{{item.RemarkProgress}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="remark" width="70">{{item.RemarkValidRange}}</f7-col>
                <f7-col width="30">
                  <f7-button fill v-if="item.ButtonStatus=='None'" href="/login/">{{ $t('app_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Disable'" @click="goHomeGame(1)">{{ $t('daily_task_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Enable'" @click="receive(item.SpecialActivityID)">{{ $t('daily_task_0003') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Done'">{{ $t('daily_task_0004') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='pastDue'">{{ $t('daily_task_0005') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='WaitingAudit'">{{ $t('daily_task_0006') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>
        </template>

        <template v-else-if="casinoList.length <=0 && requestData">
          <f7-card class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" >
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab id="tab-daily-lottery" class="page-content">
        <template v-if="lotteryList.length>0">
          <f7-card
            class="card-header-pic"
            v-for="item in lotteryList"
            :key="item.SpecialActivityID"
          >
            <f7-card-header class="no-border" valign="bottom">
              <div class="list accordion-list">
                <ul>
                  <li class="accordion-item">
                    <a href="#" class="item-content item-link">
                      <div class="item-inner">
                        <div class="item-title">
                          <img
                            class="lazy lazy-fade-in promotion-lazy"
                            :data-src="`../../static/images/yt999/promotion/${item.SpecialActivityType}.png`"
                            width="100%"
                            alt
                          >
                        </div>
                      </div>
                    </a>
                    <div class="accordion-item-content">
                      <div class="block">
                        <img
                          class="lazy lazy-fade-in"
                          :data-src="`../../static/images/yt999/promotion/content_${item.SpecialActivityType}.png`"
                          width="100%"
                          alt
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </f7-card-header>
            <f7-card-content>
              <f7-row>
                <f7-col class="title">{{item.SpecialActivityTitle}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="progress">{{item.RemarkProgress}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="remark" width="70">{{item.RemarkValidRange}}</f7-col>
                <f7-col width="30">
                  <f7-button fill v-if="item.ButtonStatus=='None'" href="/login/">{{ $t('app_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Disable'" @click="goHomeGame(2)">{{ $t('daily_task_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Enable'" @click="receive(item.SpecialActivityID)">{{ $t('daily_task_0003') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Done'">{{ $t('daily_task_0004') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='pastDue'">{{ $t('daily_task_0005') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='WaitingAudit'">{{ $t('daily_task_0006') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>
        </template>

        <template v-else-if="lotteryList.length <=0 && requestData">
          <f7-card class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" >  
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab id="tab-daily-sports" class="page-content">
        <template v-if="sportsList.length>0">
          <f7-card class="card-header-pic" v-for="item in sportsList" :key="item.SpecialActivityID">
            <f7-card-header class="no-border" valign="bottom">
              <div class="list accordion-list">
                <ul>
                  <li class="accordion-item">
                    <a href="#" class="item-content item-link">
                      <div class="item-inner">
                        <div class="item-title">
                          <img
                            class="lazy lazy-fade-in promotion-lazy"
                            :data-src="`../../static/images/yt999/promotion/${item.SpecialActivityType}.png`"
                            width="100%"
                            alt
                          >
                        </div>
                      </div>
                    </a>
                    <div class="accordion-item-content">
                      <div class="block">
                        <img
                          class="lazy lazy-fade-in"
                          :data-src="`../../static/images/yt999/promotion/content_${item.SpecialActivityType}.png`"
                          width="100%"
                          alt
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </f7-card-header>
            <f7-card-content>
              <f7-row>
                <f7-col class="title">{{item.SpecialActivityTitle}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="progress">{{item.RemarkProgress}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="remark" width="70">{{item.RemarkValidRange}}</f7-col>
                <f7-col width="30">
                  <f7-button fill v-if="item.ButtonStatus=='None'" href="/login/">{{ $t('app_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Disable'" @click="goHomeGame(3)">{{ $t('daily_task_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Enable'" @click="receive(item.SpecialActivityID)">{{ $t('daily_task_0003') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Done'">{{ $t('daily_task_0004') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='pastDue'">{{ $t('daily_task_0005') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='WaitingAudit'">{{ $t('daily_task_0006') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>
        </template>

        <template v-else-if="sportsList.length <=0 && requestData">
          <f7-card class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" >
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab id="tab-daily-egame" class="page-content">
        <template v-if="egameList.length>0">
          <f7-card class="card-header-pic" v-for="item in egameList" :key="item.SpecialActivityID">
            <f7-card-header class="no-border" valign="bottom">
              <div class="list accordion-list">
                <ul>
                  <li class="accordion-item">
                    <a href="#" class="item-content item-link">
                      <div class="item-inner">
                        <div class="item-title">
                          <img
                            class="lazy lazy-fade-in promotion-lazy"
                            :data-src="`../../static/images/yt999/promotion/${item.SpecialActivityType}.png`"
                            width="100%"
                            alt
                          >
                        </div>
                      </div>
                    </a>
                    <div class="accordion-item-content">
                      <div class="block">
                        <img
                          class="lazy lazy-fade-in"
                          :data-src="`../../static/images/yt999/promotion/content_${item.SpecialActivityType}.png`"
                          width="100%"
                          alt
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </f7-card-header>
            <f7-card-content>
              <f7-row>
                <f7-col class="title">{{item.SpecialActivityTitle}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="progress">{{item.RemarkProgress}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="remark" width="70">{{item.RemarkValidRange}}</f7-col>
                <f7-col width="30">
                  <f7-button fill v-if="item.ButtonStatus=='None'" href="/login/">{{ $t('app_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Disable'" @click="goHomeGame(4)">{{ $t('daily_task_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Enable'" @click="receive(item.SpecialActivityID)">{{ $t('daily_task_0003') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Done'">{{ $t('daily_task_0004') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='pastDue'">{{ $t('daily_task_0005') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='WaitingAudit'">{{ $t('daily_task_0006') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>
        </template>

        <template v-else-if="egameList.length <=0 && requestData">
          <f7-card class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" >
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab id="tab-daily-card" class="page-content">
        <template v-if="cardList.length>0">
          <f7-card class="card-header-pic" v-for="item in cardList" :key="item.SpecialActivityID">
            <f7-card-header class="no-border" valign="bottom">
              <div class="list accordion-list">
                <ul>
                  <li class="accordion-item">
                    <a href="#" class="item-content item-link">
                      <div class="item-inner">
                        <div class="item-title">
                          <img
                            class="lazy lazy-fade-in promotion-lazy"
                            :data-src="`../../static/images/yt999/promotion/${item.SpecialActivityType}.png`"
                            width="100%"
                            alt
                          >
                        </div>
                      </div>
                    </a>
                    <div class="accordion-item-content">
                      <div class="block">
                        <img
                          class="lazy lazy-fade-in"
                          :data-src="`../../static/images/yt999/promotion/content_${item.SpecialActivityType}.png`"
                          width="100%"
                          alt
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </f7-card-header>
            <f7-card-content>
              <f7-row>
                <f7-col class="title">{{item.SpecialActivityTitle}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="progress">{{item.RemarkProgress}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="remark" width="70">{{item.RemarkValidRange}}</f7-col>
                <f7-col width="30">
                  <f7-button fill v-if="item.ButtonStatus=='None'" href="/login/">{{ $t('app_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Disable'" @click="goHomeGame(5)">{{ $t('daily_task_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Enable'" @click="receive(item.SpecialActivityID)">{{ $t('daily_task_0003') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Done'">{{ $t('daily_task_0004') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='pastDue'">{{ $t('daily_task_0005') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='WaitingAudit'">{{ $t('daily_task_0006') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>
        </template>
        <template v-else-if="cardList.length <=0 && requestData">
          <f7-card class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" >
          </f7-card>
        </template>
      </f7-tab>
      <f7-tab id="tab-daily-fish" class="page-content">
        <template v-if="fishList.length > 0">
          <f7-card class="card-header-pic" v-for="item in fishList" :key="item.SpecialActivityID">
            <f7-card-header class="no-border" valign="bottom">
              <div class="list accordion-list">
                <ul>
                  <li class="accordion-item">
                    <a href="#" class="item-content item-link">
                      <div class="item-inner">
                        <div class="item-title">
                          <img
                            class="lazy lazy-fade-in promotion-lazy"
                            :data-src="`../../static/images/yt999/promotion/${item.SpecialActivityType}.png`"
                            width="100%"
                            alt
                          >
                        </div>
                      </div>
                    </a>
                    <div class="accordion-item-content">
                      <div class="block">
                        <img
                          class="lazy lazy-fade-in"
                          :data-src="`../../static/images/yt999/promotion/content_${item.SpecialActivityType}.png`"
                          width="100%"
                          alt
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </f7-card-header>
            <f7-card-content>
              <f7-row>
                <f7-col class="title">{{item.SpecialActivityTitle}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="progress">{{item.RemarkProgress}}</f7-col>
              </f7-row>
              <f7-row>
                <f7-col class="remark" width="70">{{item.RemarkValidRange}}</f7-col>
                <f7-col width="30">
                  <f7-button fill v-if="item.ButtonStatus=='None'" href="/login/">{{ $t('app_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Disable'" @click="goHomeGame(6)">{{ $t('daily_task_0002') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Enable'" @click="receive(item.SpecialActivityID)">{{ $t('daily_task_0003') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='Done'">{{ $t('daily_task_0004') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='pastDue'">{{ $t('daily_task_0005') }}</f7-button>
                  <f7-button fill v-else-if="item.ButtonStatus=='WaitingAudit'">{{ $t('daily_task_0006') }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-card-content>
          </f7-card>
        </template>
        <template v-else-if="fishList.length <=0 && requestData">
          <f7-card class="no-data">
            <img :src="`../../static/images/yt999/hd_nodata_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../static/images/yt999/hd_nodata_enUS.png'`" alt="No data" >
          </f7-card>
        </template>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { getPromotionList, getBonus } from "../../axios/api";
import {getCookie, removeStore,setStore} from '../../config/utils';

export default {
  components: {},
  props: {},
  data() {
    return {
      activityList:[],
      casinoList: [],
      lotteryList: [],
      sportsList: [],
      egameList: [],
      cardList: [],
      fishList: [],
      requestData:false
    };
  },
  watch: {},
  computed: {},
  methods: {
    getIcon(SpecialActivityGroupID){
      switch (SpecialActivityGroupID+'') {
        case "4":
          return "promtins-ico-casino.png";
        case "6":
          return "promtins-ico-egames.png";
        case "SportsBook":
          return "promtins-ico-sports.png";
        case "Fish":
          return "promtins-ico-fishing.png";
        case "7":
          return "promtins-ico-lottery.png";
        case "8":
          return "promtins-ico-cards.png";
        default:
          return "promtins-ico-other.png";
          break;
      }
    },
    requestPromotion() {
      const self = this;
      const params = { data: JSON.stringify({ Type: 0 }) };
      getPromotionList(params).then(data => {
        if (data.Code === "NoError") {
          const d = data.Data;
          self.activityList=d;
          self.requestData = true;
          self.casinoList = d.filter(item => {
            return item.SpecialActivityGroupName === this.$t('common_0017');
          });
          self.lotteryList = d.filter(item => {
            return item.SpecialActivityGroupName === this.$t('common_0018');
          });
          self.sportsList = d.filter(item => {
            return item.SpecialActivityGroupName === this.$t('common_0019');
          });
          self.egameList = d.filter(item => {
            return item.SpecialActivityGroupName === this.$t('common_0020');
          });
          self.cardList = d.filter(item => {
            return item.SpecialActivityGroupName === this.$t('common_1021');
          });
          self.fishList = d.filter(item => {
            return item.SpecialActivityGroupName === this.$t('common_1022');
          });
        }
      });
    },
    receive(id) {
      const self = this;
      const data = {
        SpecialActivityID: id
      };
      const d = { data: JSON.stringify(data) };
      getBonus(d).then(data => {
        self.$f7.dialog.alert(data.Data, this.$t('common_0004'));
        self.requestPromotion();
      });
    },
    tabShow(d) {
      if (this.$f7.$(".accordion-item-opened").length > 0) {
        this.$f7.accordion.close(".accordion-item-opened");
        this.$f7.$(".tabs-promotion").scrollTo(0, 0);
      }
    },
    goHomeGame(d) {
      //setStore('home-tab-active', d);
      this.$f7router.navigate(`/game-category-list/`+d+'/');
    }
  },
  created() {
    removeStore("mc-tab-active");
    this.$f7.emit("myCustomEvent", "promotion");
    this.requestPromotion();
  },
  mounted() {},
  updated() {
    this.$f7.lazy.create(this.$el);
  }
};
</script>
<style lang="less" scoped>
.buttons-tab {
  height: var(--f7-px-40);
  width: var(--f7-width-size-pt100);
  display: var(--f7-display-flex);
  justify-content: var(--f7-text-center);
  box-sizing: var(--f7-border-box);
  align-items: var(--f7-text-center);
  align-content: var(--f7-text-center);
  overflow: var(--f7-hidden);
  border-bottom: var(--f7-padding-size-1) solid var(--f7-color-blue-menu);
  //border-top: var(--f7-padding-size-1) solid var(--f7-color-blue-menu);
  a.tab-link {
    width: var(--f7-width-size-pt100);
    height: var(--f7-height-size-pt100);
    box-sizing: var(--f7-border-box);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    align-items: var(--f7-text-center);
    flex-direction: var(--f7-flex-direction);
    text-transform: var(--f7-tabbar-link-text-transform);
    font-weight: var(--f7-tabbar-link-font-weight);
    letter-spacing: var(--f7-tabbar-link-letter-spacing);
    overflow: var(--f7-hidden);
    color: var(--f7-color-gray-tint);
    font-size: var(--f7-px-16);
  }
  a.tab-link.tab-link-active {
    color: #fff;
    background: url(../../static/images/yt999/about_nav.png) no-repeat 0px 0px;
    background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);

    i.iconfont {
      color: var(--f7-color-blue-active);
    }
  }
}
.tabs-promotion {
  position: relative;
  overflow: hidden;
  min-height: calc(100% - 55px);
  height: calc(100% - 55px);
  .tab {
    padding: var(--f7-padding-size-0);
    .card-header-pic {
      background: #f9f9f9;
      margin: var(--f7-px-6);
      border: 1px solid #d2d2d2;
      padding: var(--f7-padding-size-4);
      .img-all{
        height:30px;
      }
      .card-header {
        padding: var(--f7-padding-size-0);
        display: block;
        .accordion-list {
          .accordion-item {
            .item-link {
              padding: var(--f7-padding-size-0);
              .item-inner {
                padding: var(--f7-padding-size-0);
                .item-title {
                  width: 100%;
                  .promotion-lazy {
                    height: 86px;
                  }
                }
              }
              .item-inner:before {
                content: "";
              }
            }
            .item-link.active-state {
              background-color: transparent;
            }
            .accordion-item-content {
              .block {
                padding: var(--f7-padding-size-0);
              }
            }
          }
        }
      }
      .card-header:after {
        height: 0;
      }
      .card-content {
        padding: 0;
        .row {
          width: 100%;
          height: 30px;
          line-height: 30px;
          .title {
            font-size: var(--f7-px-16);
            color: #fff;
            font-weight: bold;
          }
          .progress {
            color: #c59a4c;
            font-size: var(--f7-px-16);
            font-weight: bold;
          }
          .remark {
            color: #ccc;
            font-size: var(--f7-px-16);
          }
          .button {
            width: 100px;
            float: right;
            background: -webkit-linear-gradient(left, #eda954, #edc661);
            background: -o-linear-gradient(right, #eda954, #edc661);
            background: -moz-linear-gradient(right, #eda954, #edc661);
            background: linear-gradient(to right, #eda954, #edc661);
            color: #000;
          }
        }
      }
    }
    .no-data {
      background-color: transparent;
      box-shadow: none;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: 50%;
    }
  }
}
</style>
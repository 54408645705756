<template>
  <f7-page no-toolbar no-navbar no-swipeback class="page-loading-game" name="loading-game">
    <f7-block class="block-loading-game">
      <div class="loader-ring">
        <div class="loader-ring-light"></div>
        <div class="loader-ring-track"></div>
        <div class="loader-text">{{ $t('common_game_Beingloaded_format', [EnterGameName]) }}</div>
      </div>
    </f7-block>
  </f7-page>
</template>

<script>
import { getGameEntry } from "../axios/api";
import http from "../axios/http";
import { setCookie, getStore, getCookie } from "../config/utils";
export default {
  components: {},
  props: {},
  data() {
    return {
      EnterGameName: "",
      gameItem: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    enterGame() {
      const self = this;
      const d = { type: 2,data: getStore('playGameParam'), z: getCookie('zz')||getStore('zz')};
      getGameEntry(d).then(data => {
        if (data.Code === "NoError") {
          if(self.gameItem.ProviderId == 512) {
            const entryData = JSON.parse(data.Data);
            let encode = btoa(encodeURI(data.Data));
            window.location.href = entryData.GameEntryURL + '/index.html?info=' + encode;
          } else { 
            window.location.href = data.Data;
          }
        } else {
          let content =
            data.Code === "ProviderMaintain" &&
            data.Data &&
            data.Data.length >= 2
              ? this.$t('common_0024') + "</br>" + this.$t('loading_game_0001') + "：" +
                data.Data[0].replace("T", " ") +
                "<br/>" + this.$t('loading_game_0002') + "：" +
                data.Data[1].replace("T", " ")
              : this.$t('common_loading_game_failed');
          let promptMaintain = self.$f7.dialog
            .create({
              title: this.$t('common_0004'),
              text: content,
              cssClass: "dialog-game",
              closeByBackdropClick: false,
              buttons: [
                {
                  text: this.$t('common_close'),
                  onClick: function(dialog, e) {
                    promptMaintain.close();
                    window.close();
                  }
                }
              ]
            })
            .open();
        }
      });
    }
  },
  created() {
    const self = this;
    const data = self.gameItem = JSON.parse(getStore('playGameParam'));
    self.EnterGameName = data.Name;
    this.enterGame();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-loading-game {
  --f7-page-toolbar-bottom-offset: 0;
  .block-loading-game {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0;
    height: 100%;
    width: 100%;
    .loader-ring {
      position: absolute;
      top: 25%;
      width: 200px;
      height: 200px;
      .loader-ring-light {
        width: 200px;
        height: 200px;
        -moz-border-radius: 200px;
        -webkit-border-radius: 200px;
        border-radius: 200px;
        -moz-box-shadow: 0 4px 0 #00abf2 inset;
        -webkit-box-shadow: 0 4px 0 #00abf2 inset;
        box-shadow: 0 4px 0 #00abf2 inset;
        animation: rotate-360 1.5s linear infinite;
      }
      .loader-ring-track {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 200px;
        -moz-border-radius: 200px;
        -webkit-border-radius: 200px;
        border-radius: 200px;
      }
      .loader-text {
        position: absolute;
        top: 47%;
        width: 200px;
        color: #464646;
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
</style>
/**
 * 存储localStore
 * @param {localStore key} name 
 * @param {localStore value} content 
 */
export const setStore = (name, content) => { 
    if (!name || content == undefined) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.localStorage.setItem(name, content);
}
/**
 * 获取localStore
 * @param {localStore key} name 
 */
export const getStore = (name) => { 
    if (!name) return;
    return window.localStorage.getItem(name);
}
/**
 * 删除localStore
 * @param {localStore key} name 
 */
export const removeStore = (name) => { 
    if (!name) return;
    window.localStorage.removeItem(name);
}

/**
 * 
 * @param {cookie name} cname 
 * @param {cookie value} cvalue 
 * @param {cookie expires} exdays 
 */
export const setCookie = (cname, cvalue, exdays) => {
    if (!cname) return;
    let expires;
    if (typeof exdays == 'number') {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        expires = "expires=" + d.toGMTString();
    }
    if (typeof exdays == 'object') {
        expires = "expires=" + exdays.toGMTString()
    }
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

/**
 * 
 * @param {cookie name} cname 
 */
export const getCookie = (cname) => { 
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i=0; i<ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name)==0) { return c.substring(name.length,c.length); }
    }
    return "";
}
<template>
  <f7-page
    no-toolbar
    no-swipeback
    name="deposit-records"
    class="page-deposit-records"
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar :title="$t('deposit_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-records" v-if="depositRecordsList.length>0">
      <div class="card card-outline" v-for="item in depositRecordsList" :key="item.TicketNo">
        <div class="card-header">
          <div class="row no-gap">
            <i class="f7-icons">equal_square</i>
            <div class="col-75 co-white text-left ss">{{item.TicketNo}}</div>
          </div>
        </div>
        <div class="card-content card-content-padding">
          <!-- <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('deposit_records_0003') }}:</div>
            <div class="col-75 co-gray">
              <template v-if="item.DepositType==1">{{ $t('deposit_records_0004') }}</template>
              <template v-if="item.DepositType==2">{{ $t('deposit_records_0005') }}</template>
            </div>
          </div> -->
          <!-- <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('deposit_records_0006') }}:</div>
            <div class="col-75 co-gray">{{item.DepositType|formatDepositType(item.PayType)}}</div>
          </div> -->
          <div class="row no-gap">
            <div class="col-25 text-right co-white ss">{{ $t('deposit_records_0007') }}:</div>
            <div
              class="col-75 co-green"
              :class="item.Amount<0?'co-yellow':'co-blue'"
            >{{item.Amount| formatCurrency}}</div>
          </div>
          <!-- <div class="row no-gap">
            <div class="col-25 text-right co-white">{{ $t('deposit_records_0008') }}:</div>
            <div class="col-75 co-green">{{item.Bonus| formatCurrency}}</div>
          </div> -->
          <div class="row no-gap">
            <div class="col-25 text-right co-white ss">{{ $t('deposit_records_0009') }}:</div>
            <div class="col-75">
              <span class="co-red" v-if="item.Status==1 && item.DepositType==1">{{ $t('deposit_records_0010') }}</span>
              <span class="co-red" v-if="item.Status==2 && item.DepositType==1">{{ $t('deposit_records_0011') }}</span>
              <span class="co-red" v-if="item.Status==3 && item.DepositType==1">{{ $t('deposit_records_0012') }}</span>
              <span class="co-green" v-if="item.Status==4 && item.DepositType==1">{{ $t('deposit_records_0013') }}</span>
              <span class="co-red" v-if="item.Status==5 && item.DepositType==1">{{ $t('deposit_records_0014') }}</span>
              <span class="co-red" v-if="item.Status==1 && item.DepositType==2">{{ $t('deposit_records_0015') }}</span>
              <span class="co-green" v-if="item.Status==3 && item.DepositType==2">{{ $t('deposit_records_0016') }}</span>
              <span class="co-red" v-if="item.Status==4 && item.DepositType==2">{{ $t('deposit_records_0017') }}</span>
              <span class="co-red" v-if="item.Status==5 && item.DepositType==2">{{ $t('deposit_records_0018') }}</span>
            </div>
          </div>
          <div class="row no-gap">
            <div class="col-25 text-right co-white ss">{{ $t('deposit_records_0019') }}:</div>
            <div class="col-75 co-gray">{{item.Remark?item.Remark:'--'}}</div>
          </div>
        </div>
        <div class="card-footer co-white">{{item.CreateTs|time_local}}</div>
      </div>
    </f7-block>
    
    <f7-card v-else-if="depositRecordsList.length<=0 && !showData" class="no-data">
      <img :src="`../../../static/images/yt999/noData_${ $f7.params.currLang }.png`" width="45%" :onerror="`this.onerror=''; src='../../../static/images/yt999/noData_enUS.png'`" alt="No data" >
    </f7-card>
  </f7-page>
</template>

<script>
import { getDepositRecordsList } from "../../../axios/api";
import { getCookie } from '@/config/utils';
export default {
  components: {},
  props: {},
  data() {
    return {
      depositRecordsList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    depositRecordsListEvent() {
      const self = this;
      let queryParam = JSON.parse(self.$f7route.params.Info);
      queryParam.BeginDate = Math.round(
        new Date(queryParam.BeginDate.replace(/-/g,'/')).getTime() / 1000
      );
      queryParam.EndDate = Math.round(
        new Date(queryParam.EndDate.replace(/-/g,'/')).getTime() / 1000
      );
      queryParam.PageNow = self.pageNow;
      queryParam.PageSize = 20;

      const params = { data: JSON.stringify(queryParam) };
      getDepositRecordsList(params).then(data => {
        if (data.Code === "NoError") {
          self.depositRecordsList = self.depositRecordsList.concat(
            data.Data[0]
          );
          self.showData = self.depositRecordsList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.depositRecordsList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.depositRecordsList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.depositRecordsListEvent();
        self.allowInfinite = true;
      }, 1000);
    }
  },
  created() {
    this.depositRecordsListEvent();
  },
  mounted() {}
};
</script>
<style lang="less" scoped>
.page-deposit-records {
  .ss{
    font-weight: bold;
  }
  .block-records {
    margin: 0;
    padding: 0;
    .card {
      border: 1px solid #d2d2d2;
      background-color: #f9f9f9;
      margin: 8px;
      .co-white {
        color: #464646;
      }
      .co-gray {
        color: #8e8e91;
      }
      .co-green {
        color: #17903a;
      }
      .co-blue {
        color: #028cb3;
      }
      .co-yellow {
        color: #7c6e01;
      }
      .co-red {
        color: #c50d0d;
      }
      .card-header:after,
      .card-footer:before {
        background-color: #c7c7c7;
      }
      .card-header {
        display: block;
        font-size: 15px;
        padding: 10px 15px 10px 8px;
        i.f7-icons {
          font-size: 18px;
          vertical-align: middle;
          margin-top: -2px;
          //color: #1e56bb;
        }
      }
      .card-content {
        font-size: 15px;
        padding: 2px 4px;
        .row {
          padding: 4px 0;
        }
      }
      .card-footer {
        justify-content: flex-end;
        padding: 8px 15px;
      }
    }
  }
  
  .no-data {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 50%;
  }
}
</style>
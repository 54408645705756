export const SETMERCHAT_INFO = 'SETMERCHAT_INFO'
export const SETLOGINSTATE = 'SETLOGINSTATE'
export const SETLOGININFO = 'SETLOGININFO'
export const SETALLBALANCES = 'SETALLBALANCES'
export const SETUSERINFO = 'SETUSERINFO'
export const SETFIRSTLOADAPP = 'SETFIRSTLOADAPP'
export const SETSECURITYCODE = 'SETSECURITYCODE'
export const SET_ONLINECUSTOM_LOADSTATUS = 'SET_ONLINECUSTOM_LOADSTATUS'
export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_CURRENCY_LIST = 'SET_CURRENCY_LIST'
export const SET_MISSION_LIST = 'SET_MISSION_LIST'
export const SETFINANCIAL_DETAIL = 'SETFINANCIAL_DETAIL'
export const SETMUSIC_DETAIL = 'SETMUSIC_DETAIL'